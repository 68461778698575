import {get, put, post, del} from "../base";

// 首页数据报告

// 数据格子
export function getDataGrid(id) {
    return get(`/api/admin/convention/getDataReportNumber/${id}`);
}
// 折线图（人次）
export function getuserSessions(code,beginDate,endDate) {
    return get(`/api/admin/convention/getDataPersonFromBeninToEnd/${code}/${beginDate}/${endDate}`);
}
// 折线图（人数）
export function getNumberOfHomepageVisitors(code,beginDate,endDate) {
    return get(`/api/admin/convention/getDataPersonNumberFromBeninToEnd/${code}/${beginDate}/${endDate}`);
}
// 饼状图（人次）
export function getPieAndTable(code,data) {
    return get(`/api/admin/convention/getDataReportFuncPerson/${code}`,data);
}
// 饼状图（人数）
export function getPieAndTableNumberOfPeople(code,data) {
    return get(`/api/admin/convention/getDataReportFuncNumber/${code}`,data);
}


// 地图-左侧图
export function getMapData(code,data) {
// export function getMapData(code,page_no,page_size) {
    // return get(`/api/admin/convention/getDataReportProvinceNumber/${code}?page_no=${page_no}&page_size=${page_size}`);
    return get(`/api/admin/convention/getMapProvinceInFo/${code}`,data);
}
// 地图-右侧表格
export function getPersonTimeOfMap(code,data) {
    return get(`/api/admin/convention/getDataReportProvincePerson/${code}`,data);
}
