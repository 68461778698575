<template>
<div>
<!--  服务介绍-->
  <a-modal width="700px" v-model="visible" :footer="null" @cancel="handleClose">
    <template slot="title">
      <span style="font-weight: 550;color: #333333;font-size: 20px">{{titleName}}</span>
    </template>
    <div style="height: 500px; overflow-y: scroll;">
      <div v-if="isWhos === 'zbdwsz'" style="margin-bottom: 50px" v-html="objContent.zbdwszIntro"></div>
      <div v-if="isWhos === 'jszcqgg'" style="margin-bottom: 50px" v-html="objContent.jszcqggIntro"></div>
    </div>
    <div class="bottomSty">
      <div style="display: flex">
        <div class="cost">总费用</div>
        <div>
          <div class="pay" v-if="isWhos === 'zbdwsz'">￥{{objContent.zbdwszMoneyZKJ}}</div>
          <div class="original" v-if="isWhos === 'zbdwsz'">￥{{objContent.zbdwszMoneyYJ}}</div>

          <div class="pay" v-if="isWhos === 'jszcqgg'">￥{{objContent.jszcqggMoneyZKJ}}</div>
          <div class="original" v-if="isWhos === 'jszcqgg'">￥{{objContent.jszcqggMoneyYJ}}</div>
        </div>
      </div>
      <a-button style="margin-left: 10px" type="primary" @click="handleOk(isWhos)">
        确认使用
      </a-button>
    </div>
  </a-modal>
</div>
</template>

<script>
import {conventionCode, conventionFuncOrder} from "@/service/MedicalConference";

export default {
  name: "ServiceIntroduction",
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    titleName:{
      type:String,
      default:'',
    },
    isWhos:{
      type:String,
    },
    conferenceCode:{
      type:String,
      default:'',
    },
    objContent:{
      type:Object,
      default:{},
    },
  },
  data(){
    return{

    }
  },
  methods:{
    async handleOk(type) {
      const response = await conventionFuncOrder(this.conferenceCode,type);
      if (response.code === 200) {
        switch (type){
          case 'zbdwsz':
            this.$parent.openModelOrganizer();
            break;
          case 'jszcqgg':
            this.$parent.openModelTechnical();
            break;
        }
        await this.getList()
      } else {
        this.$message.warning(response.message);
      }
      this.handleClose();
    },
    //获取数据--更新按钮显示问题
    async getList() {
      const response = await conventionCode(this.conferenceCode)
      if(response.code === 0){
        //会议发布
        this.$store.dispatch("isShowIssueBtn", response.data.isPublished);
      }
    },
    handleClose(){
      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）
    },
  }
}
</script>

<style scoped>
.bottomSty {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background-color: #fff;
  display: flex;
  justify-content: right;
  z-index: 1;
}
.cost{
  font-size: 12px;
  margin-top: 5px;
  color: #333333;
}
.pay{
  color: #00C024;
  font-size: 18px;
}
.original{
  font-size: 12px;
  margin-left: 15px;
  color: #9c9b9b;
  text-decoration: line-through;
}
</style>
