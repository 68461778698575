<template>
<div>
      <a-drawer
        :visible="visible"
        :get-container="false"
        title="注册缴费"
        :width="1000"
        :body-style="{ paddingBottom: '80px' }"
        @close="ZCJFClose"
    >
      <div>
       <a-button type="primary"  style="margin-bottom: 10px">+ 新增</a-button>
       <a-button  @click="openQrCode" style="margin-bottom: 10px;float:right;margin-right:30px" >签到二维码</a-button>
       <a-table  
       :pagination="false"
       :columns="columns"
       :data-source="dataList">

       </a-table>
      </div>
          <!-- 查看签到二维码 -->
    <a-modal
          width="550px"
          v-model="linkVisible"
          title="二维码生成"
          :footer="null"
          @close="() =>{this.qrStatus = ''} "
          class="erweima"
          >
        <div style="display: flex;justify-content: center;align-items: center;flex-direction:column">
          <div class="codeLink" v-if="this.qrStatus === 'zhanshi'">
            <div style="padding: 15px;border: 1px dashed #000;background-color: #fff;"><div id="qrCode">
              <vue-qr
                  ref="image"
                  :logo-src="logoSrc"
                  :size="150"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="appSrc" />
            </div></div>
          </div>
          
          <div class="codeLink" v-if="this.qrStatus === 'shengcheng'">
            <div class="codeBox">
              <div style="text-align: center;">
                <a-icon type="qrcode" style="color:#45a5e6;font-size:50px;margin-bottom:10px"/>
                <p>
                  <a @click="showQRCode">生成签到二维码</a>
                </p>
              </div>
            </div>
          </div>
          <a-button @click="downImg" type="primary" v-if="qrStatus === 'zhanshi'" style="margin-top:25px;margin-bottom:60px;">
            下载签到二维码
          </a-button>
        </div>

      </a-modal>

     </a-drawer>
   </div>  
</template>

<script>
export default {
 props:{
    visible:{
      type:false,
      default:false,
    },
 
  },
   data(){
     return{
      columns:[],
      dataList:[],
     }
   },
  methods:{
       ZCJFClose(){
          let flag = false;
          this.$emit("closeZCJF", flag); //子组件向父组件传值（开关）
       },
     // 打开签到二维码弹窗
      openQrCode(){
      this.linkVisible = true
    },
    // 点击生成二维码
    showQRCode(){
      this.qrStatus = 'zhanshi'
      this.getTheQrcode()
    },
 }
}
</script>

<style>

</style>