<template>
<div>
  <div class="ConventionCase">
    <div v-if="!isShowBtn">
      <img v-if="liveContent.coverPics" style="max-height: 180px" :src="liveContent.coverPics[0]" alt="">
    </div>
    <div class="topRight" :style="{width:isShowBtn?'100%':'55%'}">
      <div class="topTitle" @mouseover="mouseoverBtn('title')" @mouseleave="mouseleaveBtn('title')">
        <span style="font-size: 20px;padding-top: -5px;margin-right: 10px;"><a-icon style="cursor: pointer;" @click="returnBtn" type="left-circle" /></span>

        <a-tag v-if="liveContent.isPublished === 1" class="tag" :color="liveContent.liveStatus === 0 ? '#2db7f5' : liveContent.liveStatus === 1 ? '#FFBA69' : liveContent.liveStatus === -1 ? '#cccccc':''">
          {{liveContent.liveStatus === 1?'进行中':liveContent.liveStatus === 0?'未开始':liveContent.liveStatus === -1?'已结束':''}}
        </a-tag>

        <a-input ref="titleRef" v-if="isShowEditTitle" @blur="pressblurBtn('title')" @pressEnter="pressEnterBtn('title')" v-model="editForm.title" style="width: 300px;" placeholder="请输入"/>

        <h3 v-if="!isShowEditTitle" style="margin-top: 2px;" class="title">{{liveContent.title}}</h3>
        <span v-if="editBtnShow && !isShowEditTitle" @click="editBtn('title')" style="font-size: 20px;color: #9c9b9b;cursor: pointer;"><a-icon type="edit" /></span>

      </div>
      <div style="display: flex">
        <div class="content" style="width: 50%;">
          <div class="font" @mouseover="mouseoverBtn('columTitle')" @mouseleave="mouseleaveBtn('columTitle')">会议分类：

<!--            <a-input v-if="isShowEditColumTitle" @blur="pressEnterBtn('columTitle')" v-model="editForm.columTitle" style="width: 200px;" placeholder="请输入"/>-->
<!--            <a-select-->
<!--                v-if="isShowEditColumTitle"-->
<!--                show-search-->
<!--                option-filter-prop="children"-->
<!--                @change="columTitleChange"-->
<!--                @mouseleave="columTitleBlur"-->
<!--                style="width: 200px;"-->
<!--                v-model="editForm.columId"-->
<!--                placeholder="默认分类"-->
<!--            >-->
<!--              <a-select-option-->
<!--                  v-for="lives in liveClassifyList"-->
<!--                  :value="lives.id"-->
<!--              >-->
<!--                {{ lives.title }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->

<!--            <span v-if="!isShowEditColumTitle">{{liveContent.columTitle}}</span>-->
            <span>{{liveContent.columTitle?liveContent.columTitle:'默认分类'}}</span>

            <span v-if="editBtnShowColumTitle && !isShowEditColumTitle" @click="editBtn('columTitle')" style="font-size: 14px;color: #9c9b9b;margin-left: 10px;cursor: pointer;"><a-icon type="edit" /></span>

          </div>
          <div class="font"  @mouseover="mouseoverBtn('beginDate')" @mouseleave="mouseleaveBtn('beginDate')">会议日期：
<!--            <a-range-picker-->
<!--                @blur="onChange"-->
<!--                style="width: 210px"-->
<!--                v-if="isShowEditBeginDate"-->
<!--                :allowClear="false"-->
<!--                :default-value="[moment(liveContent.beginDate, dateFormat), moment(liveContent.endDate, dateFormat)]"-->
<!--                @change="onChange"-->
<!--            />-->
<!--            <span v-if="!isShowEditBeginDate">{{liveContent.beginDate}} - {{liveContent.endDate}}</span>-->
            <span>{{conversionTimeFormat(liveContent.beginDate)}}-{{(conversionTimeFormat(liveContent.endDate)).slice(5, 11)}}</span>
            <span v-if="editBtnShowBeginDate && !isShowEditBeginDate" @click="editBtn('beginDate')" style="font-size: 14px;color: #9c9b9b;margin-left: 10px;cursor: pointer;"><a-icon type="edit" /></span>

          </div>
          <div class="font" @mouseover="mouseoverBtn('address')" @mouseleave="mouseleaveBtn('address')">会议地点/描述：

            <a-input ref="addressRef" v-if="isShowEditAddress" @blur="pressblurBtn('address')" @pressEnter="pressEnterBtn('address')" v-model="editForm.address" style="width: 200px;" placeholder="请输入"/>
            <span v-if="!isShowEditAddress">{{liveContent.address}}</span>
            <span v-if="editBtnShowAddress && !isShowEditAddress" @click="editBtn('address')" style="font-size: 14px;color: #9c9b9b;margin-left: 10px;cursor: pointer;"><a-icon type="edit" /></span>

          </div>
        </div>
        <div  class="content" style="width: 30%;">
          <div class="font">背景/主色：<a @click="openBackdrop(liveContent)">设置</a></div>
          <div class="font">转发封面图：<a @click="openTransmit(liveContent)"><span :style="{color: liveContent.relayImg ? '#1890FF':'#b8b8b8'}">{{liveContent.relayImg ? '设置':'未设置'}}</span></a></div>
<!--          <div class="font">电脑版：<span :style="{color: liveContent.isOpenPc ? '#1890FF':'#b8b8b8',cursor: 'pointer'}" @click="PCSetup(liveContent)">{{liveContent.isOpenPc ? '已开启':'未开启'}}</span></div>-->
          <div class="font">电脑版：
<!--            是否确认开启电脑版”，“是否确认关闭电脑版 -->
            <a-popconfirm
                :title="liveContent.isOpenPc ? '是否确认关闭电脑版?':'是否确认开启电脑版?'"
                ok-text="是"
                cancel-text="否"
                @confirm="PCSetupIsOpen(liveContent.isOpenPc === 1 ? 0:1)"
                style="margin-right: 10px;">
              <span :style="{color: liveContent.isOpenPc ? '#1890FF':'#b8b8b8',cursor: 'pointer'}">{{liveContent.isOpenPc ? '已开启':'未开启'}}</span>
            </a-popconfirm>
          </div>
        </div>

        <div class="content" style="width: 20%;">
          <div class="font">主页模版：<a @click="openTemplate(liveContent)">更换模版</a></div>
          <div class="font">图标模版：<a-popconfirm
                title="是否确认更换图标?"
                ok-text="是"
                cancel-text="否"
                @confirm="openIconTemplate(liveContent)"
            >
              <a>更换图标</a>
            </a-popconfirm>

          </div>
        </div>
      </div>
    </div>
    <div class="topBtn">
      <a-switch
          style="width: 78px"
          :checked="isShowIssueBtn ? true:false"
          checked-children="已发布"
          un-checked-children="未发布"
          @change="isPublishedChange(isShowIssueBtn ? 0:1)"
          default-checked
      />
      <p></p>
      <a-button style="width: 78px" size="small" type="primary" shape="round" @click="liveLink(liveContent.tempUrl)"><a-icon type="eye" />预览</a-button>
      <p></p>
      <a-button style="width: 78px" size="small" shape="round" @click="dataBtn(liveContent)"><a-icon type="line-chart" />统计</a-button>
    </div>
  </div>
<!--  <div  v-if="isShowBtn" style="text-align: right;margin-bottom: 10px">-->
<!--    <a-button size="small" shape="round" @click="PCSetup(liveContent)">电脑端设置</a-button>-->
<!--    <a-button size="small" style="margin-left: 5px;margin-right: 5px" shape="round" @click="editLive(liveContent)">编辑会议</a-button>-->
<!--    <a-button size="small" style="margin-right: 5px" shape="round" @click="dataBtn(liveContent)">数据分析</a-button>-->

<!--&lt;!&ndash;    当发布会议并支付订单完成后-&#45;&#45;显示此按钮&ndash;&gt;-->
<!--    <a-button v-if="isShowIssueBtn" size="small" style="margin-right: 5px" type="primary" shape="round" @click="liveLink(liveContent.tempUrl)">会议链接</a-button>-->

<!--&lt;!&ndash;    当发布会议并支付订单完成后-&#45;&#45;隐藏此按钮&ndash;&gt;-->
<!--    <a-button v-if="!isShowIssueBtn" size="small" style="margin-right: 5px" type="primary" shape="round" @click="liveLinkH5(liveContent.tempUrl)">会议预览</a-button>-->
<!--&lt;!&ndash;    <a-button v-if="!isShowIssueBtn" size="small" style="margin-right: 10px;" type="primary" shape="round" @click="issueLive(liveContent)">发布会议</a-button>&ndash;&gt;-->

<!--    <a-popconfirm-->
<!--        v-if="!isShowIssueBtn"-->
<!--        title="是否确认发布?"-->
<!--        ok-text="是"-->
<!--        cancel-text="否"-->
<!--        @confirm="issueLive(liveContent)"-->
<!--        style="margin-right: 10px;">-->
<!--      <a-button size="small" type="primary" shape="round" >发布会议</a-button>-->
<!--    </a-popconfirm>-->
<!--  </div>-->
  <!--   链接   -->
  <a-modal
      width="800px"
      v-model="linkVisible"
      title="链接"
      :footer="null">
    <div>
      <div class="linkModal">
        <div class="title">微网站访问链接 :</div>
        <div>
          <a-input v-model="appSrc" style="width: 400px" :disabled="true"/>
        </div>
        <div style="margin-left: 10px">
          <a-icon
              @click="copyText"
              style="color:#45a5e6;
                  font-size: 20px;
                  cursor: pointer"
              type="copy" />
        </div>
      </div>
      <div class="codeLink">
        <div class="title">观看链接二维码:</div>
        <div style="padding: 15px;border: 1px dashed #000;background-color: #fff;"><div id="qrCode">
          <vue-qr
              ref="image"
              :logo-src="logoSrc"
              :size="150"
              :margin="0"
              :auto-color="true"
              :dot-scale="1"
              :text="appSrc" />
        </div></div>
        <div>
          <a-icon
              @click="copyImage"
              class="iconStys"
              type="copy" />
        </div>
        <div>
          <a-icon
              @click="downImg"
              class="iconStys"
              type="vertical-align-bottom" />
        </div>
      </div>
    </div>
  </a-modal>
  <!-- 编辑-->
  <a-drawer
      :get-container="false"
      @close="rest_cancel"
      :body-style="{ paddingBottom: '80px' }"
      width="680px"
      :visible="list_visible"
      title="编辑">
    <a-form-model
        ref="content_list_Form_rest"
        :model="list_Form"
        :rules="list_Form_rules"
        :label-col="labelColAdd"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item prop="coverPics" label="封面图">
        <div style="position: relative;width: 288px;">
          <div class="upImgModel" @click="openImgModel('editCoverPics')">
            <div class="content" v-if="!list_Form.coverPics[0]">
              <div class="addModel">+</div>
              <div>
                上传
              </div>
            </div>
            <div>
              <img style="width: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left:0.6%"
                   :src="list_Form.coverPics[0]" alt="">
            </div>
          </div>
          <div class="hoverBgc" v-if="list_Form.coverPics[0]">
            <div class="iconDel">
              <admin_con
                  @click="()=>{list_Form.coverPics = []}"
                  type="icon-shanchu1"/>
            </div>
          </div>
        </div>
        <div style="color: #a1a1a1">注:建议尺寸1920px*1080px,大小不超过2M</div>
      </a-form-model-item>
      <a-form-model-item prop="title" label="会议名称">
        <a-input v-model="list_Form.title" style="width: 300px;" placeholder="请输入"/>
        <div style="color: #a1a1a1;font-size: 12px">注：会议名称最长不能超过60个汉字</div>
      </a-form-model-item>
      <a-form-model-item prop="beginDate" label="会议日期">

        <div style="display: flex">
          <a-range-picker
              style="width: 300px"
              @change="picher_onChange"
              :allowClear="false"
              :value="[moment(list_Form.beginDate, 'YYYY-MM-DD'), moment(list_Form.endDate, 'YYYY-MM-DD')]"
              :format="'YYYY-MM-DD'"/>
        </div>
      </a-form-model-item>
      <a-form-model-item prop="address">
        <template slot="label">
              <span>会议地址
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>{{tooltipAddress}}</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </span>
        </template>
        <a-input style="width: 300px" v-model="list_Form.address" placeholder="请输入"/>
      </a-form-model-item>
      <!--              <a-form-model-item  prop="columId" label="会议分类">-->
      <a-form-model-item label="会议分类">
        <div style="display: flex;width: 400px">
          <a-select
              show-search
              option-filter-prop="children"
              style="width: 300px;"
              class="input"
              v-model="list_Form.columId"
              placeholder="请选择">
            <a-select-option
                v-for="liveClassify in liveClassifyList"
                :value="liveClassify.id">
              {{liveClassify.title}}
            </a-select-option>
          </a-select>
          <a-icon @click="liveClassifyRow" class="iconStY" type="redo" />
          <a-icon @click="loadList" class="iconStY" type="plus-square" />
        </div>
      </a-form-model-item>
      <a-form-model-item label="主色值" >
        <a-input v-model="list_Form.mainColor" style="width: 300px;">
          <input
              :value="list_Form.mainColor"
              type="color"
              slot="addonBefore"
              @change="colorRest"
              style="outline-style: none ;border: 0; padding: 0;height: 29px">
        </a-input>
      </a-form-model-item>
      <a-form-model-item  label="背景图">
        <div style="position: relative;width: 288px;">
          <div class="upImgModel" @click="openImgModel('editHomeCoverImage')">
            <div class="content" v-if="!list_Form.homeCoverImage">
              <div class="addModel">+</div>
              <div>
                上传
              </div>
            </div>
            <div>
              <img style="width: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left:0.6%"
                   :src="list_Form.homeCoverImage" alt="">
            </div>
          </div>
          <div class="hoverBgc" v-if="list_Form.homeCoverImage">
            <div class="iconDel">
              <admin_con
                  @click="()=>{list_Form.homeCoverImage = ''}"
                  type="icon-shanchu1"/>
            </div>
          </div>
        </div>
        <div style="color: #a1a1a1">注:宽度不能超过750px，大小不超过1M</div>
      </a-form-model-item>
      <a-form-model-item label="转发封面图">
        <div style="position: relative;width: 288px;">
          <div class="upImgModel_relayImg" @click="openImgModel('editRelayImg')">
            <div class="content_relayImg" v-if="!list_Form.relayImg">
              <div class="addModel_relayImg">+</div>
              <div>
                上传
              </div>
            </div>
            <div>
              <img v-if="list_Form.relayImg" style="width: 278px;height: 278px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left:0.6%"
                   :src="list_Form.relayImg" alt="">
            </div>
          </div>
          <div class="hoverBgc_relayImg" v-if="list_Form.relayImg">
            <div class="iconDel_relayImg">
              <admin_con
                  @click="()=>{list_Form.relayImg = ''}"
                  type="icon-shanchu1"/>
            </div>
          </div>
        </div>
        <div style="color: #a1a1a1">注:尺寸建议500*500，大小不超过500kb</div>
      </a-form-model-item>
    </a-form-model>
    <div
        :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
              }"
    >
      <a-button  @click="rest_cancel">
        取消
      </a-button>
      <a-button style="margin-left: 10px" type="primary" @click="content_list_Ok">
        确认
      </a-button>
    </div>
  </a-drawer>

  <pictureDialog
      :info="pInfo"
      :visible="visible"
      v-on:closeMain="closeMain"
      v-on:checkList="checkList">
  </pictureDialog>
  <!-- 新增对话框 -->
  <a-modal
      title="新增"
      :visible="visibleClass"
      @ok="handleOk"
      @cancel="handleCancel"
      style="width:200px;height: 130px;"
  >
    <a-form-model :model="form"  :rules="addRules" ref="ruleForm_add" style="display:flex">
      <a-form-model-item label="分类标题" prop="name" style="display:flex">
        <a-input  placeholder="请输入"   v-model="form.name"  style="display:flex;margin-top:5px;width:220px"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
  <!-- 电脑端设置  -->
  <a-drawer
      :get-container="false"
      @close="PCSetup_cancel"
      :body-style="{ paddingBottom: '80px' }"
      width="600px"
      :visible="PCSetup_visible"
      title="电脑端设置">
    <a-form-model
        ref="PCSetupRef"
        :model="PCSetupForm"
        :rules="PCSetupRules"
        :label-col="labelColedit"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item prop="banner" label="是否开启电脑端微网站">
        <a-switch
            :checked="PCSetupForm.isOpenPc === 1 ? true : false"
            checked-children="是"
            un-checked-children="否"
            @change="switchEditBtn(PCSetupForm.isOpenPc === 0 ? 1 : 0)"
            default-checked
        />
      </a-form-model-item>
      <a-form-model-item prop="pcBanner" label="电脑端banner">
        <div style="position: relative;width: 288px;">
          <div class="upImgModelBanner" @click="openImgModel('pcBanner')">
            <div class="content" v-if="!PCSetupForm.pcBanner">
              <div class="addModel">+</div>
              <div>
                上传
              </div>
            </div>
            <div>
              <img v-if="PCSetupForm.pcBanner" style="width: 370px; height: 100px;border-radius: 10px;margin-top: 0.6%;margin-bottom: 0.6%;margin-left:0.8%"
                   :src="PCSetupForm.pcBanner" alt="">
            </div>
          </div>
          <div class="hoverBgcIconBanner" v-if="PCSetupForm.pcBanner">
            <div class="iconDel">
              <admin_con
                  @click="()=>{PCSetupForm.pcBanner = ''}"
                  type="icon-shanchu1"/>
            </div>
          </div>
        </div>
        <div style="color: #a1a1a1">注：建议尺寸3700 : 1000 大小不超过2M</div>
      </a-form-model-item>
      <div>
        <div><span style="color: red;">*</span> 外链功能图标设置</div>
        <a-table
            :pagination="false"
            style="margin-top: 20px"
            :columns="columns"
            :data-source="dataList">
            <span slot="icon" slot-scope="text, record, index">
              <div style="position: relative;width: 50px;">
                <a-popconfirm placement="bottom" @confirm="confirmPop" @cancel="cancelPop">
                  <span slot="title">
                    <div style="width: 400px;height: 200px;overflow-y: scroll;margin-left: -20px">
                      <a-row type="flex">
                        <a-col :span="4"
                               v-for="(list, index) in iconList"
                               :key="index"
                               style="display: flex;justify-content: center;margin-top: 30px">
                          <img
                              @click="selectIcon(index,list.img)"
                              v-if="list.img"
                              :style="{
                                border:iconTemplate === index ? '3px solid rgba(0,0,0,.3)' : '', cursor: 'pointer',}"
                              class="popCard"
                              :src="list.img" alt="">
                        </a-col>
                      </a-row>
                    </div>
                  </span>
                  <span slot="icon"></span>
                  <div class="upImgModelIcon" @click="openPropver(record)">
                    <div class="content" v-if="!record.pcIconUrl">
                      <div class="addModel">+设置</div>
                    </div>
                    <div>
                      <img v-if="record.pcIconUrl"
                           class="contentImg"
                           :src="record.pcIconUrl" alt="">
                    </div>
                  </div>
                </a-popconfirm>
                  <div class="hoverBgcIcon" v-if="record.pcIconUrl">
                    <div class="iconDel">
                      <admin_con @click="()=>{record.pcIconUrl = ''}" type="icon-shanchu1"/>
                    </div>
                  </div>
              </div>
            </span>
        </a-table>
      </div>
    </a-form-model>
    <div
        :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
              }"
    >
      <a-button  @click="PCSetup_cancel">
        取消
      </a-button>
      <a-button style="margin-left: 10px" type="primary" @click="PCSetup_ok">
        确认
      </a-button>
    </div>
  </a-drawer>

  <!--    确认产品信息 v-if="ProductVisible"   :data="ProductInforList" -->
  <ProductInfor
      v-if="ProductVisible"
      :visible="ProductVisible"
      :orderNumber="orderNumber"
      :conferenceCode="conferenceCode"
      :publishedConvention="true"
      v-on:closeMainProduct="closeMainProduct"
      v-on:OpenPayModel="OpenPayModel"
  />
  <!--    订单支付  -->
  <pay-indent
      v-if="payVisible"
      :orderNumber="orderNumber"
      :visible="payVisible"
      :publishedConvention="true"
      :conferenceCode="conferenceCode"
      v-on:closeMain="closeMainPay"
  />
  <!--  背景/主色-->
  <a-modal
      @ok="OkBackdrop"
      @cancel="cancelBackdrop"
      width="680px"
      :visible="backdrop_visible"
      title="背景/主色">
    <a-form-model
        ref="content_list_Form_rest"
        :model="backdrop_Form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="主色值" >
        <a-input v-model="backdrop_Form.mainColor" style="width: 290px;">
          <input
              :value="backdrop_Form.mainColor"
              type="color"
              slot="addonBefore"
              @change="colorRestBackdrop"
              style="outline-style: none ;border: 0; padding: 0;height: 29px">
        </a-input>
      </a-form-model-item>
      <a-form-model-item  label="背景图">
        <div style="position: relative;width: 288px;">
          <div class="upImgModel" style="width: 200px;">
            <div class="content" v-if="!backdrop_Form.homeCoverImage">
              <div class="addModel">+</div>
              <div>
                上传
              </div>
            </div>
            <div>
              <img style="width: 190px;max-height:300px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left:0.6%"
                   :src="backdrop_Form.homeCoverImage" alt="">
            </div>
          </div>
          <div class="hoverBgc" style="width: 200px" v-if="backdrop_Form.homeCoverImage">
            <div class="iconDel">
              <admin_con @click="()=>{backdrop_Form.homeCoverImage = ''}" type="icon-shanchu1"/>
            </div>
          </div>
          <div class="hoverBgc" style="width: 200px" v-if="!backdrop_Form.homeCoverImage">
            <div class="iconDelDiv">
              <div class="iconDel-cont">
                <div class="customUpload" @click="openImgModel('backdropHomeCoverImage')">自定义上传</div>
                <div class="customUpload" @click="openSystemBGI">选择系统背景</div>
              </div>
            </div>
          </div>
        </div>
        <div style="color: #a1a1a1">注:宽度不能超过750px，大小不超过1M</div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>

  <!--  转发封面图-->
  <a-modal
      @ok="OkTransmit"
      @cancel="cancelTransmit"
      width="700px"
      :visible="Transmit_visible"
      title="转发封面图">
    <a-form-model
        ref="Transmit_ref"
        :model="Transmit_Form"
        :label-col="labelColTransmit"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item  label="转发封面图">
        <div style="position: relative;width: 288px;">
          <div class="upImgModel" @click="openImgModel('TransmitRelayImg')">
            <div class="content" v-if="!Transmit_Form.relayImg">
              <div class="addModel">+</div>
              <div>
                上传
              </div>
            </div>
            <div>
              <img style="width: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left:0.6%"
                   :src="Transmit_Form.relayImg" alt="">
            </div>
          </div>
          <div class="hoverBgc" v-if="Transmit_Form.relayImg">
            <div class="iconDel">
              <admin_con
                  @click="()=>{Transmit_Form.relayImg = ''}"
                  type="icon-shanchu1"/>
            </div>
          </div>
        </div>
        <div style="color: #a1a1a1;width: 700px;">注：可上传logo，建议上传正方形图片，大小不超过5M，图片太大部分手机可能会不显示</div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>

  <!--      数据报告-->
  <DataReport
      v-if="visibleDataReport"
      :liveInfo="liveInfo"
      :selectNumber="selectId"
      :visible="visibleDataReport"
      v-on:closeMainDataReport="closeMainDataReport"
  ></DataReport>

  <!--  选择模板-->
  <TemplateList
      v-if="Template_visible"
      :visible="Template_visible"
      v-on:closeMainTemplate="closeMainTemplate"
      v-on:selectTemplate="selectTemplate"
  />

<!--  会议分类  -->
  <a-modal
      @ok="OkClassify"
      @cancel="cancelClassify"
      width="500px"
      :visible="classify_visible"
      title="会议分类">
   <div style="display: flex">
     <a-select
         show-search
         option-filter-prop="children"
         style="width: 300px;"
         v-model="editForm.columId"
         placeholder="请选择"
         @change="columTitleChange"
     >
       <a-select-option
           v-for="lives in liveClassifyList"
           :value="lives.id"
       >
         {{ lives.title }}
       </a-select-option>
     </a-select>


     <a-icon @click="liveClassifyRow" class="iconStY" type="redo" />
     <a-icon @click="loadList" class="iconStY" type="plus-square" />
   </div>
  </a-modal>
<!--  会议日期-->
  <a-modal
      @ok="OkDate"
      @cancel="cancelDate"
      width="500px"
      :visible="date_visible"
      title="会议日期">
    <a-range-picker
        :disabled-date="disabledDate"
        style="width: 300px"
        :allowClear="false"
        :default-value="[moment(liveContent.beginDate, dateFormat), moment(liveContent.endDate, dateFormat)]"
        @change="onChange"
        @calendarChange="DateChange"
    />
  </a-modal>

  <SystemIcons
      v-if="systemIconsVisible"
      :visible="systemIconsVisible"
      v-on:closeMainSystemIcons="closeMainSystemIcons"
      v-on:checkListSystemIcons="checkListSystemIcons"
  ></SystemIcons>

  <IconTemplate
      v-if="IconTemplateVisible"
      :visible="IconTemplateVisible"
      :listObj="IconTemplateObj"
      v-on:closeMainSystemIcons="closeMainIconTemplate"
      v-on:checkListIconTemplate="checkListIconTemplate"
  />
  <!--    系统背景图-->
  <BackgroundImage
      v-if="BackgroundImageVisible"
      :visible="BackgroundImageVisible"
      v-on:closeMainBackgroundImage="closeMainBackgroundImage"
      v-on:checkListBackgroundImage="checkListBackgroundImage"
  />
</div>
</template>

<script>
import DataReport from "@/components/meet/DataReport";
import SystemIcons from "@/components/meet/SystemIcons";
import payIndent from "@/components/PaidView/payIndent";
import {
  conventionCode,
  getLinkFuncByCode, getNoPayOrderNumberBy, getPayResult,
  liveClassify,
  putSwitch,
  setPcImg,
} from "@/service/MedicalConference";
import PictureDialog from "@/components/Material/pictureDialog";
import ProductInfor from "@/components/PaidView/ProductInfor";
// 在需要生成二维码的文件中引入比如qrCode.vue
import VueQr from 'vue-qr';
import moment from "moment";
import {admin_icon} from "@/utils/myIcon";
import {AddManagement} from "@/service/columnmanageapi";
import { iconModelList } from "@/utils/utilsList";
import {publishedConvention} from "@/service/authentication";
import TemplateList from "@/components/meet/TemplateList";
import IconTemplate from "@/components/meet/IconTemplate";
import BackgroundImage from "@/components/meet/BackgroundImage";
import {iconList, postReplacementIcon} from "@/service/photoCommander";
const columns = [
  {
    title: '图标',
    key: 'pcIconUrl',
    dataIndex: 'pcIconUrl',
    align:'center',
    width:150,
    scopedSlots: { customRender: 'icon' },
  },{
    title: '名称',
    key: 'title',
    dataIndex: 'title',
    scopedSlots: { customRender: 'titles' },
  }];
export default {
name: "ConventionCase",
  components:{IconTemplate, TemplateList, VueQr,PictureDialog,admin_con: admin_icon,ProductInfor,DataReport,SystemIcons,BackgroundImage,payIndent},
  props: {
    conferenceCode: {
      type: String,
    },
    isShowBtn: {
      type: Boolean,
    },
  },
  computed:{
    isShowIssueBtn() {
      return this.$store.state.isShowIssueBtn;
    },
    meetCode() {
      return this.$store.state.meetCode;
    },
  },
  data(){
    return{
      BackgroundImageVisible:false,
      IconTemplateVisible:false,
      IconTemplateObj:{},
      systemIconsVisible:false,
      selectId:1,
      ProductInforList:{},
      ProductVisible:false,
      payVisible:false,
      columns,
      dataList:[],
      iconList:iconModelList(),
      liveContent:{},
      linkVisible:false,
      visibleClass:false,
      list_visible:false,
      visible:false,
      visiblePropver:false,
      PCSetup_visible:false,
      iconRow:{},
      appSrc:"",
      logoSrc:"",
      listId:null,
      list_Form:{
        title:"",
        address:"",
        beginDate:'',
        endDate:'',
        mainColor:'#45A5E6',
        coverPics:[],
        homeCoverImage:'',
        // sortTime:'',
        relayImg:'',
        columId:undefined,
      },
      list_Form_rules: {
        title: [{ required: true, message: '请输入', trigger: 'blur' },{ min: 1, max: 60, message: '会议名称最长不能超过60个汉字', trigger: 'blur' },],
        address: [{ required: true, message: '请输入', trigger: 'blur' }],
        beginDate: [{ required: true, message: '请选择', trigger: 'blur' }],
        // columId: [{ required: true, message: '请选择', trigger: 'change' }],
        coverPics: [{ required: true, message: '请上传', trigger: 'blur' }],
        // sortTime: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      labelCol: { span: 2},
      labelColTransmit: { span: 3},
      labelColAdd: { span: 6},
      labelColedit: { span: 7},
      wrapperCol: { span: 14 },
      openModelType:null,
      picList:[],
      pInfo:true,
      liveClassifyList:[
        {
          id:0,
          title:'默认分类'
        }
      ],
      tooltipAddress:'显示在转发之后的消息摘要处',
      form:{},
      addRules:{
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      PCSetupRules:{
        pcBanner: [{ required: true, message: '请上传', trigger: 'change' }]
      },
      PCSetupForm:{
        pcBanner:'',
        isOpenPc:0,
      },
      iconTemplate:null,
      iconUrl:null,
      orderNumber:'',

      //  修改
      editForm:{
        columId:null,
      },

      isShowEditTitle:false,
      editBtnShow:false,

      isShowEditColumTitle:false,
      editBtnShowColumTitle:false,

      isShowEditBeginDate:false,
      editBtnShowBeginDate:false,
      dateFormat : 'YYYY-MM-DD',

      isShowEditAddress:false,
      editBtnShowAddress:false,

      backdrop_Form:{
        mainColor:'#45A5E6',
        homeCoverImage:''
      },
      backdrop_visible:false,

      Transmit_Form:{
        relayImg:'',
      },

      Transmit_visible:false,

      liveInfo:{},
      visibleDataReport:false,

      Template_visible:false,

      classify_visible:false,
      date_visible:false,

      offsetDays: 86400000 * 6, //最多选择7天
      selectPriceDate: "",

      isTitleBlur:1,
    }
  },
  created() {
    this.getList();
    this.liveClassifyRow();
  },
  methods:{
    closeMainBackgroundImage(val) {
      this.BackgroundImageVisible = val;
    },
    checkListBackgroundImage(val){
      this.backdrop_Form.homeCoverImage = val;
    },
    closeMainSystemIcons(val) {
      this.systemIconsVisible = val;
    },
    async checkListSystemIcons(val) {
      // 打印选择的模板id,并进行图标模板修改
      if (val.iconTemplateId) {
        let data = {
          "conventionCode": this.conferenceCode, //会议code
          "iconTempId": val.iconTemplateId, //图标模板id
          "isCleanUp":1
        }
        const response = await postReplacementIcon(data);
        if (response.code === 200) {
          this.$message.success("修改成功！");
          await this.getList();
          //调用父级类方法，刷新
          this.$parent.$parent.getLists();
          this.$parent.$parent.getFeatureList();
        } else {
          this.$message.warning(response.message);
        }
      }
    },
    // async getIsShowIssueBtn() {
    //   const response = await conventionNoPayOrder(this.orderNumber)
    //   if(response.code === 200){
    //     this.ProductInforList = response.data;
    //   }else {
    //     this.$message.warning(response.message);
    //   }
    // },
    //根据会议code获取未支付的订单号
    async getNoPayOrderNumber() {
      const response = await getNoPayOrderNumberBy(this.conferenceCode)
      if(response.code === 200){
        // 1:有订单
        // 0：无订单
        // 2：上传银行回执待确认
        if(response.data === 1){ //1:有订单
          this.orderNumber = response.message;
          // await this.getIsShowIssueBtn();
          this.ProductVisible = true;
        }else if(response.data === 0){ //0：无订单 直接发布
          await this.publishedConventions(this.conferenceCode);
        }else if(response.data === 2){ // 2：上传银行回执待确认
          this.$message.info("已上传银行回执等待客服确认，若有问题请联系客服！")
        }
      }
    },
    //发布会议
    async publishedConventions(code) {
      const response = await publishedConvention(code);
      if (response.code === 200) {
        this.$message.success("成功发布会议！");
        this.getList();
      } else {
        this.$message.warning(response.message);
      }
    },
    //发布会议按钮
    issueLive() {
      //判断有无发布订单
      this.getNoPayOrderNumber();
    },
    closeMainProduct(val){
      this.ProductVisible = val;
      this.getList();
    },
    OpenPayModel(){
      this.payVisible = true;
    },
    closeMainPay(val){
      this.payVisible = val;
    },
    selectIcon(index,iconUrl){
      this.iconTemplate = index;
      this.iconUrl = iconUrl;
    },
    confirmPop(){
      if(this.iconUrl){
        let data = {
          id:this.iconRow.id,
          pcIconUrl:this.iconUrl,
        }
        this.putLinkFunc(data)
      }else {
        this.$message.warning('请先选择图标！')
      }
    },
    cancelPop(){
      this.iconUrl = null;
      this.iconTemplate = null;
      this.iconRow = {};
    },
    openPropver(record){
      this.visiblePropver = true;
      this.iconRow = record;
    },
    switchEditBtn(isOpen){
      this.PCSetupForm.isOpenPc = isOpen;
    },
    content_list_Ok(){
      this.$refs.content_list_Form_rest.validate(async valid => {
        if (valid) {
          let form = this.list_Form
          let data = {
            "conferenceCode":form.conferenceCode,//会议编码
            "address": form.address,//会议地址
            "beginDate": form.beginDate,//会议日期-开始
            // "conventionHead": "冯明霞",//
            "coverPics": form.coverPics,//封面图
            "domainCode": "yichimeeting",//写死传过来
            "endDate": form.endDate,//会议日期-结束
            "homeCoverImage": form.homeCoverImage,//背景图
            "mainColor": form.mainColor,//主色值
            // "sortTime": form.sortTime,//直播开始时间
            // "tempUrl": "https://yichimeeting-mp.yichimeeting.com/conference",//选择模板的链接
            "title": form.title,//会议标题
            "relayImg": form.relayImg,//转发封面图
            "columId": form.columId//会议所属分类id
          }
          const response = await putSwitch(data)
          if (response.code === 0) {
            this.$message.success("修改成功！")
            //关闭
            this.list_visible = false
            //  刷新
            await this.getList();
            //调用父级类方法，刷新banner图片
            this.$parent.$parent.getLists();
          } else {
            this.$message.warning(response.message)
          }
        } else {
          return false;
        }
      });
    },
    rest_cancel(){
      this.list_visible = false;
      this.$refs.content_list_Form_rest.resetFields();
    },
    colorRest(e){
      this.list_Form.mainColor = e.target.value
    },
    colorRestBackdrop(e){
      this.backdrop_Form.mainColor = e.target.value
    },
    handleCancel(){
      this.visibleClass = false;
    },
    handleOk(){
      this.$refs.ruleForm_add.validate(valid=> {
        if (valid) {
          let data = {
            title: this.form.name,
            type:'meeting'
          }
          this.postClass(data)
          this.form.name = '';
          this.visibleClass = false;
        }
      })
    },
    async postClass(data) {
      const response = await AddManagement(data)
      if (response.code === 200) {
        this.editForm.columId = response.data;
        this.$message.success("新增成功！");
        this.liveClassifyList = [
          {
            id:0,
            title:'默认分类'
          }
        ];
        await this.liveClassifyRow();
      } else {
        this.$message.warning(response.message)
      }
    },
    loadList(){
      this.visibleClass = true;
    },
    async liveClassifyRow() {
      this.liveClassifyList = [
        {
          id:0,
          title:'默认分类'
        }
      ];
      let data = {
        pageNo:1,
        pageSize: 9999999,
        type:'meeting'
      }
      const response = await liveClassify(data)
      if(response.code === 0){
        let list = response.data;
        for (let i=0;i<list.length;i++){
          this.liveClassifyList.push(list[i]);
        }
      }else {
        this.$message.warning(response.message)
      }
    },

    moment,
    picher_onChange(value, dateString){
      this.list_Form.beginDate = dateString[0]
      this.list_Form.endDate = dateString[1]
    },
    openImgModel(type){
      this.visible = true;
      this.openModelType = type;
    },
    openSystemBGI(){
      this.BackgroundImageVisible = true;
    },
    checkList(val) {
      this.picList = val[0].url;
      let type = this.openModelType;
      if(type === 'coverPics'){ // 封面图
        this.add_Form.coverPics[0] = this.picList;
      }else if(type === 'homeCoverImage'){ //背景图
        this.add_Form.homeCoverImage = this.picList;
      }else if(type === 'relayImg'){ //转发封面图
        this.add_Form.relayImg = this.picList;
      }else if(type === 'editCoverPics'){ //修改-封面图
        this.list_Form.coverPics[0] = this.picList;
      }else if(type === 'editHomeCoverImage'){ //修改-背景图
        this.list_Form.homeCoverImage = this.picList;
      }else if(type === 'editRelayImg'){ //修改-转发封面图
        this.list_Form.relayImg = this.picList;
      }else if(type === 'pcBanner'){
        this.PCSetupForm.pcBanner = this.picList;
      }else if(type === 'tableImg'){
        this.PCSetupForm.pcBanner = this.picList;
      }else if(type === 'backdropHomeCoverImage'){
        this.backdrop_Form.homeCoverImage = this.picList;
      }else if(type === 'forwardingCover'){
        this.editForm.relayImg = this.picList;
        let data = {
          "conferenceCode": this.conferenceCode,//会议编码
          "relayImg": this.editForm.relayImg,
        }
        this.editApi(data);
      }else if(type === 'TransmitRelayImg'){
        this.Transmit_Form.relayImg = this.picList;
        // let data = {
        //   "conferenceCode": this.conferenceCode,//会议编码
        //   "relayImg": this.Transmit_Form.relayImg,
        // }
        // this.editApi(data);
      }
    },
    closeMain(val) {
      this.visible = val;
    },
    //可以看到src并非url，而是base64字符串。base64转Blob：
    base64ToBlob(dataurl) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    downImg(){
      let container = document.getElementById('qrCode').childNodes[0];

      let blob = this.base64ToBlob(container.getAttribute('src'));

      let aLink = document.createElement('a');

      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = '文件名';
      aLink.href = URL.createObjectURL(blob);
      aLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));//兼容火狐
    },
    //点击复制二维码
    copyImage() {
      let container = document.getElementById('qrCode').childNodes[0];
      let myBlob = this.base64ToBlob(container.getAttribute('src'));

      navigator.clipboard.write([
        new window.ClipboardItem({
          [myBlob.type]: myBlob
        })
      ]);

      this.$message.success("图片已复制到剪贴板！")
    },
    copyText() {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = this.appSrc;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('文本已复制到剪贴板!')
      //移除input标签
      document.body.removeChild(input)
    },
    //获取数据
    async getList() {
      const response = await conventionCode(this.conferenceCode)
      if(response.code === 0){
        this.liveContent = response.data;
        //会议发布
        this.$store.dispatch("isShowIssueBtn", response.data.isPublished);
      }
    },
    returnBtn(){
      if(this.$route.query.memberId){
        this.$router.push({path:'/PathmeetingManagement',query: {memberId:this.$route.query.memberId}});
      }else {
        this.$router.push({path:'/PathmeetingManagement'});
      }
      this.$store.dispatch('isShowNav',true); //恢复导航栏
    },
    dataBtn(row){
      this.liveInfo = row;
      this.visibleDataReport = true;
      // this.$router.push({path:'dataStatistics',query:{code:row.conferenceCode}});
      // this.$store.dispatch("isShowNav", false);
      //
      // this.$store.commit('dataAnalysisId',row.conferenceCode)
      // //保存会议创建时间
      // this.$store.dispatch("statisticsLiveCreated", row.createdTime);
    },
    PCSetup(row){
      //赋值
      this.PCSetupForm.isOpenPc = row.isOpenPc;
      this.PCSetupForm.pcBanner = row.pcBanner;
      //table
      this.getLinkFunc();
      this.PCSetup_visible = true;
    },
    async getLinkFunc() {
      const response = await getLinkFuncByCode(this.conferenceCode)
      if(response.code === 0){
        this.dataList = response.data;
      }
    },
    PCSetup_cancel(){
      this.PCSetup_visible = false;
    },
    PCSetup_ok(){
      this.$refs.PCSetupRef.validate(async valid => {
        if (valid) {
          // console.log(this.PCSetupForm)
          let isNext = true;
          //判断table的list是否为空
          for (let i=0;i<this.dataList.length;i++){
            if(!this.dataList[i].pcIconUrl){
              this.$message.warning("外联功能图标有空！")
              isNext = false;
              return;
            }
          }
          if(isNext){
            let data = {
              conferenceCode:this.conferenceCode,
              isOpenPc:this.PCSetupForm.isOpenPc,
              pcBanner:this.PCSetupForm.pcBanner,
            }
            const response = await putSwitch(data)
            if(response.code === 0){
              await this.getList();
              this.PCSetup_visible = false;
            }
          }
        } else {
          return false;
        }
      });
    },
    PCSetupIsOpen(isOpen){
      let data = {
        conferenceCode:this.conferenceCode,
        isOpenPc:isOpen,
      }
      this.editApi(data);
    },
    async putLinkFunc(data) {
      const response = await setPcImg(data)
      if(response.code === 200){
        this.$message.success("操作成功！");
        await this.cancelPop();
        await this.getLinkFunc();
      }else {
        this.$message.warning(response.message);
      }
    },
    editLive(row){
      this.listId = row.id
      this.list_Form = {
        title:row.title,
        address:row.address,
        beginDate:row.beginDate,
        endDate:row.endDate,
        mainColor:row.mainColor?row.mainColor:'#45A5E6',
        coverPics:row.coverPics,
        homeCoverImage:row.homeCoverImage,
        // sortTime:row.sortTime,
        relayImg:row.relayImg,
        columId:row.columId,
        conferenceCode:row.conferenceCode,
        domainCode:row.domainCode,
      }
      this.list_visible = true
    },
    liveLink(link){
      this.appSrc = link;
      this.linkVisible = true;
    },
    liveLinkH5(link){
      window.open(link);
    },
    mouseoverBtn(val){
      if(val === 'title'){
        this.editBtnShow = true;
      }else if(val === 'columTitle'){
        this.editBtnShowColumTitle = true;
      }else if(val === 'beginDate'){
        this.editBtnShowBeginDate = true;
      }else if(val === 'address'){
        this.editBtnShowAddress = true;
      }

    },
    mouseleaveBtn(val){
      if(val === 'title') {
        this.editBtnShow = false;
      }else if(val === 'columTitle'){
        this.editBtnShowColumTitle = false;
      }else if(val === 'beginDate'){
        this.editBtnShowBeginDate = false;
      }else if(val === 'address'){
        this.editBtnShowAddress = false;
      }
    },
    editBtn(val){
      if(val === 'title') {
        this.editForm.title = this.liveContent.title;
        this.isShowEditTitle = true;
        this.$nextTick(()=>{
          this.$refs.titleRef.focus();
        })
      }else if(val === 'columTitle'){ //会议分类
        // this.editForm.columId = this.liveContent.columId;
        // this.isShowEditColumTitle = true;
        this.classify_visible = true;
        this.editForm.columId = this.liveContent.columId;
      }else if(val === 'beginDate'){
        this.editForm.beginDate = this.liveContent.beginDate;
        this.editForm.endDate = this.liveContent.endDate;
        this.date_visible = true;
      }else if(val === 'address'){
        this.editForm.address = this.liveContent.address;
        this.isShowEditAddress = true;
        this.$nextTick(()=>{
          this.$refs.addressRef.focus();
        })
      }
    },
    pressblurBtn(val){
      this.isTitleBlur ++;
        let data;
        if(val === 'title') {
          this.isShowEditTitle = false;
          data = {
            "conferenceCode": this.conferenceCode,//会议编码
            "title": this.editForm.title,
          }
        }
        else if(val === 'address'){
          this.isShowEditAddress = false;
          data = {
            "conferenceCode": this.conferenceCode,//会议编码
            "address": this.editForm.address,//会议日期-开始
          }
        }
       if(this.isTitleBlur === 2) {
         this.editApi(data);
       }
    },
    pressEnterBtn(val){
      this.isTitleBlur ++;
      let data;
      if(val === 'title') {
        this.isShowEditTitle = false;
        data = {
          "conferenceCode": this.conferenceCode,//会议编码
          "title": this.editForm.title,
        }
        if(this.isTitleBlur === 2) {
          this.editApi(data);
        }
      }else if(val === 'beginDate'){
        this.isShowEditBeginDate = false;
        data = {
          "conferenceCode": this.conferenceCode,//会议编码
          "beginDate": this.editForm.beginDate,//会议日期-开始
          "endDate": this.editForm.endDate,//会议日期-结束
        }
        this.editApi(data);
      }else if(val === 'address'){
        this.isShowEditAddress = false;
        data = {
          "conferenceCode": this.conferenceCode,//会议编码
          "address": this.editForm.address,//会议日期-开始
        }
        this.editApi(data);
      }
    },
    columTitleChange(val){
      this.editForm.columId = val;
      console.log(this.editForm.columId)
    },
    columTitleBlur(){
      console.log('鼠标移出时回调')
    },
    // 修改接口
    async editApi(data,type) {
        const response = await putSwitch(data)
        if (response.code === 0) {
          this.isTitleBlur = 1;
          this.$message.success("修改成功！")
          //  刷新
          await this.getList();
          if(type === 'pic'){
            //调用父级类方法，刷新banner图片
            this.$parent.$parent.getLists();
          }
        } else {
          this.$message.warning(response.message)
        }

    },
    onChange(date, dateString) {
      this.editForm.beginDate = dateString[0];
      this.editForm.endDate = dateString[1];
      this.selectPriceDate = "";
    },
    openBackdrop(row){
      this.backdrop_Form = {
        mainColor:row.mainColor,
        homeCoverImage:row.homeCoverImage,
      }
      this.backdrop_visible = true;
    },
    OkBackdrop(){
      let data = {
        "conferenceCode": this.conferenceCode,//会议编码
        "mainColor": this.backdrop_Form.mainColor,
        "homeCoverImage": this.backdrop_Form.homeCoverImage,
      }
      this.editApi(data,'pic');

      this.cancelBackdrop();
    },
    cancelBackdrop(){
      this.backdrop_visible = false;
      this.backdrop_Form = {
        mainColor:'#45A5E6',
        homeCoverImage:''
      }
    },
    isPublishedChange(isOpen){
      if(isOpen === 1){
        this.issueLive();
      }else {
        let data = {
          "conferenceCode": this.conferenceCode,//会议编码
          "isPublished": isOpen,
        }
        this.editApi(data);
      }
    },
    cancelTransmit(){
      this.Transmit_visible = false;
      this.Transmit_Form = {
        relayImg:'',
      };
    },
    OkTransmit(){
      // if(this.Transmit_Form.relayImg){
        let data = {
          "conferenceCode": this.conferenceCode,//会议编码
          "relayImg": this.Transmit_Form.relayImg ? this.Transmit_Form.relayImg : '',
        }
        this.editApi(data);
        this.cancelTransmit();
      // }else {
      //   this.$message.warning("请先上传图片！")
      // }
    },
    openTransmit(row){
      this.Transmit_Form.relayImg = row.relayImg;
      this.Transmit_visible = true;
    },
    closeMainDataReport(val) {
      this.visibleDataReport = val;
    },
    openTemplate(){
      this.Template_visible = true;
    },

    openIconTemplate(){
      this.systemIconsVisible = true;
    },

    closeMainTemplate(){
      this.Template_visible = false;
    },
    selectTemplate(val){
      let url = 'https://meet.yichimeeting.com'+val+'?conferenceCode='+this.meetCode;
      let data = {
        "conferenceCode": this.conferenceCode,//会议编码
        "tempUrl": url,
      }
      this.editApi(data,'pic');
    },
    cancelClassify(){
      this.classify_visible = false;
    },
    OkClassify(){
      let data = {
        "conferenceCode": this.conferenceCode,//会议编码
        "columId": this.editForm.columId,
      }
      this.editApi(data);
      this.cancelClassify();
    },
    cancelDate(){
      this.date_visible = false;
    },
    OkDate(){
      let data = {
        "conferenceCode": this.conferenceCode,//会议编码
        "beginDate": this.editForm.beginDate,//会议日期-开始
        "endDate": this.editForm.endDate,//会议日期-结束
      }
      this.editApi(data,'pic');
      this.cancelDate();
    },
    conversionTimeFormat(time) {
      return moment(time).format('YYYY年MM月DD日'); // 获取当前时间的秒级时间戳
    },
    //根据选择的开始时间/结束时间，动态渲染要禁用的日期
    disabledDate(current) {
      if (this.selectPriceDate) {
        let selectV = moment(this.selectPriceDate, "YYYY-MM-DD").valueOf();
        return (
            current > moment(new Date(selectV + this.offsetDays), "YYYY-MM-DD") ||
            current < moment(new Date(selectV - this.offsetDays), "YYYY-MM-DD")
        );
      } else {
        return false;
      }
    },
    // 选择会议日期
    DateChange(date, dateString) {
      this.selectPriceDate = date[0];
    },
    closeMainIconTemplate(val){
      this.IconTemplateVisible = val;
    },
    async checkListIconTemplate(val) {

    },
  }
}
</script>

<style scoped lang="scss">
  .ConventionCase{
    width: 98%;
    margin-left: 5px;
    min-height: 190px;
    margin: auto;
    display: flex;
    img{
      width: 320px;
      margin: 15px 30px 10px 20px;
    }
    .topRight{
      margin-top: 10px;
      .topTitle{
        display: flex;
      }
      .title{
        font-weight: bold;margin-right: 10px;margin-bottom: 20px;
      }
      .tag{
        height: 23px;
        margin-top: 4px;
        margin-right: 10px;
      }
      .content{
        //width: 50%;
        .font{
          //margin-top: 10px;
          height: 35px;
        }
      }
    }
    .topBtn{
      width: 200px;
      margin-top:20px;
      text-align: right;
    }
  }
  .linkModal{
    display: flex;
    height: 50px;
    line-height: 50px;
    .title{
      width: 120px;
      text-align: right;
      margin-right: 10px;

    }

  }
  .codeLink{
    display: flex;
    margin-top: 20px;
    .title{
      width: 120px;
      text-align: right;
      margin-right: 10px;
      margin-top: 5px;
    }
    .iconStys{
      color: #45a5e6;
      font-size: 20px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .upImgModel{
    width: 288px;
    min-height: 162px;
    text-align: center;
    background-color: #fcfcfc;
    border: #DDDDDD 2px dashed;
    border-radius: 10px;
    .content{
      font-size: 20px;
      text-align: center;
      width: 100%;
      margin-top: 50px;
      color: #929292;
      .addModel{
        font-size: 30px;
      }
    }
  }.upImgModel_relayImg{
    width: 288px;
    height: 288px;
    text-align: center;
    background-color: #fcfcfc;
    border: #DDDDDD 2px dashed;
    border-radius: 10px;
    .content_relayImg{
      font-size: 20px;
      text-align: center;
      width: 100%;
      margin-top: 100px;
      color: #929292;
      .addModel_relayImg{
        font-size: 30px;
      }
    }
  }
  .upImgModelBanner{
    width: 380px;
    height: 110px;
    text-align: center;
    background-color: #fcfcfc;
    border: #DDDDDD 2px dashed;
    border-radius: 10px;
    .content{
      font-size: 16px;
      text-align: center;
      width: 100%;
      margin-top: 10px;
      color: #929292;
      .addModel{
        font-size: 25px;
      }
    }
  }
  .hoverBgcIconBanner{
    width: 380px;
    height: 100%;
    position: absolute;
    background-color: rgba(0,0,0,.4);
    top:0;
    border-radius: 10px;
    opacity: 0; //透明度为0 不显示遮罩层
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 25px;
    .iconDel{
      font-size: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .hoverBgcIconBanner:hover{
    opacity: 1;
  }
  .hoverBgc_relayImg{
    width: 288px;
    height: 100%;
    position: absolute;
    background-color: rgba(0,0,0,.4);
    top:0;
    border-radius: 10px;
    opacity: 0; //透明度为0 不显示遮罩层
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 25px;
    .iconDel_relayImg{
      font-size: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .hoverBgc_relayImg:hover{
    opacity: 1;
  }
  .upImgModelIcon{
    width: 80px;
    height: 80px;
    text-align: center;
    background-color: #fcfcfc;
    border: #DDDDDD 2px dashed;
    border-radius: 5px;
    .content{
      font-size: 12px;
      text-align: center;
      width: 100%;
      padding-top: 30px;
      color: #929292;
      .addModel{
        font-size: 12px;
      }
    }
    .contentImg{
      width: 75px;
      border-radius: 5px;
      margin-top: 1%;
      margin-bottom: 1%;
      margin-left:1%;
    }
  }
  .hoverBgc{
    width: 288px;
    height: 100%;
    position: absolute;
    background-color: rgba(0,0,0,.4);
    top:0;
    //z-index: 999;
    border-radius: 10px;
    opacity: 0; //透明度为0 不显示遮罩层
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 25px;
    .iconDel{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .hoverBgc:hover{
    opacity: 1;
  }
  .hoverBgcIcon{
    width: 80px;
    height: 100%;
    position: absolute;
    background-color: rgba(0,0,0,.4);
    top:0;
    //z-index: 999;
    border-radius: 10px;
    opacity: 0; //透明度为0 不显示遮罩层
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 25px;
    .iconDel{
      font-size: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .hoverBgcIcon:hover{
    opacity: 1;
  }
  .iconStY{
    color:#45a5e6;
    font-size: 20px;
    margin-left: 5px;
    margin-top:5px;
  }
  .popCard{
    width: 50px;
    height: 50px;
  }
  .popCard:hover{
    background-color: rgba(0,0,0,.3);
    border:1px solid rgba(0,0,0,.3);
  }
  .iconDelDiv{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .iconDel-cont{
      width: 100%;
      padding: 5%;
      font-size: 11px;
      color: #F5F5F5;
      display: flex;
      justify-content: space-between;
    }
    .customUpload{
      cursor: pointer;
    }
  }
</style>
