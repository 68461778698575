<template>
  <div style="height: auto;border-left:1px solid #F5F5F5">
    <a-spin :spinning="spinning">
      <div class="content-div">
        <div class="left-phone">
<!--          顶部banner-->
          <div class="left-banner">
            <div @mouseover="mouseoverBtn('liveCoverPics')" @mouseleave="mouseleaveBtn('liveCoverPics')">
              <img style="width: 100%;height: 200px" :src="liveCoverPics" alt="">
              <div class="reminder">
                <div v-if="liveCoverPicsShow"  @click="openModel('reminderPic','','radio')" class="reminder-edit"><a-icon type="edit" /></div>
              </div>
            </div>
            <div class="isOpenCountdown" :style="{display: 'flex',justifyContent:isOpenCountdown(beginDate,endDate) ? 'space-between':'right',}">
<!--              倒计时-->
              <div v-if="isOpenCountdown(beginDate,endDate)" style="padding-left: 5px;">
                {{bannerCountDown.day}}天{{bannerCountDown.hour}}小时{{bannerCountDown.min}}分钟{{bannerCountDown.second}}秒
              </div>
<!--              时长开关-->
              <div style="display: flex">
                <div style="margin-right: 5px">倒计时 <a-tooltip placement="topRight">
                  <template slot="title">
                    <span>会议结束或关闭时，倒计时会自动关闭</span>
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip> :</div>
                <a-switch
                    :style="{marginRight:'10px',backgroundColor: isOpenCountdown(beginDate,endDate) === 1 ? '#45a5e6':'rgba(255,255,255,.5)'}"
                    :checked="isOpenCountdown(beginDate,endDate) === 1 ? true : false"
                    checked-children="开启"
                    un-checked-children="关闭"
                    @change="switchCountDownBtn(isOpenCountdown(beginDate,endDate) === 0 ? 1 : 0,beginDate)"
                    default-checked
                />
              </div>

            </div>
          </div>
<!--          <div :style="{backgroundImage:'url('+homeCoverImage+')',height:'625px',backgroundRepeat:'no-repeat',backgroundSize:'100%'}">-->
          <div style="position: relative;">
              <img v-if="homeCoverImage" class="left-content-img" :src="homeCoverImage" alt="">
            <!--
            /conference ：九宫格
            /conferenceWhitebg：带背景色的九宫格

            /conferenceEntrance：方块格子
            /conferenceEntranceWhitebg：带背景色的方块格

            /conferenceAround：带背景色一行两个的长方形
                     -->
            <!--            九宫格-->
              <div v-if="extractStringBetween(tempUrl) === 'conference'">
                <div class="left-content-list" :style="{height:585-(organizerIsOpen ? 21+21*(organizerList.length ? organizerList.length : 1):42)+'px'}" v-if="!isShowIconTitle">
                  <a-row type="flex">
                    <a-col
                        :span="8"
                        v-for="(list, index) in dataList"
                        v-if="list.isPublished"
                        :key="index"
                        style="display: flex;justify-content: center;margin-top: 30px"
                        @click="contentBtn(list)"
                    >
                      <img style="width: 80px;height: 80px" :src="list.icon" alt="">
                    </a-col>
                  </a-row>
                </div>
                <div class="left-content-list" :style="{height:585-(organizerIsOpen ? 21+21*(organizerList.length ? organizerList.length : 1):42)+'px'}" v-else>
                  <a-row type="flex">
                    <a-col
                        :span="8"
                        v-for="(list, index) in dataList"
                        v-if="list.isPublished"
                        :key="index"
                        @click="contentBtn(list)"
                        style="display: flex;justify-content: center;margin-top: 30px"
                    >
                      <div>
                        <img style="width: 70px;height: 70px" :src="list.icon" alt="">
                        <div :style="{textAlign: 'center',marginTop: '10px',fontWeight: 'bold',color:list.iconTitleColor?list.iconTitleColor:'#333333'}">{{ list.title }}</div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </div>

            <!--            带背景色的九宫格-->
              <div v-if="extractStringBetween(tempUrl) === 'conferenceWhitebg'" style="padding: 5px">
                <div class="left-content-list" :style="{height:585-(organizerIsOpen ? 21+21*(organizerList.length ? organizerList.length : 1):42)+'px'}" v-if="!isShowIconTitle">
                  <a-row type="flex">
                    <a-col
                        :span="8"
                        v-for="(list, index) in dataList"
                        v-if="list.isPublished"
                        :key="index"
                        style="display: flex;justify-content: center;"
                        @click="contentBtn(list)"
                    >
                      <div :style="{width: '100%',backgroundColor:hexToRgb(list.iconBgColor ? list.iconBgColor:'#ffffff',list.transparency),display: 'flex',justifyContent: 'center',padding: '10px',margin: '3px'}">
                        <img style="width: 80px;height: 80px;margin:10px 0 10px 0;" :src="list.icon" alt="">
                      </div>

                    </a-col>
                  </a-row>
                </div>
                <div class="left-content-list" :style="{height:585-(organizerIsOpen ? 21+21*(organizerList.length ? organizerList.length : 1):42)+'px'}" v-else>
                  <a-row type="flex">
                    <a-col
                        :span="8"
                        v-for="(list, index) in dataList"
                        v-if="list.isPublished"
                        :key="index"
                        @click="contentBtn(list)"
                        style="display: flex;justify-content: center;"
                    >
                      <div :style="{width: '100%',backgroundColor:hexToRgb(list.iconBgColor ? list.iconBgColor:'#ffffff',list.transparency),display: 'flex',justifyContent: 'center',padding: '10px',margin: '3px'}">
                        <div>
                          <img style="width: 70px;height: 70px;margin-top: 6px" :src="list.icon" alt="">
                          <div :style="{textAlign: 'center',fontWeight: 'bold',color:list.iconTitleColor?list.iconTitleColor:'#333333'}">{{ list.title }}</div>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </div>

            <!--            方块格-->
            <div v-if="extractStringBetween(tempUrl) === 'conferenceEntrance'">
              <div class="entrance-list" :style="{height:585-(organizerIsOpen ? 21+21*(organizerList.length ? organizerList.length : 1):42)+'px'}">
                <div style="background-color: #f4742f;position: relative;width: 100%;">
<!--                小屏幕样式-->
                  <div style="width: 340px;position: absolute;left: 50%; transform: translateX(-50%);margin-top:10px " v-if="innerWidths > 0 && innerWidths < 1920">
                    <div
                        v-for="(item,i) in itemArrayX"
                        :key="i"
                        class="b_item">
                      <img
                          :style="{borderRadius:'5px',height:item.height,width: item.width,marginBottom:'-4px'}"
                          :src="item.icon" alt=""/>
                    </div>
                  </div>
<!--                大屏幕样式-->
                  <div style="width: 380px;position: absolute;left: 50%; transform: translateX(-50%);margin-top: 5px " v-else>
                    <div
                        v-for="(item,i) in itemArrayD"
                        :key="i"
                        class="b_item">
                      <img
                          :style="{borderRadius:'5px',height:item.height,width: item.width}"
                          :src="item.icon" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--            带背景色的方块格-->
            <div v-if="extractStringBetween(tempUrl) === 'conferenceEntranceWhitebg'">
              <div class="entrance-list" :style="{height:585-(organizerIsOpen ? 21+21*(organizerList.length ? organizerList.length : 1):42)+'px'}">
                <div style="background-color: #f4742f;position: relative;width: 100%;">
<!--                小屏幕样式-->
                  <div style="width: 340px;position: absolute;left: 50%; transform: translateX(-50%);margin-top:10px " v-if="innerWidths > 0 && innerWidths < 1920">
                    <div v-if="isShowIconTitle">
                      <div
                          v-for="(item,i) in itemArrayX"
                          :key="i"
                          class="b_item">
                        <div :style="{height:item.height,width: item.width,backgroundColor:hexToRgb(item.iconBgColor ? item.iconBgColor:'#ffffff',item.transparency)}">
                          <img
                              :style="{height:'45px',width: '45px',marginTop:(item.marginTop-10)+'px',marginLeft:item.marginLeft+'px'}"
                              :src="item.icon" alt=""/>
                          <div :style="{textAlign: 'center',fontSize: '12px',color:iconTitleColor?iconTitleColor:'#333333'} ">
                            {{item.title}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                          v-for="(item,i) in itemArrayX"
                          :key="i"
                          class="b_item">
                        <div :style="{height:item.height,width: item.width,backgroundColor:hexToRgb(item.iconBgColor ? item.iconBgColor:'#ffffff',item.transparency),}">
                          <img
                              :style="{height:'45px',width: '45px',marginTop:item.marginTop+'px',marginLeft:item.marginLeft+'px'}"
                              :src="item.icon" alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
<!--                大屏幕样式-->
                  <div style="width: 380px;position: absolute;left: 50%; transform: translateX(-50%);margin-top: 5px " v-else>
                    <div v-if="isShowIconTitle">
                      <div
                          v-for="(item,i) in itemArrayD"
                          :key="i"
                          class="b_item">
                        <div :style="{height:item.height,width: item.width,backgroundColor:hexToRgb(item.iconBgColor ? item.iconBgColor:'#ffffff',item.transparency),}">
                          <img
                              :style="{height:'45px',width: '45px',marginTop:(item.marginTop-10)+'px',marginLeft:item.marginLeft+'px'}"
                              :src="item.icon" alt=""/>
                          <div :style="{textAlign: 'center',fontSize: '12px',color:iconTitleColor?iconTitleColor:'#333333'} ">
                            {{item.title}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                          v-for="(item,i) in itemArrayD"
                          :key="i"
                          class="b_item">
                        <div :style="{height:item.height,width: item.width,backgroundColor:hexToRgb(item.iconBgColor ? item.iconBgColor:'#ffffff',item.transparency),}">
                          <img
                              :style="{height:'45px',width: '45px',marginTop:item.marginTop+'px',marginLeft:item.marginLeft+'px'}"
                              :src="item.icon" alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--            带背景色一行两个的长方形-->
            <div v-if="extractStringBetween(tempUrl) === 'conferenceAround'">
              <div class="left-content-list" :style="{height:585-(organizerIsOpen ? 21+21*(organizerList.length ? organizerList.length : 1):42)+'px'}" v-if="isShowIconTitle" style="padding: 13px">
                <a-row type="flex">
                  <a-col
                      :span="12"
                      v-for="(list, index) in dataList"
                      v-if="list.isPublished"
                      :key="index"
                      style="display: flex;justify-content: center;"
                      @click="contentBtn(list)"
                  >
                    <div class="rectangle" :style="{backgroundColor:hexToRgb(list.iconBgColor ? list.iconBgColor:'#ffffff',list.transparency)}">
                      <div style="display: flex;margin:10px;padding-left: 10px">
                        <img style="width: 45px;height: 45px" :src="list.icon" alt="">
                        <div :style="{color:list.iconTitleColor?list.iconTitleColor:'#333333'}" class="fontSty">{{ list.title }}</div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div class="left-content-list" :style="{height:585-(organizerIsOpen ? 21+21*(organizerList.length ? organizerList.length : 1):42)+'px'}" v-else>
                <a-row type="flex" style="padding: 13px">
                  <a-col
                      :span="12"
                      v-for="(list, index) in dataList"
                      v-if="list.isPublished"
                      :key="index"
                      @click="contentBtn(list)"
                      style="display: flex;justify-content: center;"
                  >
                    <div  class="rectangle" :style="{backgroundColor:hexToRgb(list.iconBgColor ? list.iconBgColor:'#ffffff',list.transparency),justifyContent: 'center'}">
                      <img style="width: 45px;height: 45px;margin:10px" :src="list.icon" alt="">
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>

            </div>

<!--          底部技术支持-->
          <div class="technicalSupport" ref="jszcRefs" style="position: absolute;bottom: 0;">
            <div v-if="organizerList.length === 0 || !organizerIsOpen">
              <div class="technicalSupport-top">
                <div @click="openServiceModel('zbdw')" style="cursor: pointer;margin-right: 3px;">主办单位设置</div>
                <div @click="openServiceModel('zbdw')" style="cursor: pointer"><a-icon style="color: #45A5E6" type="edit" /></div>
              </div>
            </div>
            <div v-if="organizerList.length > 0 && organizerIsOpen">
              <div class="technicalSupport-top" v-for="(item,index) in organizerList" :key="index">
                <div style="width: 25px"><img v-if="item.icon" style="width: 18px;height: 18px;border-radius: 18px;margin-right: 8px" :src="item.icon"/></div>
                <div @click="openServiceModel('zbdw')" style="cursor: pointer;">{{item.name}}</div>
                <div  @click="openServiceModel('zbdw')" style="cursor: pointer;">
                  <div style="width: 20px;text-align: right"><a-icon v-if="index === organizerList.length - 1" style="color: #45A5E6" type="edit" /></div>
                </div>
              </div>
            </div>
            <div class="technicalSupport-bottom">
              <div style="text-shadow: #000 1px 1px 3px;">技术支持：医驰会务平台</div>
              <div @click="openServiceModel('jszc')" class="delectIcon">去除平台名称</div>
            </div>
          </div>
        </div>
        <div class="right-table">
          <div style="min-width: 0;">
            <div class="topCard">
              <ConventionCase
                  ref="ConventionCase"
                  v-if="meetCode"
                  :conferenceCode="meetCode"
                  :isShowBtn="true"/>
            </div>
            <div class="btn">
              <div style="margin-top: 10px"><a-button @click="addCard" type="primary">+ 新增功能</a-button></div>
              <div style="display: flex;margin-top: 10px">

                <a-button @click="tableSortBtn">排序设置</a-button>
                <a-button style="margin-left: 10px;margin-right: 10px;" @click="oneKeyBtn">一键设置</a-button>

<!--                <div>显示功能名称开启 <a-tooltip placement="topRight">-->
<!--                  <template slot="title">-->
<!--                    <span>按钮开启时，用户端功能名称将会显示</span>-->
<!--                  </template>-->
<!--                  <a-icon type="info-circle" />-->
<!--                </a-tooltip>：</div>-->
<!--                <a-switch-->
<!--                    style="margin-right:10px"-->
<!--                    :checked="isShowIconTitle === 1 ? true : false"-->
<!--                    checked-children="开启"-->
<!--                    un-checked-children="关闭"-->
<!--                    @change="switchEditBtn(isShowIconTitle === 0 ? 1 : 0)"-->
<!--                    default-checked-->
<!--                />-->
<!--                <div v-if="isShowIconTitle">设置功能名称字体颜色：</div>-->
<!--                <a-popover placement="bottom" v-if="isShowIconTitle">-->
<!--                  <template slot="content">-->
<!--                    <sketch-picker v-model="iconTitleColor" @input="colorRest"/>-->
<!--                  </template>-->
<!--                  <a-button :style="{marginRight: '10px',marginTop: '-1px',backgroundColor:iconTitleColor,width:'50px',height:'25px'}"></a-button>-->
<!--                </a-popover>-->
              </div>
            </div>

            <div class="table">
              <a-table
                  :scroll="{ x: 1300 }"
                  :pagination="false"
                  :columns="columns"
                  :data-source="dataList">
                <span slot="isOpenTopTitleBar" slot-scope="text,record">
                  <span style="cursor: pointer" @click="openSetTitleBar(record)" v-if="record.funcCode === 'HYC' ||record.funcCode === 'TEXT' ||record.funcCode === 'ZCBM'" :style="{color:record.isOpenTopTitleBar ? '#333333':'#c6c6c6'}">{{record.isOpenTopTitleBar ? '已开启':'已关闭'}}</span>
                  <span v-else>-</span>
                </span>
                <span slot="titleBar">
                  标题栏<a-tooltip>
                  <template slot="title">
                    仅图片类型、文本编辑类型和自定义表单可【关闭标题栏】
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
                </span>
              <span slot="content" slot-scope="text,record">
                <span>
                  <span v-if="record.funcCode === 'GRZL'">-</span>
                  <span v-else>
                    <span v-if="record.funcCode === 'ZCBM' ||record.funcCode=='ZCJF' ">
                      <a @click="contentBtn(record)">设置内容</a>
                      <a @click="setRemarks(record)" style="margin-left: 10px">设置备注</a>
                    </span>
                    <a v-else @click="contentBtn(record)">设置内容</a>
                  </span>

                </span>
              </span>
                <span slot="icon" slot-scope="text,record">
                  <div style="width:70px;height: 70px;background-color: #333333;position: relative">
                    <img v-if="text" :src="text" style="width: 100%;height: 100%;">
                    <img v-if="!text" src="../../../assets/meet/noImg.png" style="width: 100%;height: 100%;">


                    <div class="iconLayer">
                      <div style="cursor: pointer"  @click="openModel('列表功能图标','图片库','radio',record.id)">自定义上传</div>
                      <div style="margin-top: 10px;cursor: pointer" @click="openIconTemplate(record)">选择系统图标</div>
                    </div>
                  </div>

                </span>
                <span slot="funcTitle" slot-scope="text,record">
                <span @click="restBtn(record)" v-if="!text" style="color: red">未设置</span>
                <a v-else @click="restBtn(record)">{{text}}</a>
              </span>
                <span slot="titles" slot-scope="text,record">
                <a @click="restBtn(record)">{{text}}</a>
              </span>
                <span slot="isPublished" slot-scope="text,record">
                <a-switch
                    v-model="record.isPublished === 1 ? true : false"
                    checked-children="开"
                    un-checked-children="关"
                    @change="functionModuleSwitch(record,record.isPublished === 1 ? 0 : 1)"
                    default-checked/>
              </span>
                <span slot="isNeedLogin" slot-scope="text,record">
                    <a-switch
                        v-model="record.isNeedLogin === 1 ? true : false"
                        :disabled="record.funcCode === 'GRZL' || record.funcCode === 'ZCJF'"
                        checked-children="开"
                        un-checked-children="关"
                        @change="set_login_Btn(record.id,record.isNeedLogin === 1 ? 0 : 1)"
                        default-checked/>
              </span>
                <!--              <span slot="isShowApplyBar" slot-scope="text,record">-->
                <!--                <a-switch-->
                <!--                    v-model="record.isShowApplyBar === 1 ? true : false"-->
                <!--                    checked-children="开"-->
                <!--                    un-checked-children="关"-->
                <!--                    @change="set_Suspended_Btn(record.id,record.isShowApplyBar === 1 ? 0 : 1)"-->
                <!--                    default-checked/>-->
                <!--              </span>-->
                <!--              <span slot="isNeedPay" slot-scope="text,record">-->
                <!--                <a-switch-->
                <!--                    v-model="record.isNeedPay === 1 ? true : false"-->
                <!--                    checked-children="开"-->
                <!--                    un-checked-children="关"-->
                <!--                    @change="set_payFees_Btn(record.id,record.isNeedPay === 1 ? 0 : 1)"-->
                <!--                    default-checked/>-->
                <!--              </span>-->
                <span slot="action" slot-scope="text,record">
                <a v-if="record.funcCode === 'HYBM'" style="margin-right: 8px" @click="listBtn(record)">名单</a>
                <a v-if="record.funcCode === 'ZCBM' || record.funcCode === 'ZCJF'" style="margin-right: 8px" @click="ZCBMPanelBtn(record)">收集结果</a>
                  <a style="margin-right: 8px" @click="restBtn(record)">修改</a>
                  <!--                <a style="margin-right: 8px" @click="moveUp(record.id)">上移</a>-->
<!--                <a style="margin-right: 8px" @click="shiftDown(record.id)">下移</a>-->
                <a-popconfirm
                    style="margin-right: 8px"
                    title="是否确认删除?"
                    @confirm="delCard(record)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </span>
              </a-table>
            </div>
          </div>

        </div>
      </div>

      <!-- 底部   -->
<!--      <div style="height: 50px"></div>-->
<!--      <div class="bottomSty">-->
<!--        <div class="btn">-->
<!--          <a-button @click="returnBtn">返回上一层</a-button>-->
<!--        </div>-->
<!--      </div>-->
      <!------------------------------------------对话框-------------------------->
      <!-- 选择图片-->
      <a-modal
        title="图片库"
        :width="1300"
        :visible="visible"
        @ok="handleOk"
        @cancel="handleCancel"
      >
          <div class="modalDiv">
            <div class="left">
              <a-menu
                  :default-open-keys="['sub1']"
                  class="menu"
                  v-model="selectId"
                  mode="inline">
                <a-menu-item
                  v-for="(item, index) in templateList"
                  @click="selectStatus(item.title,item)"
                  :key="item.id"
                >
                  {{ item.title }}
                </a-menu-item>

                <a-sub-menu key="sub1" title="城市图标">
                  <a-menu-item
                      v-for="(list, index) in cityList"
                      @click="selectCity(list)"
                      :key="list"
                  >
                    {{ list }}
                  </a-menu-item>
                </a-sub-menu>

              </a-menu>
            </div>

            <div class="content">
              <a-spin :spinning="spin">
                <div class="content-top">
                  <div class="top-icon">
                    <!--   v-show="!(pageNo === 1 && iconTemplate === 0)"    -->
                    <a-icon
                        v-show="!(pageNo === 1 && iconTemplate === 0)"
                        class="icon"
                        @click="leftBtn"
                        type="left"
                    />
                  </div>
                  <div class="imgList">
                    <div v-for="(img, index) in iconDataList" :key="img.id">
                      <img
                          @click="getIconList(img.id, index, img.fileUrl)"
                          :style="{
                        border:
                          iconTemplate === index ? '4px solid #45a5e6' : '',
                        cursor: 'pointer',
                      }"
                          class="imgD"
                          :src="img.previewImg"
                      />
                    </div>
                  </div>
                  <div class="top-icon">
                    <!--   v-show="!(((pageNo-1) * pageSize + iconTemplate +1) === iconDataCount)"             -->
                    <a-icon
                        v-show="
                      !(
                        (pageNo - 1) * pageSize + iconTemplate + 1 ===
                        iconDataCount
                      )
                    "
                        class="icon"
                        @click="rightBtn"
                        type="right"
                    />
                  </div>
                </div>
                <div class="content-list">
                  <div class="content-list-top">
                    <a-button @click="openDown" style="margin-left: 20px">
                      <a-icon type="cloud-download" /> 下载源文件</a-button
                    >
                    <a-input-search
                        v-model="iconName"
                        style="width: 300px"
                        placeholder="请输入功能名称"
                        enter-button="搜索"
                        @search="onSearch"
                    />
                  </div>
                  <div class="content-list-card">
                    <div
                        @click="selectCard(index, img.url)"
                        class="card"
                        :style="{
                      border: selectIndex === index ? '2px solid #45a5e6' : '',
                      cursor: 'pointer',backgroundColor:'rgba(0,0,0,.85)'
                    }"
                        v-for="(img, index) in funList"
                        :key="img.id"
                    >
                      <div
                          style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                      >
                        <img class="imgD" :src="img.url" />
                      </div>
                      <div class="font" style="color: #F7F9FA">{{ img.name }}</div>
                    </div>
                  </div>
                </div>
            </a-spin>
            </div>

<!--            <div v-show="selectId[0] === '2'" class="content">-->
<!--              <div class="content-two-list">-->
<!--                <div class="content-two-list-top">-->
<!--                  <div style="position: relative; width: 140px; height: 35px">-->
<!--                    <a-button style="margin-left: 1%">-->
<!--                      <a-icon type="vertical-align-top" />-->
<!--                      批量上传图片-->
<!--                    </a-button>-->
<!--                    <input-->
<!--                      multiple-->
<!--                      ref="batchImg"-->
<!--                      style="-->
<!--                        position: absolute;-->
<!--                        top: 0;-->
<!--                        opacity: 0;-->
<!--                        width: 140px;-->
<!--                        height: 35px;-->
<!--                        left: 0;-->
<!--                      "-->
<!--                      accept="image/*"-->
<!--                      type="file"-->
<!--                      @change="batchImgBtn('batchImg')"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <a-input-search-->
<!--                    v-model="myFunName"-->
<!--                    style="width: 300px"-->
<!--                    placeholder="请输入功能名称"-->
<!--                    enter-button="搜索"-->
<!--                    @search="MySearch"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="content-two-list-card">-->
<!--                  <div class="addCard">-->
<!--                    <div class="up">-->
<!--                      <div style="font-size: 40px; color: #bdbcbc">+</div>-->
<!--                      <div style="font-size: 16px; color: #929292">上传</div>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <input-->
<!--                        id="surfacePlot"-->
<!--                        ref="surfacePlot"-->
<!--                        class="inputImg"-->
<!--                        accept="image/*"-->
<!--                        type="file"-->
<!--                        @change="surfacePlotBtn"-->
<!--                      />-->
<!--                    </div>-->

<!--                    <div class="imgSty">-->
<!--                      <img-->
<!--                        v-if="funUp.funImg"-->
<!--                        class="img"-->
<!--                        :src="funUp.funImg"-->
<!--                        alt=""-->
<!--                      />-->
<!--                    </div>-->
<!--                    <div v-if="funUp.funImg" class="upSave" @click="saveBtn">-->
<!--                      保存-->
<!--                    </div>-->

<!--                    <div class="upInput">-->
<!--                      <a-input-->
<!--                        style="margin-top: 60px"-->
<!--                        v-model="funUp.funName"-->
<!--                        placeholder="请输入功能名称"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div>-->
<!--                    <div-->
<!--                      class="card"-->
<!--                      v-for="(img, index) in myFunList"-->
<!--                      @click="getSelectMyIcon(index, img.url)"-->
<!--                      :style="{-->
<!--                        border:-->
<!--                          selectMyIndex === index ? '2px solid #45a5e6' : '',-->
<!--                        cursor: 'pointer',-->
<!--                      }"-->
<!--                      :key="img.id"-->
<!--                    >-->
<!--                      <div class="imgTitle">-->
<!--                        <div>-->
<!--                          <div-->
<!--                            style="-->
<!--                              display: flex;-->
<!--                              justify-content: center;-->
<!--                              width: 100%;-->
<!--                            "-->
<!--                          >-->
<!--                            <img class="imgD" :src="img.url" />-->
<!--                          </div>-->
<!--                          <div style="text-align: center; margin-top: 10px">-->
<!--                            <a-input-->
<!--                              id="Ainput"-->
<!--                              @blur="blurEnter(img)"-->
<!--                              @pressEnter="pressEnter(img)"-->
<!--                              style="width: 93%; margin: auto"-->
<!--                              v-if="img.editKey"-->
<!--                              v-model="img.name"-->
<!--                            />-->
<!--                            <div class="font" v-else>{{ img.name }}</div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="maskLayer" v-show="!img.editKey">-->
<!--                          <div style="font-size: 20px; text-align: right">-->
<!--                            <a-icon-->
<!--                              @click="putIconList(img)"-->
<!--                              style="margin-right: 10px"-->
<!--                              type="edit"-->
<!--                            />-->
<!--                            <a-popconfirm-->
<!--                              placement="bottom"-->
<!--                              title="是否确认删除?"-->
<!--                              ok-text="是"-->
<!--                              cancel-text="否"-->
<!--                              @confirm="delIconList(img.id)"-->
<!--                            >-->
<!--                              <a-icon-->
<!--                                style="margin-right: 10px"-->
<!--                                type="close-circle"-->
<!--                              />-->
<!--                            </a-popconfirm>-->
<!--                          </div>-->
<!--                          <div style="padding-top: 35%">选择</div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

          </div>
      </a-modal>
      <!--    新增/修改-->
      <a-drawer
          :get-container="false"
        :title="titleDrawer"
        :width="600"
        :visible="content_addCard_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="closeAddOrRest"
      >
        <a-form-model
          ref="content_add_ruleForm"
          :model="content_addCard_form"
          :rules="content_addCard_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
        <a-form-model-item label="类型" prop="funcCode">
<!--            <a-select-->
<!--                :disabled="titleDrawer === '修改' && content_addCard_form.funcCode && isRestFun"-->
<!--              show-search-->
<!--              placeholder="请选择"-->
<!--              option-filter-prop="children"-->
<!--              style="width: 260px"-->
<!--              v-model="content_addCard_form.funcCode"-->
<!--            >-->
<!--              &lt;!&ndash;              <a-select-option&ndash;&gt;-->
<!--              &lt;!&ndash;                  @click="content_addCard_form_handleChange(code.code)"&ndash;&gt;-->
<!--              &lt;!&ndash;                  v-for="code in code_selectData"&ndash;&gt;-->
<!--              &lt;!&ndash;                  v-model="code.code">&ndash;&gt;-->
<!--              &lt;!&ndash;                {{code.name}}&ndash;&gt;-->
<!--              &lt;!&ndash;              </a-select-option>&ndash;&gt;-->
<!--              <a-select-option-->
<!--                @click="content_addCard_form_handleChange(code.code, code)"-->
<!--                v-for="code in code_selectData"-->
<!--                v-model="code.name"-->
<!--              >-->
<!--                {{ code.name }}{{ code.type }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
          </a-form-model-item>
          <a-form-model-item v-if="ruleOneOrTwo" prop="icon" label="功能图标">
            <div
              class="surfacePlot"
              style="position: relative"
            >
              <div class="surface">
                <div v-if="!content_addCard_form.icon" class="add">+</div>
                <div v-if="!content_addCard_form.icon" class="font">选择</div>
              </div>
              <div class="uploadImg_img">
                <img
                  v-if="content_addCard_form.icon"
                  alt=""
                  style="
                    width: 98%;
                    height: 98%;
                    border-radius: 5px;
                    margin: auto;
                    margin-top: 2px;background-color:rgba(0,0,0,.85);"
                  :src="content_addCard_form.icon"/>
              </div>
              <div class="upImgBtn">
                <div style="margin-top: 50%;">
                  <a style="color: white" @click="openModel('功能图标','图片库','radio')">自定义上传</a>
                  <a style="margin-left: 15px;color: white" @click="openModel('功能图标','图标库')">选择系统图标</a>
                </div>
              </div>
            </div>
            <!--          <div style="color: #a1a1a1">注:尺寸640*360，大小不超过500KB</div>-->
          </a-form-model-item>
          <a-form-model-item v-if="ruleOneOrTwo" prop="title" label="功能名称">
            <a-input
              style="width: 260px"
              v-model="content_addCard_form.title"
              placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item label="图标背景色">
<!--            <a-input v-model="content_addCard_form.iconBgColor" style="width: 260px">-->
<!--              <input-->
<!--                  :value="content_addCard_form.iconBgColor"-->
<!--                  type="color"-->
<!--                  slot="addonBefore"-->
<!--                  @change="colorAdd"-->
<!--                  style="outline-style: none ;border: 0; padding: 0;height: 29px;">-->
<!--            </a-input>-->
            <a-popover placement="bottom">
              <template slot="content">
                <sketch-picker v-model="content_addCard_form.iconBgColor" @input="colorAdd"/>
              </template>
              <a-button :style="{backgroundColor:content_addCard_form.iconBgColor,width:'80px',height:'35px',marginTop:'3px'}"></a-button>
            </a-popover>
          </a-form-model-item>
        </a-form-model>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button @click="closeAddOrRest"> 取消 </a-button>
          <a-button style="margin-left: 15px" type="primary" @click="addSubmit">
            确定
          </a-button>
        </div>
      </a-drawer>
      <!---------------设置------------------------>
      <a-drawer
        title="设置"
        :width="500"
        :visible="set_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="
          () => {
            this.set_visible = false;
            this.set_form.isNeedPay = 0;
            this.set_form.isShowApplyBar = 0;
            this.set_form.isNeedLogin = 0;
          }
        "
      >
        <div>
          <div style="margin-left: 28px; margin-top: 10px; color: black">
            <span>是否需要缴费：</span>
            <a-switch
              :checked="set_form.isNeedPay === 1 ? true : false"
              @change="set_payFees_Btn(set_form.isNeedPay === 1 ? 0 : 1)"
              style="margin-left: 5px"
              checked-children="是"
              un-checked-children="否"
              default-checked
            />
          </div>
          <div style="margin-top: 10px; color: black">
            <span>是否显示悬浮报名：</span>
            <a-switch
              :checked="set_form.isShowApplyBar === 1 ? true : false"
              @change="set_Suspended_Btn(set_form.isShowApplyBar === 1 ? 0 : 1)"
              style="margin-left: 5px"
              checked-children="是"
              un-checked-children="否"
              default-checked
            />
          </div>
          <div style="margin-left: 28px; margin-top: 10px; color: black">
            <span>是否需要登录：</span>
            <a-switch
              :checked="set_form.isNeedLogin === 1 ? true : false"
              @change="set_login_Btn(set_form.isNeedLogin === 1 ? 0 : 1)"
              style="margin-left: 5px"
              checked-children="是"
              un-checked-children="否"
              default-checked
            />
          </div>
        </div>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button
            @click="
              () => {
                this.set_visible = false;
                this.set_form.isNeedPay = 0;
                this.set_form.isShowApplyBar = 0;
                this.set_form.isNeedLogin = 0;
              }
            "
          >
            关闭
          </a-button>
        </div>
      </a-drawer>
      <!--内容-字幕文字通知-->
      <a-drawer
        title="内容-字幕文字通知"
        :width="680"
        :visible="content_alphabetic_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="closeAlphabetic"
      >
        <a-form-model
          ref="ruleForm"
          :model="content_ByAdvertising_form"
          :rules="content_ByAdvertising_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="字幕内容">
            <a-input v-model="content_alphabetic_font" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item>
            <div style="text-align: center; width: 600px">
              <a-button @click="content_alphabetic_submit" type="primary"
                >提交</a-button
              >
            </div>
          </a-form-model-item>
          <a-form-model-item>
            <div style="text-align: center; width: 600px">
              <a-table
                :rowKey="(record) => record.id"
                style="width: 98%; margin: auto"
                :columns="content_alphabetic_columns"
                :data-source="content_alphabetic_data"
              >
                <span slot="system" slot-scope="text, record">
                  <a-popconfirm
                    title="是否确认删除?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="content_alphabetic_delBtn(record.id)"
                    @cancel="() => {}"
                  >
                    <a>删除</a>
                  </a-popconfirm>
                </span>
              </a-table>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" @click="closeAlphabetic"> 关闭 </a-button>
        </div>
      </a-drawer>
      <!--内容-图片展示  HYC-->
      <a-drawer
          :get-container="false"
        title="内容-图片展示"
        :width="680"
        :visible="picture_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="
          () => {
            this.picture_visible = false;
            this.picture_form.picture = [];
            // this.picture_form.topBanner = '';
            // this.picture_form.isOpenTopTitleBar = 1;
          }
        "
      >
        <a-form-model
          ref="ruleForm"
          :model="picture_form"
          :rules="picture_rules"
          :label-col="{ span: 2 }"
          :wrapper-col="wrapperCol"
        >
<!--          <a-form-model-item prop="banner" label="顶部标题栏">-->
<!--            <a-switch-->
<!--                :checked="picture_form.isOpenTopTitleBar === 1 ? true : false"-->
<!--                checked-children="开启"-->
<!--                un-checked-children="关闭"-->
<!--                @change="isOpenTopTitleBarBtn(picture_form.isOpenTopTitleBar === 0 ? 1 : 0)"-->
<!--                default-checked-->
<!--            />-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item prop="pcBanner" label="顶部banner">-->
<!--            <div style="position: relative;width: 288px;">-->
<!--              <div class="upImgModelBanner" @click="openModel('顶部banner','','radio')">-->
<!--                <div class="contentBanner" v-if="!picture_form.topBanner">-->
<!--                  <div class="addModel">+</div>-->
<!--                  <div>-->
<!--                    上传-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <img v-if="picture_form.topBanner" class="topBanner"-->
<!--                       :src="picture_form.topBanner" alt="">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="hoverBgcIconBanner" v-if="picture_form.topBanner">-->
<!--                <div class="iconDel">-->
<!--                  <admin_con-->
<!--                      @click="()=>{picture_form.topBanner = ''}"-->
<!--                      type="icon-shanchu1"/>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div style="color: #a1a1a1">注：大小不超过2M</div>-->
<!--          </a-form-model-item>-->
          <a-form-model-item prop="picture" label="图片">
            <!-- <div class="uploadImg">
              <input
                id="upload_HYC"
                style="width: 100%; height: 100%; opacity: 0"
                accept="image/*"
                type="file"
                @change="picture_image"
                ref="detailCoverUrl_hyc"
              />
            </div> -->
            <div class="uploadImg" @click="openModel('图片展示','','checkbox')">
              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div class="add">+</div>
                <div style="font-size: 17px; font-weight: 520; color: #bdbcbc">
                  上传
                </div>
              </div>
            </div>
          </a-form-model-item>
          <div style="color: #a1a1a1; margin-left: 8%">
            注:尺寸 宽640，大小不超过1M
          </div>
          <a-row :gutter="1" style="margin-top: 20px; margin-left: 6%">
            <a-col
              style="position: relative"
              class="gutter-row"
              :span="6"
              v-for="(img, index) in picture_form.picture"
            >
              <div class="gutter-box">
                <div class="upload_gutter">
                  <a-icon
                    @click="delPicListBtn(index)"
                    style="
                      color: red;
                      font-size: 25px;
                      position: absolute;
                      top: 15px;
                      right: 20px;
                    "
                    type="close-circle"
                  />
                  <img
                    v-if="picture_form.picture[0] !== ''"
                    alt=""
                    style="width: 100%; height: 100%"
                    :src="picture_form.picture[index]"
                  />
                </div>
              </div>
            </a-col>
          </a-row>
        </a-form-model>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button
            @click="
              () => {
                this.picture_visible = false;
                this.picture_form.picture = [];
                // this.picture_form.topBanner = '';
                // this.picture_form.isOpenTopTitleBar = 1;
              }
            "
          >
            取消
          </a-button>
          <a-button
            style="margin-left: 20px"
            type="primary"
            @click="pictureTrueBtn"
          >
            确定
          </a-button>
        </div>
      </a-drawer>
      <!-- 内容-PDF展示   -->
      <a-modal
        @cancel="
          () => {
            this.content_pdf_visible = false;
            this.content_pdf_list = [];
          }
        "
        width="500px"
        v-model="content_pdf_visible"
        title="内容-PDF展示"
      >
<!--        <div style="width: 450px">-->
<!--          <div class="uploadpdf">-->
<!--            <input-->
<!--              id="content_pdfUrl"-->
<!--              style="width: 100%; height: 100%; opacity: 0"-->
<!--              accept=".pdf"-->
<!--              type="file"-->
<!--              @change="content_pdf"-->
<!--              ref="content_pdfUrl"-->
<!--            />-->
<!--          </div>-->
<!--          <div>-->

<!--          </div>-->
<!--        </div>-->
        <div style="width: 450px">
          <div class="uploadpdf">
            <input
                multiple
                id="content_pdfUrl"
                ref="content_pdfUrl"
                style="width: 100%; height: 100%; opacity: 0"
                accept=".pdf"
                type="file"
                @change="content_pdf('content_pdfUrl')"
            />
          </div>
          <div v-for="(item,index) in content_pdf_list" :key="item.value" style="margin-bottom: 5px">
            {{ item.name }} <span v-if="item.name">: </span>
            <div v-if="item.name">
              <div style="display: flex;justify-content: space-between">
                <a-progress :percent="item.name ? 100 : 0" />
                <a-icon type="close-circle" @click="delectPdfList(index)" style="color: red;margin-top: 5px;cursor: pointer"/>
              </div>
            </div>
          </div>
        </div>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button
            @click="
              () => {
                this.content_pdf_visible = false;
                this.content_pdf_list = [];
              }
            "
          >
            取消
          </a-button>
          <a-button
            style="margin-left: 10px"
            type="primary"
            @click="content_pdf_Ok"
          >
            确认
          </a-button>
        </div>
      </a-modal>
      <!--内容-图文链接展示-->
      <a-drawer
          :get-container="false"
        title="内容-云展厅图文链接展示"
        :width="680"
        :visible="content_GraphicLinks_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="
          () => {
            this.content_GraphicLinks_visible = false;
          }
        "
      >
        <a-form-model
          ref="content_GraphicLinks_ruleForm"
          :model="content_GraphicLinks_form"
          :rules="content_GraphicLinks_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="链接" prop="targetUrl">
            <div style="display: flex; margin-top: 5px">
              <a-input
                v-model="content_GraphicLinks_form.targetUrl"
                placeholder="请输入"
              />
              <a-button @click="openLinkModel">选择</a-button>
            </div>
          </a-form-model-item>

          <a-form-model-item label="标题" prop="title">
            <a-input
              v-model="content_GraphicLinks_form.title"
              placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item prop="targetUrl" label="图片">
            <div style="display: flex; width: 450px">
              <div class="uploadImg" @click="openModel('云展厅','','radio')">
                <img
                  v-if="content_GraphicLinks_form.displayUrl"
                  style="width: 100%; height: 100%; border: 1px solid #f17042"
                  :src="content_GraphicLinks_form.displayUrl"
                />
                <div
                  v-else
                  style="
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div class="add">+</div>
                  <div
                    style="font-size: 17px; font-weight: 520; color: #bdbcbc"
                  >
                    上传
                  </div>
                </div>
              </div>
              <!--              <div class="upload_deyails">-->
              <!--                <img-->
              <!--                    v-if="content_GraphicLinks_form.displayUrl"-->
              <!--                    alt=""-->
              <!--                    style="width: 100%;height:100%"-->
              <!--                    :src="content_GraphicLinks_form.displayUrl"-->
              <!--                />-->
              <!--              </div>-->
            </div>
            <div style="color: #a1a1a1">注:尺寸 640*173，大小不超过500kb</div>
          </a-form-model-item>
          <a-form-model-item>
            <div style="text-align: center; width: 600px">
              <a-button @click="content_GraphicLinks_submit" type="primary"
                >提交</a-button
              >
            </div>
          </a-form-model-item>
          <a-form-model-item>
            <div style="text-align: center; width: 600px">
              <a-table
                :rowKey="(record) => record.id"
                style="width: 98%; margin: auto"
                :columns="content_GraphicLinks_columns"
                :data-source="content_GraphicLinks_data"
              >
                <span slot="system" slot-scope="text, record">
                  <a-popconfirm
                    title="是否确认删除?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="content_GraphicLinks_data_delBtn(record.id)"
                    @cancel="() => {}"
                  >
                    <a>删除</a>
                  </a-popconfirm>
                  <a
                    @click="content_GraphicLinks_up(record.id)"
                    style="margin-left: 8px"
                    >上移</a
                  >
                  <a
                    @click="content_GraphicLinks_down(record.id)"
                    style="margin-left: 8px"
                    >下移</a
                  >
                </span>
                <span slot="displayUrl" slot-scope="text, record">
                  <img
                    style="width: 80px; height: auto"
                    :src="record.displayUrl"
                    alt=""
                  />
                </span>
                <span slot="titleData" slot-scope="text, record">
                  <a @click="openLinkUrl(record)">{{ record.title }}</a>
                </span>
              </a-table>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button
            type="primary"
            @click="
              () => {
                this.content_GraphicLinks_visible = false;
              }
            "
          >
            关闭
          </a-button>
        </div>
      </a-drawer>
      <!-- 内容-链接展示   -->
      <a-modal
        width="600px"
        v-model="content_LinkShow_visible"
        title="内容-链接展示"
        @ok="content_LinkShow_Ok"
      >
        <div style="display: flex">
          <span style="width: 50px">链接：</span>
          <a-textarea
            v-model="content_LinkShow_dataVal"
            placeholder="请输入"
            :rows="4"
          />
        </div>
        <span
          v-if="content_LinkShow_dataValIsTrue"
          style="color: red; margin-left: 50px"
          >请输入</span
        >
      </a-modal>
      <!-- 设置备注   -->
      <a-modal
          width="600px"
          v-model="setRemarksVisible"
          title="设置备注"
          @cancel="setRemark_cancel"
          @ok="setRemark_Ok"
      >
        <div style="display: flex">
          <span style="width: 120px;text-align: right;">备注：</span>
          <a-textarea
              style="width: 400px"
              v-model="remarkVal"
              placeholder="请输入"
              :rows="4"
          />
        </div>
        <div style="display: flex;margin-top: 20px">
          <span style="width: 120px;text-align: right;">备注显示位置：</span>
          <a-radio-group v-model="remarkLocation">
            <!--            up 页面上方  down 页面下方-->
            <a-radio value="up">
              页面上方
            </a-radio>
            <a-radio value="down">
              页面下方
            </a-radio>
          </a-radio-group>
        </div>
      </a-modal>
<!--      学分配置-->
      <a-drawer
        title="学分配置"
        :width="600"
        :visible="configuration_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="configurationClose"
      >
        <a-form-model
          ref="configuration_ruleForm"
          :model="configuration_form"
          :label-col="labelCol"
          :rules="configurationRules"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="备注事项" prop="remark">
            <a-textarea
              v-model="configuration_form.remark"
              placeholder="请输入"
              style="width: 300px"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
          <!--          <a-form-model-item label="授予学分" prop="credit">-->
          <!--            <a-input-number :min="1" style="width: 300px" v-model="configuration_form.credit" placeholder="请输入" />-->
          <!--          </a-form-model-item>-->
        </a-form-model>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button @click="configurationClose">取消</a-button>
          <a-button
            style="margin-left: 10px"
            type="primary"
            @click="configurationSubmit"
            >确定</a-button
          >
        </div>
      </a-drawer>
    </a-spin>
    <AddExpertModel :expertForm="expertForm" />
    <pictureDialog
      :info="pInfo"
      :visible="visiblePic"
      v-on:closeMain="closeMain"
      v-on:checkList="checkList"
    >
    </pictureDialog>
    <!-- 人员名单-->
    <a-drawer
      :width="1200"
      :visible="ExcelMember"
      title="人员名单"
      :body-style="{ paddingBottom: '80px' }"
      @close="excelClose"
    >
      <div style="width: 100%">
        <div
          style="
            display: flex;
            height: 35px;
            line-height: 35px;
            justify-content: space-between;
          "
        >
          <div>
            <span>手机号：</span>
            <a-input
              allowClear
              placeholder="请输入"
              style="width: 200px"
              @pressEnter="inquireBtn"
              v-model="listTel"
            />
            <a-button
              @click="inquireBtn"
              style="margin-left: 10px"
              type="primary"
              >查询</a-button
            >
          </div>
          <a-button @click="DownloadTemplate" style="margin-left: 20px"
            >下载模板</a-button
          >
        </div>
        <div style="margin-top: 20px">
          <a-table
            :columns="columnsList"
            :pagination="false"
            :rowKey="(record) => record.id"
            :data-source="dataLists"
          >
            <template slot="serialNumber" slot-scope="text, record, index">
              <span>{{ (listPageNo - 1) * 10 + index + 1 }}</span>
            </template>
            <template slot="sex" slot-scope="text, record, index">
              <span>{{
                record.sex === 1 ? "男" : record.sex === 2 ? "女" : "未知"
              }}</span>
            </template>
            <template slot="isBasicLevel" slot-scope="text, record, index">
              <span>{{ record.isBasicLevel === 1 ? "是" : "否" }}</span>
            </template>
          </a-table>
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: right;
              margin-top: 5px;
            "
          >
            <a-pagination
              show-quick-jumper
              show-size-changer
              :current="listPageNo"
              :pageSize="listpageSize"
              :total="listCount"
              @showSizeChange="templateChangeSize"
              @change="templateChange"
            />
          </div>
        </div>
      </div>
    </a-drawer>
    <!--  选择链接    -->
    <linkDialog
        :info="true"
      :visible="visibleLink"
      v-on:closeMain="closeMainLink"
      v-on:checkList="checkListLink"
    ></linkDialog>
    <MessageAlert
        :visible="visibleAlert"
        v-on:closeMain="closeMainAlert"
    ></MessageAlert>
<!--    富文本-->
    <a-drawer
        :get-container="false"
        title="富文本"
        :width="700"
        :visible="richText_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_richText"
    >
      <a-form-model
          ref="ruleForm"
          :model="html_form"
          :label-col="{ span:2}"
          :wrapper-col="wrapperCol"
      >
<!--        <a-form-model-item prop="banner" label="顶部标题栏">-->
<!--          <a-switch-->
<!--              :checked="html_form.isOpenTopTitleBar === 1 ? true : false"-->
<!--              checked-children="开启"-->
<!--              un-checked-children="关闭"-->
<!--              @change="isOpenTopTitleBarHTMLBtn(html_form.isOpenTopTitleBar === 0 ? 1 : 0)"-->
<!--              default-checked-->
<!--          />-->
<!--          <div v-if="!html_form.isOpenTopTitleBar" style="color: #a1a1a1">注：关闭标题栏，建议设置banner图</div>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item prop="pcBanner" label="顶部banner">-->
<!--          <div style="position: relative;width: 288px;">-->
<!--            <div class="upImgModelBanner" @click="openModel('HTML顶部banner','','radio')">-->
<!--              <div class="contentBanner" v-if="!html_form.topBanner">-->
<!--                <div class="addModel">+</div>-->
<!--                <div>-->
<!--                  上传-->
<!--                </div>-->
<!--              </div>-->
<!--              <div>-->
<!--                <img v-if="html_form.topBanner" class="topBanner"-->
<!--                     :src="html_form.topBanner" alt="">-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="hoverBgcIconBanner" v-if="html_form.topBanner">-->
<!--              <div class="iconDel">-->
<!--                <admin_con-->
<!--                    @click="()=>{html_form.topBanner = ''}"-->
<!--                    type="icon-shanchu1"/>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="color: #a1a1a1">注：大小不超过2M</div>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="富文本">
          <div style="border: 1px solid #ccc;width:550px;">
            <Toolbar
                style="border-bottom: 1px solid #ccc;"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 500px; overflow-y: hidden;"
                v-model="html_form.html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </a-form-model-item>
      </a-form-model>

      <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
      >
        <a-button @click="close_richText"> 取消 </a-button>
        <a-button style="margin-left: 15px" type="primary" @click="richTextSubmit">
          确定
        </a-button>
      </div>
    </a-drawer>
<!--    服务介绍-->
    <ServiceIntroduction
        v-if="ServiceVisible"
        :visible="ServiceVisible"
        :titleName="ServiceTitle"
        :isWhos="isWhos"
        :objContent="objContent"
        :conferenceCode="meetCode"
        v-on:closeMain="closeMainService"/>

<!--    主办单位设置-->
    <OrganizerSetup
        v-if="OrganizerVisible"
        :meetCode="meetCode"
        :visible="OrganizerVisible"
        v-on:closeMain="closeMainOrganizer"
    />

<!--    技术支持设置-->
    <TechnicalSupport
        v-if="TechnicalVisible"
        :meetCode="meetCode"
        :visible="TechnicalVisible"
        v-on:closeMain="closeMainTechnical"
    />
<!--    注册报名-->
    <a-drawer
        :get-container="false"
        title="自定义表单"
        :width="1000"
        :visible="ZCBM_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_ZCBM"
    >
      <div>
<!--      <a-form-model-->
<!--          ref="ruleForm"-->
<!--          :model="ZCBMForm"-->
<!--          :label-col="labelCol_video"-->
<!--          :wrapper-col="wrapperCol"-->
<!--      >-->
<!--        <a-form-model-item prop="banner" label="顶部标题栏">-->
<!--          <a-switch-->
<!--              :checked="ZCBMForm.isOpenTopTitleBar === 1 ? true : false"-->
<!--              checked-children="开启"-->
<!--              un-checked-children="关闭"-->
<!--              @change="isOpenTopTitleBarZCBMBtn(ZCBMForm.isOpenTopTitleBar === 0 ? 1 : 0)"-->
<!--              default-checked-->
<!--          />-->
<!--          <div v-if="!ZCBMForm.isOpenTopTitleBar" style="color: #a1a1a1">注：关闭标题栏，建议设置banner图</div>-->
<!--        </a-form-model-item>-->

<!--        <a-form-model-item prop="pcBanner" label="顶部banner">-->
<!--          <div style="position: relative;width: 288px;">-->
<!--            <div class="upImgModelBanner" @click="openModel('ZCBM顶部banner','','radio')">-->
<!--              <div class="contentBanner" v-if="!ZCBMForm.topBanner">-->
<!--                <div class="addModel">+</div>-->
<!--                <div>-->
<!--                  上传-->
<!--                </div>-->
<!--              </div>-->
<!--              <div>-->
<!--                <img v-if="ZCBMForm.topBanner" class="topBanner"-->
<!--                     :src="ZCBMForm.topBanner" alt="">-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="hoverBgcIconBanner" v-if="ZCBMForm.topBanner">-->
<!--              <div class="iconDel">-->
<!--                <admin_con-->
<!--                    @click="delZCBMImg"-->
<!--                    type="icon-shanchu1"/>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="color: #a1a1a1">注：大小不超过2M</div>-->
<!--        </a-form-model-item>-->
<!--      </a-form-model>-->
      </div>

      <a-button type="primary" @click="openZCBM" style="margin-bottom: 10px">+ 新增</a-button>
      <!-- <a-button  v-if="qrStatus !== ''"  @click="openQrCode" style="margin-bottom: 10px;float:right;margin-right:30px">签到二维码</a-button> -->


      <!-- <a-popconfirm title="是否确认生成签到二维码" ok-text="是" cancel-text="否" @confirm="openQrCode()">
        <a-button v-if="qrStatus === 'shengcheng'" style="margin-bottom: 10px;float:right;margin-right:30px">签到二维码</a-button>
      </a-popconfirm> -->



      <a-table
          :scroll="{ x: 1100 }"
          :pagination="false"
          :columns="columnsZCBM"
          :data-source="dataZCBMList">
        <span slot="isMust" slot-scope="text,record">
          <span v-if="text === 0">否</span>
          <span v-if="text === 1">必选</span>
        </span>
        <span slot="type" slot-scope="text,record">
          <span v-if="text === 'text'">填空</span>
          <span v-if="text === 'check'">多选</span>
          <span v-if="text === 'radio'">单选</span>
          <span v-if="text === 'dropdown_radio'">下拉单选</span>
          <span v-if="text === 'multiline'">多行填空</span>
        </span>
        <span slot="rank" slot-scope="text,record ,index">
          <a v-if="index !== 0" @click="moveUpZCBM('up',record.id)" style="margin-right: 8px">上移</a>
          <a v-if="index !== dataZCBMList.length - 1" @click="moveUpZCBM('down',record.id)" >下移</a>
        </span>
        <span slot="action" slot-scope="text,record">
          <a style="margin-right: 8px" @click="UpZCBMList(record)">编辑</a>
          <a-popconfirm
              style="margin-right: 8px"
              title="是否确认删除?"
              @confirm="delCardZCBMList(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-drawer>
<!--    注册报名--新增/修改-->
    <a-drawer
        :get-container="false"
        :title="ZCBMTitle"
        :width="600"
        :visible="ZCBM_add_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_ZCBMAdd"
    >
      <a-form-model
          ref="ZCBM_add_ruleForm"
          :model="ZCBM_form"
          :rules="ZCBM_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="类型" prop="type">
          <a-row>
            <a-col :span="6" v-for="item in codeListZCBM" :key="item.id" style="margin-bottom: 5px">
              <a-button :type="ZCBM_form.type === item.type ? 'primary':''" @click="selectBtn(item)">
                <span v-if="item.id === 1">
<!--                  填空-->
                  <admin_con v-if="ZCBM_form.type === item.type" :style="{fontSize: '16px'}" type="icon-plus-blanksfill-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-plus-blanksfill"/>
                </span>
                <span v-if="item.id === 2">
<!--                  多选-->
                  <admin_con v-if="ZCBM_form.type === item.type" :style="{fontSize: '16px'}" type="icon-duoxuanxuanzhong_o-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-duoxuanxuanzhong_o"/>
                </span>
                <span v-if="item.id === 3">
<!--                  单选-->
                  <admin_con v-if="ZCBM_form.type === item.type" :style="{fontSize: '16px'}" type="icon-danxuan-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-danxuan"/>
                </span>
                <span v-if="item.id === 4">
<!--                  下拉单选-->
                  <admin_con v-if="ZCBM_form.type === item.type" :style="{fontSize: '16px'}" type="icon-xialadanxuan-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-xialadanxuan"/>
                </span>
                <span v-if="item.id === 5">
<!--                  多行填空-->
                  <admin_con v-if="ZCBM_form.type === item.type" :style="{fontSize: '16px'}" type="icon-duoxiangtiankong-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-duoxiangtiankong"/>
                </span>
                <span>{{item.name}}</span>
              </a-button>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="标题" prop="title">
          <a-row>
            <a-col :span="18">
<!--              <a-textarea-->
<!--                  v-if="ZCBM_form.type === 'multiline'"-->
<!--                  v-model="ZCBM_form.title"-->
<!--                  placeholder="请输入"-->
<!--                  :auto-size="{ minRows: 3, maxRows: 5 }"-->
<!--              />-->
              <a-input placeholder="请输入" v-model="ZCBM_form.title"/>
            </a-col>
            <a-col :span="6">
              <a-checkbox style="padding-left: 6px" v-model="ZCBM_form.isMust">必答题</a-checkbox>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="选项" prop="option" v-if="ZCBM_form.type === 'radio' || ZCBM_form.type === 'dropdown_radio' || ZCBM_form.type === 'check'">
          <div style="display: flex;align-items: center;margin-bottom: 5px"  v-for="(item,index) in ZCBM_form.option" :key="index">
            <a-input placeholder="输入选项" style="width: 100%;margin-right: 5px;" v-model="item.title" />
            <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="addOptions" />
            <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delOptions(item,index)" />
          </div>
        </a-form-model-item>
        <a-form-model-item label="提示">
          <a-textarea
              v-model="ZCBM_form.tips"
              placeholder="请输入"
              style="width: 310px"
              :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
      >
        <a-button @click="close_ZCBMAdd"> 取消 </a-button>
        <a-button style="margin-left: 15px" type="primary" @click="ZCBMAdd">
          确定
        </a-button>
      </div>
    </a-drawer>
<!--    查看名单-名单-->
    <a-drawer
        :get-container="false"
        title="查看名单"
        :width="1100"
        :visible="ZCBM_visible_list"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_ZCBMList"
    >
      <div style="display: flex;justify-content: space-between;margin-bottom: 20px">
        <div style="display: flex;">
          <div style="padding-top: 5px;margin-right: 30px;font-weight: bold">提交数量共：<span style="color: #45A5E6">{{pagination.total}}</span>条</div>
          <div>
            <span style="color: #333333">提交时间：</span>
            <a-range-picker style="width: 320px" show-time
                            @change="selectZCBMTime"
                            @ok="selectZCBMTimeOk"></a-range-picker>
          </div>
        </div>
        <div>
          <a-popconfirm
              v-if="pagination.total > 0"
              style="margin-right: 10px"
              title="是否确认清除名单，清除后将无法恢复?"
              @confirm="delZCBMMList"
          >
            <a-button>清除名单</a-button>
          </a-popconfirm>
          <a-button :disabled="loadingZCBM" @click="exportBtn"><a-icon type="file-excel" style="color: #00A854" /> 批量导出</a-button>
        </div>
      </div>

      <a-table
          :pagination="false"
          :columns="columnsZCBMList"
          :data-source="dataListZCBMList">
      </a-table>
      <div
          style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
            style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ pagination.total }}条记录 第 {{ pagination.current }} /
          {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
        </span>
        <a-pagination
            style="float: right"
            show-quick-jumper
            show-size-changer
            :page-size.sync="pagination.pageSize"
            :total="pagination.total"
            v-model="pagination.current"
            @change="pageChange"
            @showSizeChange="pageChange"
        />
      </div>

    </a-drawer>
<!--    清除名单弹窗-->
    <a-modal v-model="clearVisible">
      <div style="height: 50px;font-size: 16px;">
        <div style="display: flex;justify-content: left">
          <a-icon style="color: #f4742f;font-size: 25px;margin-right: 7px" type="info-circle" />
          <div>注册报名已开始，若想新增或编辑数据，请先清空名单！</div>
        </div>
      </div>
      <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #f5f5f5',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
      >
        <a-button @click="closeZCBMMList"> 取消 </a-button>
        <a-popconfirm
            style="margin-left: 15px"
            title="是否确认清除名单，清除后将无法恢复?"
            @confirm="delZCBMMLists"
        >
          <a-button type="primary">清除名单</a-button>
        </a-popconfirm>
      </div>
    </a-modal>

    <!--      数据报告-->
    <DataReport
        v-if="visibleDataReport"
        :liveInfo="liveInfo"
        :dataId="dataId"
        :funcCode1="funcCode1"
        :selectNumber="selectNumber"
        :visible="visibleDataReport"
        v-on:closeMainDataReport="closeMainDataReport"
    ></DataReport>

    <!--    一键设置-->
    <a-modal title="一键设置" v-model="setClickVisible" :footer="false">
      <a-form-model
          :label-col="{ span: 8 }"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="功能名称字体颜色" v-if="isShowIconTitle">
          <a-popover placement="bottom">
            <template slot="content">
              <sketch-picker v-model="iconTitleColorNoBtn" @input="FunctionColorRest"/>
            </template>
            <a-button :style="{marginRight: '10px',marginTop: '5px',marginLeft: '8px',backgroundColor:iconTitleColorNoBtn,width:'70px',height:'30px'}"></a-button>
          </a-popover>
          <a @click="putFunColorApi">保存</a>
        </a-form-model-item>
        <a-form-model-item label="功能图标背景颜色">
          <a-popover placement="bottom">
            <template slot="content">
              <sketch-picker v-model="funIconColor" @input="funIconColorAdd"/>
            </template>
            <a-button :style="{marginRight: '10px',marginTop: '5px',marginLeft: '8px',backgroundColor:funIconColor,width:'70px',height:'30px'}"></a-button>
          </a-popover>
          <a @click="funcIconBgColorBtn">保存</a>
        </a-form-model-item>
        <a-form-model-item label="功能名称">
          <a-switch
              style="margin-right:10px;margin-left: 8px;"
              :checked="isShowIconTitle === 1 ? true : false"
              checked-children="开启"
              un-checked-children="关闭"
              @change="switchEditBtn(isShowIconTitle === 0 ? 1 : 0)"
              default-checked
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!--    排序设置-->
    <a-modal title="排序设置" v-model="setSortVisible" :footer="false">
      <a-form-model
          :label-col="{ span: 8 }"
          :wrapper-col="wrapperCol"
      >
        <a-table
            :pagination="false"
            :columns="columnsModel"
            :data-source="dataListOpen">
          <span slot="action" slot-scope="text,record,index">
            <a v-if="index !== 0" style="margin-right: 8px" @click="moveUp(record.id)">上移</a>
            <a v-if="dataListOpen.length-1 !== index" style="margin-right: 8px" @click="shiftDown(record.id)">下移</a>
          </span>
        </a-table>
      </a-form-model>
    </a-modal>

    <!--标题栏设置-->
    <a-modal
        width="800px"
        title="设置标题栏"
        v-model="setTitleBar"
        @cancel="setTitleBar_cancel"
        @ok="setTitleBar_Ok"
    >
      <a-form-model
          ref="ruleForm"
          :model="setTitleBar_form"
          :label-col="labelColImg"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="示例图">
          <div class="sampleGraph">
            <div style="width: 18%;text-align: center">< 返回</div>
            <div style="width: 64%;text-align: center">{{setTitleBar_form.title}}</div>
            <div style="width: 18%;"></div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="标题栏">
          <a-switch
              :checked="setTitleBar_form.isOpenTopTitleBar === 1 ? true : false"
              checked-children="开启"
              un-checked-children="关闭"
              @change="isOpenTopTitleBarBtn(setTitleBar_form.isOpenTopTitleBar === 0 ? 1 : 0)"
              default-checked
          />
        </a-form-model-item>
        <a-form-model-item label="banner图（非必选）">
          <div style="position: relative;width: 288px;">
            <div class="upImgModelBanner" @click="openModel('banner图（非必选）','','radio')">
              <div class="contentBanner" v-if="!setTitleBar_form.topBanner">
                <div class="addModel">+</div>
                <div>
                  上传
                </div>
              </div>
              <div>
                <img v-if="setTitleBar_form.topBanner" class="topBanner"
                     :src="setTitleBar_form.topBanner" alt="">
              </div>
            </div>
            <div class="hoverBgcIconBanner" v-if="setTitleBar_form.topBanner">
              <div class="iconDel">
                <admin_con
                    @click="()=>{setTitleBar_form.topBanner = ''}"
                    type="icon-shanchu1"/>
              </div>
            </div>
          </div>
          <div style="color: #a1a1a1">注：大小不超过2M</div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!--  功能新增修改  -->
    <a-modal
        width="1000px"
        :title="titleDrawer"
        v-model="funListVisible"
        @cancel="funListVisible_cancel"
        @ok="funListVisible_Ok"
    >
      <a-form-model
            ref="funList_ruleForm"
            :model="content_addCard_form"
            layout="inline"
        >
          <a-form-model-item>
            <span slot="label"><span style="color: red">*</span> 功能名称</span>
            <a-input
                style="width: 260px"
                v-model="content_addCard_form.title"
                placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item label="名称字体颜色">
            <a-popover placement="bottom">
              <template slot="content">
                <sketch-picker v-model="content_addCard_form.iconTitleColor" @input="colorIconTitleColor"/>
              </template>
              <a-button :style="{backgroundColor:content_addCard_form.iconTitleColor,width:'80px',height:'35px',marginTop:'3px'}"></a-button>
            </a-popover>
          </a-form-model-item>
          <a-form-model-item label="功能背景颜色">
            <a-popover placement="bottom">
              <template slot="content">
                <sketch-picker v-model="content_addCard_form.iconBgColor" @input="colorIconBgColor"/>
              </template>
              <a-button :style="{backgroundColor:content_addCard_form.iconBgColor,width:'80px',height:'35px',marginTop:'3px'}"></a-button>
            </a-popover>
          </a-form-model-item>
        </a-form-model>
      <div class="model-fun">
        <div class="fun-top">
          <div style="display: flex;">
            <span style="color: red;margin-right: 5px;">* </span> <span style="font-weight: bold">功能类型</span>
            <a-alert banner closable class="fun-alert">
              <span slot="message">修改功能类型会<span style="color: red;">清空原类型下设置的内容</span>，请谨慎操作</span>
            </a-alert>
            </div>
        </div>
        <div style="padding: 20px;">
          <div v-for="(item,index) in funModelList" :key="index">
            <div style="font-weight: bold">{{item.type}} <a-tag :color="item.serviceCode === 'freefree' ? 'green':item.serviceCode === 'standard' ? 'red':item.serviceCode === 'flagship' ? 'purple':''">{{item.serviceCode === 'freefree' ? '免费':item.serviceCode === 'standard' ? '标准版':item.serviceCode === 'flagship' ? '旗舰版':''}}</a-tag></div>
            <div style="margin-top: 15px;margin-bottom: 15px;min-width: 100px;">
              <div v-for="(redio,index) in item.arr" :key="index" @click="funCodeValChange(redio.code)" class="funRadio" :style="{color:content_addCard_form.funcCode === redio.code ? '#1890FF':'',border:content_addCard_form.funcCode === redio.code ? '1px solid #1890FF':'1px solid #9c9b9b'}">
                <img :src="redio.icon" style="width: 20px;height: auto;margin-top: -5px;" alt="">
                {{redio.name}}
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 注册缴费 -->
    <a-drawer
        :get-container="false"
        title="注册缴费"
        :width="1000"
        :visible="ZCJF_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_ZCJF">
      <a-button type="primary"  style="margin-bottom: 10px" @click="openZCJF">+ 新增</a-button>
      <a-button  v-if="qrStatus !== ''"  @click="openQrCode" style="margin-bottom: 10px;float:right;margin-right:30px">签到二维码</a-button>
      <a-table  :columns="columnsZCJF"  :data-source="dataZCJFList" :pagination="false">
        <span slot="fieldType" slot-scope="text,record">
          <span v-if="text === 'text'">填空</span>
          <span v-if="text === 'check'">多选</span>
          <span v-if="text === 'radio'">单选</span>
          <span v-if="text === 'dropdown_radio'">下拉单选</span>
          <span v-if="text === 'multiline'">多行填空</span>
          <span v-if="text == 'radio_pay'">单选付费</span>
           <span v-if="text == 'date'">日期</span>
        </span>
        <span slot="fieldLabel" slot-scope="text,record" >
        <a-tag color="blue" v-if="record.type=='系统字段'">
          系统
         </a-tag>
         {{record.fieldLabel}}
         <a-tooltip v-if="record.fieldName=='baomingType'">
          <template slot="title">
             报名类型只有一个选项时,用户端不会展示该题目
            </template>
           <a-icon type="exclamation-circle" />
           </a-tooltip>
        </span>
        <span slot="isRequired" slot-scope="text,record">
           <span v-if="record.isRequired==1">必选</span>
            <span v-if="record.isRequired==0">否</span>
        </span>
       <span  slot="rank" slot-scope="text,record,index">
            <a @click="moveZCJF('up',record.id)"  style="margin-right:7px" v-if="index!==1 && record.fieldName !=='baomingType'">上移</a>
            <a @click="moveZCJF('down',record.id)" v-if="index!==dataZCJFList.length-1 && record.fieldName !=='baomingType'">下移</a>
       </span>
       <!-- <span slot="isOpen" slot-scope="text,record,index">
           <a-switch
              v-if="record.type=='系统字段'"
              :checked="record.isOpen === 1? true : false"
              checked-children="开启"
              un-checked-children="关闭"
              @change="changeOpenZCJF(record.isOpen === 1? 0 : 1,record)"
              default-checked
            ></a-switch>
       </span> -->
       <span slot="action" slot-scope="text,record">
          <a @click="editZCJF(record)">编辑</a> &ensp;
           <a-popconfirm
              style="margin-right: 8px"
              title="是否确认删除?"
              @confirm="delZCJF(record.id)"
          >
            <a v-if="record.fieldName!=='baomingType'&& record.fieldName!=='payItem' ">删除</a>
          </a-popconfirm>
       </span>

      </a-table>
    </a-drawer>

 <!--    注册缴费--新增/修改-->
    <a-drawer
        :get-container="false"
        :title="ZCJFTitle"
        :width="600"
        :visible="ZCJF_add_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="ZCJFClose"
    >
       <a-form-model-item label="类型"  :label-col="{ span:4  }"   v-if="ZCJFTitle=='新增'"
          :wrapper-col="{ span: 17 }">
        <!-- 新增的时候才显示 -->
        <a-radio-group default-value="0"
         button-style="solid"
          style="margin-left:20%;margin-bottom:0px"
          v-model="ZCJFADDType"
          >
         <a-radio-button :value="0">
         系统字段
        </a-radio-button>
        <a-radio-button :value="1">
         自定义字段
        </a-radio-button>
        </a-radio-group>
        </a-form-model-item>
        <!-- 自定义字段表单 -->
        <a-form-model
          ref="ZCJF_add_ruleForm"
          :model="ZCJF_form"
          :rules="ZCJF_rules"
          v-if="ZCJFADDType==1"
      >

        <a-form-model-item label="类型" prop="type" :label-col="{ span: 4 }"
          :wrapper-col="{ span: 17 }">

            <span v-for="item in codeListZCJF" :key="item.id" style="margin-bottom: 5px;background:red;margin-right:10px" >
              <a-button :type="ZCJF_form.type === item.type ? 'primary':''" @click="selectBtnZCJF(ZCJF_form,item)">
                <span v-if="item.id === 1">
<!--                  填空-->
                  <admin_con v-if="ZCJF_form.type === item.type" :style="{fontSize: '16px'}" type="icon-plus-blanksfill-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-plus-blanksfill"/>
                </span>
                <span v-if="item.id === 2">
<!--                  多选-->
                  <admin_con v-if="ZCJF_form.type === item.type" :style="{fontSize: '16px'}" type="icon-duoxuanxuanzhong_o-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-duoxuanxuanzhong_o"/>
                </span>
                <span v-if="item.id === 3">
<!--                  单选-->
                  <admin_con v-if="ZCJF_form.type === item.type" :style="{fontSize: '16px'}" type="icon-danxuan-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-danxuan"/>
                </span>
                <span v-if="item.id === 4">
<!--                  下拉单选-->
                  <admin_con v-if="ZCJF_form.type === item.type" :style="{fontSize: '16px'}" type="icon-xialadanxuan-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-xialadanxuan"/>
                </span>
                <span v-if="item.id === 5">
<!--                  多行填空-->
                  <admin_con v-if="ZCJF_form.type === item.type" :style="{fontSize: '16px'}" type="icon-duoxiangtiankong-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-duoxiangtiankong"/>
                </span>
                 <span v-if="item.id === 6">

                  <admin_con v-if="ZCJF_form.type === item.type" :style="{fontSize: '16px'}"  type="icon-xialadanxuan-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-xialadanxuan"/>
                </span>
                <span v-if="item.id === 7">

                  <admin_con v-if="ZCJF_form.type === item.type" :style="{fontSize: '16px'}" type="icon-duoxiangtiankong-copy"/>
                  <admin_con v-else :style="{fontSize: '16px'}" type="icon-duoxiangtiankong"/>
                </span>

                <span>{{item.name}}</span>
              </a-button>
            </span>

        </a-form-model-item>
        <a-form-model-item label="标题" prop="title" :label-col="labelCol"
          :wrapper-col="wrapperCol">
          <a-row>
            <a-col :span="18">
              <a-input placeholder="请输入" v-model="ZCJF_form.title"
              :disabled="ZCJF_form.zhiduanType =='系统字段'?true:false"/>
            </a-col>
            <a-col :span="6">
              <a-checkbox style="padding-left: 6px" v-model="ZCJF_form.isMust"
               :disabled="ZCJF_form.fieldName=='baomingType' ||ZCJF_form.fieldName=='payItem' ">必答题</a-checkbox>
            </a-col>
          </a-row>
        </a-form-model-item>
        <!-- 不是单选付费选项 -->
        <a-form-model-item label="选项" prop="option" v-if="ZCJF_form.type === 'radio' || ZCJF_form.type === 'dropdown_radio' || ZCJF_form.type === 'check'" :label-col="labelCol"
          :wrapper-col="wrapperCol">
          <div style="display: flex;align-items: center;margin-bottom: 5px"  v-for="(item,index) in ZCJF_form.option" :key="index">
            <!-- 解析性别 -->
            <a-input v-if="ZCJF_form.fieldName =='sex'"  v-model="item.name == 0?'女':item.name=='1'?'男':'其他'" :disabled="ZCJF_form.zhiduanType =='系统字段'?true:false"></a-input>
            <a-input v-if="ZCJF_form.fieldName !=='sex'" placeholder="输入选项" style="width: 100%;margin-right: 5px;" v-model="item.name"  :disabled="ZCJF_form.zhiduanType =='系统字段'?true:false"/>
            <!-- title为报名类型且为系统字段显示开关  其他显示未加减 -->
            <a-switch v-if="ZCJF_form.fieldName=='baomingType'&&ZCJF_form.zhiduanType =='系统字段'"
              :checked="item.isOpen === 1? true : false"
              checked-children="开启"
              un-checked-children="关闭"
              @change="changeZCJF(item.isOpen === 1? 0 : 1,index)"
              default-checked
            ></a-switch>


            <a-icon type="plus-circle" v-else-if="index <=0 &&ZCJF_form.zhiduanType !=='系统字段'" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"    @click="addOptionsZCJF(ZCJF_form.type)"/>
            <a-icon type="minus-circle" v-else-if="index!==0&& ZCJF_form.zhiduanType !=='系统字段'" style="cursor: pointer;font-size: 18px;margin-right: 10px"    @click="delOptionsZCJF(item,index)" />


          </div>
            <span v-if="ZCJF_form.fieldName=='baomingType' && ZCJF_form.zhiduanType=='系统字段'">
              <a style="color:red">*</a>报名类型只有一个选项时，用户端不会展示该题目
              </span>

        </a-form-model-item>

        <!-- 单选付费选项------ -->
          <a-row :gutter="24">
            <a-col :span="12" >
        <a-form-model-item label="选项" prop="option" v-if="ZCJF_form.type === 'radio_pay'" :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }">
            <div style="display: flex;align-items: center;margin-bottom: 5px"  v-for="(item,index) in ZCJF_form.option" :key="index">
             <a-input placeholder="输入选项" style="width: 100%;margin-right: 5px;" v-model="item.name" />
            </div>
        </a-form-model-item>
            </a-col>
            <a-col  :span="12">
          <a-form-model-item label="金额" prop="option" v-if="ZCJF_form.type === 'radio_pay'" :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }" style="margin-left:-60px">
            <div style="display: flex;align-items: center;margin-bottom: 5px"  v-for="(item,index) in ZCJF_form.option" :key="index">
             <a-input placeholder="输入金额" :min="0" style="width: 100%;margin-right: 5px;" v-model="item.money"  addon-after="元" type="number"/>
             <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="addOptionsZCJF(ZCJF_form.type)"/>
             <a-icon type="minus-circle" v-if="index!==0 " style="cursor: pointer;font-size: 18px;margin-right: 10px"    @click="delOptionsZCJF(item,index)" />
            </div>
          </a-form-model-item>
         </a-col>
          </a-row>
        <a-form-model-item label="提示" :label-col="labelCol"
          :wrapper-col="wrapperCol">
          <a-textarea
              v-model="ZCJF_form.tips"
              placeholder="请输入"
              style="width: 310px"
              :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
        </a-form-model>
        <!-- 系统字段表格 -->
         <a-form-model-item label="字段"  :label-col="{ span:4  }"   v-if="ZCJFADDType==0"
          :wrapper-col="{ span: 17 }">
         <a-table :columns="columnsZCJFSystems" :data-source="ZCJFSystemList" :pagination="false"   style="margin-left:10%"
         :row-selection="rowSelection" >

        </a-table>
       </a-form-model-item>

       <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
      >
        <a-button @click="ZCJFClose()"> 取消 </a-button>
        <a-button style="margin-left: 15px" type="primary" @click="ZCJFADDType==1?ZCJFAdd():ZCJFADDSys()">
          确定
        </a-button>
      </div>
    </a-drawer>
    <!-- 查看签到二维码 -->
    <a-modal
          width="550px"
          v-model="linkVisible"
          title="二维码生成"
          :footer="null"
          @close="() =>{this.qrStatus = ''} "
          class="erweima"
          >
        <div style="display: flex;justify-content: center;align-items: center;flex-direction:column">
          <div class="codeLink" v-if="this.qrStatus === 'zhanshi'">
            <div style="border: 1px dashed #000;background-color: #fff;">
              <div id="qrCode">
              <vue-qr
                  ref="image"
                  :logo-src="logoSrc"
                  :size="150"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="app2Src"
                  style="display:none"/>
              <img :src="appSrc" alt="" style="width:150px">
              </div>
           </div>
          </div>

          <div class="codeLink" v-if="this.qrStatus === 'shengcheng'">
            <div class="codeBox">
              <div style="text-align: center;">
                <a-icon type="qrcode" style="color:#45a5e6;font-size:50px;margin-bottom:10px"/>
                <p>
                  <a @click="showQRCode">生成签到二维码</a>
                </p>
              </div>
            </div>
          </div>
          <a-button @click="downImg()" type="primary" v-if="qrStatus === 'zhanshi'" style="margin-top:25px;margin-bottom:60px;">
            下载签到二维码
          </a-button>
        </div>

    </a-modal>

    <IconTemplate
        v-if="IconTemplateVisible"
        :visible="IconTemplateVisible"
        :listObj="IconTemplateObj"
        :funId="funcId"
        v-on:closeMainSystemIcons="closeMainIconTemplate"
        v-on:checkListIconTemplate="checkListIconTemplate"
        v-on:checkselectTemplate="checkSelectTemplate"
    />

    <SystemIcons
        v-if="systemIconsVisible"
        :visible="systemIconsVisible"
        v-on:closeMainSystemIcons="closeMainSystemIcons"
        v-on:checkListSystemIcons="checkListSystemIcons"
    ></SystemIcons>

    <!-- <ZCJFTempalte
       :visible="ZCJFVisible"
       v-on:closeZCJF="closeZCJF">
    </ZCJFTempalte> -->
  </div>
</template>

<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import {Sketch} from "vue-color";

import AddExpertModel from "@/utils/AddExpertModel";
import moment from "moment";
import IconTemplate from "@/components/meet/IconTemplate";
// import ZCJFTempalte from "@/components/meet/ZCJFTempalte"
import {
  CaseLiveFeature,
  CaseLiveFeatureOne,
  conventionCode,
  del_card,
  del_content_alphabetic_list,
  del_content_GraphicLinks_list,
  exportMeetingRegistrationList,
  function_upDown,
  get_content_alphabetic_list,
  get_content_GraphicLinks_list,
  meetingRegistrationList,
  getZCBMList,
  postZCBMList,
  post_content_alphabetic_list,
  post_content_card,
  post_content_GraphicLinks_list,
  put_content_GraphicLinks_move,
  put_content_picture,
  putSwitch,
  putUpdXFZC,
  putZCBMList,
  putZCBMListMove,
  delZCBMListMove,
  getZCBMPanel,
  getZCBMPanelExport,
  allowOperation,
  delAllNameList,
  get_isShowPay,
  oneClickFuncIconBgColor, getFuncType,getQrCode,
  getFuncZCJF,addFuncZCJF,editFuncZCJF,delFuncZCJF,moveFuncZCJF,changeisOpen,postSystemType,getSystemType,getZCJFResult
} from "../../../service/MedicalConference";
import { update, update_File, updates } from "../../../utils/update";

import { geTypeList } from "../../../service/medicalConference_api";
import { educationList, sexDate } from "@/utils/dateReturn";
import City from "@/assets/city.json";
import {
  bach_icon,
  delIcon, getlconCity,
  iconList,
  icontemp,
  postIcon, postReplacementIcon,
  putIcon,
} from "@/service/photoCommander";
import {postDocumentImg, postImgList} from "@/service/document_api";
import pictureDialog from "@/components/Material/pictureDialog";
import linkDialog from "@/components/Material/linkDialog";
const plainOptions = ["游客"];
import { codeList } from "@/utils/utilsList";
import ConventionCase from "@/components/meet/ConventionCase";
import {addReportPb, getAuthenticationInformation} from "@/service/authentication";
import MessageAlert from "@/components/Authentication/MessageAlert";
import axios from "axios";
import TcVod from "vod-js-sdk-v6";
import ServiceIntroduction from "@/components/PaidView/ServiceIntroduction";
import OrganizerSetup from "@/components/PaidView/OrganizerSetup";
import {getSignature} from "@/service/columnmanageapi";
import TechnicalSupport from "@/components/PaidView/TechnicalSupport";
import DataReport from "@/components/meet/DataReport";
import VueQr from 'vue-qr';

const columns = [
  {
    title: '图标',
    key: 'icon',
    dataIndex: 'icon',
    scopedSlots: { customRender: 'icon' },
  },{
    title: '名称',
    key: 'title',
    dataIndex: 'title',
    scopedSlots: { customRender: 'titles' },
  },{
    title: '类型',
    key: 'funcTitle',
    dataIndex: 'funcTitle',
    scopedSlots: { customRender: 'funcTitle' },
  },{
    title: '内容',
    scopedSlots: { customRender: 'content' },
  },{
    // title: '标题栏',
    align:'center',
    slots: { title: 'titleBar' },
    scopedSlots: { customRender: 'isOpenTopTitleBar' },
  },{
    title: '状态',
    dataIndex: 'isPublished',
    key: 'isPublished',
    scopedSlots: { customRender: 'isPublished' },
  },
  {
    title: '是否需要登录',
    key: 'isNeedLogin',
    dataIndex: 'isNeedLogin',
    scopedSlots: { customRender: 'isNeedLogin' },
  },
  // {
  //   title: '悬浮报名显示',
  //   key: 'isShowApplyBar',
  //   dataIndex: 'isShowApplyBar',
  //   scopedSlots: { customRender: 'isShowApplyBar' },
  // },
  // {
  //   title: '是否需要缴费',
  //   key: 'isNeedPay',
  //   dataIndex: 'isNeedPay',
  //   scopedSlots: { customRender: 'isNeedPay' },
  // },
  {
    title: '操作',
    fixed: 'right',
    width: 180,
    height:100,
    scopedSlots: { customRender: 'action' },
  },
];
const columnsModel = [{
    title: '名称',
    key: 'title',
    dataIndex: 'title',
    scopedSlots: { customRender: 'titles' },
  },
  {
    title: '操作',
    width: 180,
    height:100,
    scopedSlots: { customRender: 'action' },
  },
];


const editorConfig = { // JS 语法
  MENU_CONF: {}
};
const signature = '';
import headerConfig from "../../../service/api_header_config";
import {admin_icon} from "@/utils/myIcon";
import SystemIcons from "@/components/meet/SystemIcons";
import ZCJFTempalteVue from '../../../components/meet/ZCJFTempalte.vue';
const headerApi = headerConfig.api_header;
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF['uploadImage'] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    const user_info = window.localStorage.getItem("yichi_userInfo");
    let formData = new FormData()
    formData.append('file', file)
    // file 即选中的文件,自己实现上传，并得到图片 url alt href
    axios.post(headerApi+'/api/admin/common/update', formData,{
      headers: {
        'x-token': JSON.parse(user_info).id,
        'token':JSON.parse(user_info).token
      }
      }).then((res) => {
      if(res.data.code === 0){
        insertFn(res.data.data.url)// 最后插入图片
      }
    })
  }
}
// 修改 uploadVideo 菜单配置
editorConfig.MENU_CONF['uploadVideo'] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    const user_info = window.localStorage.getItem("yichi_userInfo");
    let mediaFile = file;
    // console.log(file)
    // axios.post(headerApi+'/api/admin/uploadVideo', mediaFile,{
    //   headers: {
    //     'token':JSON.parse(user_info).token
    //   }
    // }).then((res) => {
    //   if(res.data.code === 0){
    //     insertFn(res.data.data.url)// 最后插入图片
    //   }
    // })

    //获取签名
    getSignature().then((res) => {
      if (res.code === 0) {
        // console.log(res)
        const getSignature = () => {
          return res.data.signature;
        }
        const tcVod = new TcVod({
          getSignature: getSignature, //上传签名的函数
          // appId:1500024305 //appid
        })
        const uploader = tcVod.upload({
          mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
        })
        uploader.done().then(res => {
          insertFn(res.video.url)// 进行视频处理：转码和截图
        })
      }
    })
  }
}

export default {
  components: {
    DataReport,
    TechnicalSupport,
    OrganizerSetup,
    admin_con: admin_icon,
    ServiceIntroduction, AddExpertModel, pictureDialog, linkDialog,ConventionCase,MessageAlert,Editor, Toolbar,
    "sketch-picker": Sketch,
    IconTemplate,
    SystemIcons,
    VueQr,

  },


  data() {

    return {
      logoSrc:"",
      appSrc:"",
      app2Src:'',
      qrStatus:'',
      linkVisible:false,
      innerWidths:null,
      jszcHeight:0,
      clearVisible:false,
      editor: null,
      // html: '',

      html_form:{
        html: '',
        topBanner:'',
        isOpenTopTitleBar:1,
      },

      ZCBMForm:{
        topBanner:'',
        isOpenTopTitleBar:1,
      },

      toolbarConfig: { },
      editorConfig,
      mode: 'default', // or 'simple'


      columns,
      columnsModel,
      visibleAlert: false,
      visibleLink: false,
      ExcelMember: false,
      changeFile: "",
      listTel: "",
      columnsList: [
        {
          title: "序号",
          scopedSlots: { customRender: "serialNumber" },
        },
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "手机号",
          dataIndex: "telephone",
          key: "telephone",
        },
        {
          title: "身份证号",
          dataIndex: "idCard",
          key: "idCard",
        },
        {
          title: "性别",
          dataIndex: "sex",
          key: "sex",
          scopedSlots: { customRender: "sex" },
        },
        {
          title: "出生日期",
          dataIndex: "birthday",
          key: "birthday",
        },
        {
          title: "职称",
          dataIndex: "jobTitle",
          key: "jobTitle",
        },
        {
          title: "工作单位",
          dataIndex: "hospitalName",
          key: "hospitalName",
        },
        {
          title: "是否来自基层",
          dataIndex: "isBasicLevel",
          key: "isBasicLevel",
          scopedSlots: { customRender: "isBasicLevel" },
        },
        {
          title: "单位所在地",
          dataIndex: "province",
          key: "province",
        },
        {
          title: "报名时间",
          dataIndex: "createdTime",
          key: "createdTime",
          scopedSlots: { customRender: "createdTime" },
        },
      ],
      dataLists: [],
      listPageNo: 1,
      listpageSize: 10,
      listCount: 0,

      funcId: null,
      funcCode: null,
      // current_times,
      radioStyle: {
        width: "110px",
        display: "block",
        height: "35px",
        lineHeight: "35px",
        marginTop: "10px",
      },
      plainOptions,
      // plainOption,
      videoList_id: "",
      file_id_list_rest: "",
      // video_experts_list:[],
      experts_list_page_no: 1,
      selectTitle_experts_list: "",
      expertIdNew: "",
      name_input: "",
      selectTitle: "",
      live_Room: [],
      expertROLE_id: "",
      case_cardId: "",
      farther_id_addressId: "",
      ruleOneOrTwo: true,
      content_addCard_visible: false,
      // isRestFun:true, //默认可以修改
      titleDrawer: "新增",
      // code_selectData:[],
      code_selectData: codeList(),
      codeVal: "",
      content_addCard_form: {
        conventionId: "",
        contentType: " ",
        funcCode: '',
        title: "",
        icon: "",
        intro: "",
        iconTitleColor:'#333333',
        iconBgColor:'#ffffff',
        transparency:0.2,
        // 是否显示悬浮报名
        isShowApplyBar: 0,
        // 是否需要付费
        isNeedPay: 0,
        // 是否需要登录
        isNeedLogin: 0,
      },
      content_addCard_rules: {},
      content_addCard_rules1: {
        icon: [{ required: true, message: "请选择", trigger: "blur" }],
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        funcCode: [{ required: true, message: "请选择", trigger: "change" }],
      },
      content_addCard_rules2: {
        funcCode: [{ required: true, message: "请选择", trigger: "change" }],
      },

      ModifyLevel_id: "",
      farther_id: "",
      expertName_id: "",
      LiveBroadcast_list_page_no: 1,
      HTML_id: "",
      // linkVal:'',
      // titleTop:'',
      btnShow: "",
      dataList: [],
      cacheDataTwo: [],
      spinning: false,
      spin: false,
      labelCol: { span: 4 },
      labelColImg: { span: 4 },
      labelCol_addCard: { span: 9 },
      labelCol_LiveBroadcastCalendar_haiBao: { span: 6 },
      labelCol_LiveBroadcastCalendar: { span: 4 },
      labelCol_video: { span: 3 },
      wrapperCol: { span: 14 },
      card_list_content: [],
      //设置-----------------------------------------
      set_visible: false,
      payFees: 0,
      set_form: {
        isNeedPay: 0,
        isShowApplyBar: 0,
        isNeedLogin: 0,
      },
      //人数-------------------------------------------
      // per_OfThe_visible:false,
      // per_OfThe_input:'',
      //名单-学分注册-------------------------------------
      // List_credit_registration_visible:false,
      ViewingDurationCount: null,
      ViewingDurationDate: [],
      ViewingDurationDates: [],


      //内容——轮播广告---------------------------------
      // content_ByAdvertising_visible:false,
      content_ByAdvertising_form: {
        displayUrl: "",
        targetUrl: "",
      },
      content_ByAdvertising_rules: {
        displayUrl: [{ required: true, message: "请选择", trigger: "blur" }],
      },

      //内容--字幕文字通知----------------------------
      content_alphabetic_visible: false,
      content_alphabetic_font: "",
      content_alphabetic_columns: [
        {
          title: "内容",
          dataIndex: "content",
          key: "content",
          width: "25%",
        },
        {
          title: "操作",
          dataIndex: "system",
          key: "system",
          width: "25%",
          scopedSlots: { customRender: "system" },
        },
      ],
      content_alphabetic_data: [],
      //内容-图片展示----------------------------------
      picture_visible: false,
      picture_form: {
        isOpenTopTitleBar:1,
        topBanner:'',
        picture: [],
      },
      picture_rules: {
        picture: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      //内容-PDF展示-----------------------------------
      content_pdf_visible: false,
      content_pdf_val: "",
      percentAge: 0,
      content_pdf_name: "",
      content_pdf_list: [],
      //内容-图文链接展示--------------------------------
      content_GraphicLinks_visible: false,
      content_GraphicLinks_form: {
        targetUrl: "",
        title: "",
        displayUrl: "",
      },
      content_GraphicLinks_rules: {
        displayUrl: [{ required: true, message: "请上传", trigger: "blur" }],
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        targetUrl: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      content_GraphicLinks_columns: [
        {
          title: "图片",
          dataIndex: "displayUrl",
          key: "displayUrl",
          width: "25%",
          scopedSlots: { customRender: "displayUrl" },
        },
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
          width: "45%",
          scopedSlots: { customRender: "titleData" },
        },
        {
          title: "操作",
          dataIndex: "system",
          key: "system",
          width: "30%",
          scopedSlots: { customRender: "system" },
        },
      ],
      content_GraphicLinks_data: [],
      //内容-链接展示------------------------------------
      content_LinkShow_visible: false,
      content_LinkShow_dataVal: "",
      content_LinkShow_dataValIsTrue: false,
      //内容-直播日历展示---------------------------------
      LiveBroadcast_end: null,
      LiveBroadcast_start: null,

      richText_visible:false,


      content_LiveBroadcast_columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          width: "5%",
        },
        {
          title: "日程名称",
          dataIndex: "title",
          key: "title",
          width: "13%",
          ellipsis: true,
        },
        {
          title: "日期",
          dataIndex: "liveDate",
          key: "liveDate",
          width: "10%",
        },
        {
          title: "直播时间",
          dataIndex: "startTime",
          key: "startTime",
          width: "13%",
          scopedSlots: { customRender: "startTime" },
        },
        {
          title: "地址",
          scopedSlots: { customRender: "address" },
        },
        {
          title: "时长",
          dataIndex: "durationIsOpen",
          key: "durationIsOpen",
          scopedSlots: { customRender: "timeTime" },
        },
        {
          title: "回放",
          dataIndex: "replayIsOpen",
          key: "replayIsOpen",
          scopedSlots: { customRender: "replayIsOpen" },
        },
        {
          title: "云展厅",
          dataIndex: "isGallery",
          scopedSlots: { customRender: "CloudHall" },
        },
        {
          title: "企业鸣谢",
          dataIndex: "isEnterprise",
          scopedSlots: { customRender: "EnterpriseZone" },
        },
        {
          title: "开场图",
          dataIndex: "isBeginImage",
          scopedSlots: { customRender: "openingFigure" },
        },
        {
          title: "操作",
          dataIndex: "system",
          key: "system",
          width: "10%",
          scopedSlots: { customRender: "system" },
        },
      ],
      content_LiveBroadcast_data: [],
      content_LiveBroadcast_data_one: [],
      content_LiveBroadcast_data_total: 0,
      picture_id: "",
      HYTZ_id: "",
      set_id: "",
      //时长
      // content_RCYL_duration_visible:false,
      content_RCYL_duration_Bz: "",

      RCYL_duration_switch_isOpen_id: "",

      RCYL_Enterprise_zone_isOpen_id: "",

      opening_figure_id: "",

      PlaybackForm: {
        replay_is_open: 0,
      },
      // columnsPlayback,
      replay_is_open_switch_id: "",
      editingKey: "",
      content_PlaybackTableData_data_total: 0,
      content_PlaybackTableData_data_page_no: 0,
      content_PlaybackTableData_list_id: "",

      content_LiveBroadcast_rest_Btn_id: "",



      content_place_modal_visible: false,


      place_list_rest_id: "",
      place_list_routerId: "",
      dataListOne: [],
      addIsShow: false,
      // videoList:[],
      videoListId: "",
      // startList:[],
      endList: [],


      conferenceLiveBroadcast_pageNo: 1,
      conferenceLiveBroadcast_title: "",


      //  学分配置
      configuration_visible: false,
      codeId: null,
      listId: null,
      configuration_form: {
        remark: "",
        // credit:null,
      },
      configurationRules: {
        remark: [{ required: true, message: "请输入", trigger: "blur" }],
        // credit: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      // radioGroup:'voluntarily',
      indicator: (
        <a-icon type="loading" style="font-size: 18px;color:#45a5e6" spin />
      ),
      // handMovementLoading:false,

      expertForm: {
        formShow: false,
        formType: undefined,
      },
      liveMeetId: null,

      sexList: sexDate(),
      educationList: educationList(),
      City: City,
      jobTitleList: [],
      provincePtions: [],

      hosNamePageNo: 1,
      hosName: "",

      schoolNamePageNo: 1,
      schoolName: "",
      schoolListData: [], //学校列表

      // hospitalOfficesList:[],

      addPersonDisabled: true, //初始状态下默认为不能编辑状态

      visible: false,
      selectId: ["vector"],
      templateList: [
        {
          id: "vector",
          title: "矢量图标",
        },
        // {
        //   id:'2',
        //   title:'我的上传',
        // }
      ],
      cityList:[],
      funList: [],
      isImgSty: "imgTitle",
      iconName: "",
      // isImgSty:'img',
      funUp: {
        funName: "",
        funImg: "",
      },
      myFunList: [],
      curPage: 1,
      pageNo: 1,
      pageSize: 5,
      template: 5,
      iconDataList: [],
      iconDataCount: 0,
      iconTemplate: 0,
      iconTemplateId: null,
      downLoadFile: "",
      createdById: null,
      myFunName: "",
      //最终选择的图片
      selectImg: "",
      selectIndex: null,
      selectMyIndex: null,

      visiblePic: false,
      typePic: null,
      pInfo: false,
      picList: [],
      liveCoverPics:'',
      homeCoverImage:'',
      userInfoList:{},

      bannerCountDown:{
        curStartTime: '',
        day:'0',
        hour:'00',
        min:'00',
        second:'00',
      },
      endDate:'',
      beginDate:'',

      ServiceVisible:false,
      serviceCode:'',

      ServiceTitle:'服务介绍',
      isWhos:null,
      objContent:{},

      OrganizerVisible:false,

      TechnicalVisible:false,

      ProductVisible:false,

      payVisible:false,

      organizerList:[],

      organizerIsOpen:false,



      ZCBM_visible:false,

      ZCBM_add_visible:false,

      columnsZCBM :[
        {
          title: '类型',
          key: 'type',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
        },{
          title: '标题',
          key: 'title',
          dataIndex: 'title',
        },{
          title: '是否必选',
          key: 'isMust',
          dataIndex: 'isMust',
          scopedSlots: { customRender: 'isMust' },
        },
        {
          title: '排序',
          scopedSlots: { customRender: 'rank' },
        },{

          title: '创建时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
        }, {
          title: '操作',
          fixed: 'right',
          width: 180,
          height:100,
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataZCBMList:[],

      ZCBMTitle:'新增',

      ZCBM_form:{
        type:'text',
        title:'',
        isMust:false,
        option:[
          {
            title:'',
          }
        ],
        tips:'',
      },
      ZCBM_rules:{
        type: [{ required: true, message: "请选择", trigger: "change" }],
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        option: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      codeListZCBM:[
        {
          id:1,
          name:'填空',
          type:'text',
        },
        {
          id:2,
          name:'多选',
          type:'check',
        },
        {
          id:3,
          name:'单选',
          type:'radio',
        },
        {
          id:4,
          name:'下拉单选',
          type:'dropdown_radio',
        },
        {
          id:5,
          name:'多行填空',
          type:'multiline',
        },
      ],
      columnsZCBMList :[],
      dataListZCBMList:[],

      ZCBM_visible_list:false,
      loadingZCBM:false,

      ZCBM_list_StimeVal:null,
      ZCBM_list_EtimeVal:null,
      pagination:{
        total:0,
        current:1,
        pageSize:10
      },
      ZCBMListId:null,
      orderNumber:'',
      tempUrl:'',

      isKefu:null,

      setRemarksVisible:false,
      remarkVal:'',
      remarkLocation:'up',
      setRemark_dataValIsTrue:false,

      iconTitleColor:'#333333',
      iconTitleColorNoBtn:'#333333',

      iconBgColor:'#ffffff',

      test:'rgba(255,255,255, .5)',

      visibleDataReport:false,

      liveInfo:{},

      selectNumber:2,

      liveCoverPicsShow:false,

      setClickVisible:false,

      setSortVisible:false,

      dataListOpen:[],

      funIconColor:'#ffffff',
      funTransparency:0.2,

      setTitleBar:false,
      setTitleBar_form:{
        title:'',
        isOpenTopTitleBar:0,
        topBanner:'',
      },
      funListVisible:false,
      funList_form: {
        conventionId: "",
        funcCode: '',
        title: "",
        icon: "https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/ca02df7b-f7c5-48df-949c-da19cbd515eb.png",
        intro: "",
        iconTitleColor:'#333333',
        iconBgColor:'#ffffff',
        transparency:0.2,
        // 是否显示悬浮报名
        isShowApplyBar: 0,
        // 是否需要付费
        isNeedPay: 0,
        // 是否需要登录
        isNeedLogin: 0,
      },
      funList_rules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      funModelList:[],
      IconTemplateVisible:false,
      IconTemplateObj:{},
      systemIconsVisible:false,

      isCleanUp:1,
      ZCJF_visible:false,
      dataZCJFList:[],
      columnsZCJF:[
        {
          title: '类型',
          key: 'fieldType',
          dataIndex: 'fieldType',
          scopedSlots: { customRender: 'fieldType' },
        },{
          title: '标题',
          key: 'fieldLabel',
          dataIndex: 'fieldLabel',
          scopedSlots: { customRender: 'fieldLabel' },
        },{
          title: '是否必选',
          key: 'isRequired',
          dataIndex: 'isRequired',
          scopedSlots: { customRender: 'isRequired' },
        },{
          title: '排序',
          scopedSlots: { customRender: 'rank' },
        },
        // ,{
        //   title: '状态',
        //   scopedSlots: { customRender: 'isOpen' },
        // },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
        }, {
          title: '操作',
          fixed: 'right',
          width: 180,
          height:100,
          scopedSlots: { customRender: 'action' },
        },
      ],
       codeListZCJF:[
        {
          id:1,
          name:'填空',
          type:'text',
        },
        {
          id:2,
          name:'多选',
          type:'check',
        },
        {
          id:3,
          name:'单选',
          type:'radio',
        },
        {
          id:4,
          name:'下拉单选',
          type:'dropdown_radio',
        },
        {
          id:5,
          name:'多行填空',
          type:'multiline',
        },
      ],
      ZCJFTitle:'新增',
      ZCJF_add_visible:false,
      ZCJFId:'',
      ZCJF_form:{
        type:'text',
        title:'',
        isMust:false,
        option:[
          {
            name:'',
          }
        ],
        tips:'',},
     ZCJF_rules:{
        type: [{ required: true, message: "请选择", trigger: "change" }],
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        option: [{ required: true, message: "请输入", trigger: "blur" }],
      },

      dataId:"",
      funcCode1:'',
      // moveVisible:false,
      // moveNum:"",
      ZCJFADDType:0,
      ZCJFSystemList:[],
      columnsZCJFSystems:[
       {
          title: '全部',
          key: 'fieldLabel',
          dataIndex: 'fieldLabel',

        },
      ],
      selectedRows:[],
      selectedRowKeys:[],
    }

  },

  mounted() {
    this.getFeatureList();
    this.getLists();
    this.getFunListNew();
  },
  created() {
    const userInfo = JSON.parse(window.localStorage.getItem("yichi_userInfo"));
    this.isKefu = userInfo.isKefu;

    this.innerWidths = window.innerWidth; //屏幕宽度

    // this.$nextTick(()=>{
    //   this.jszcHeight = this.$refs.jszcRefs.clientHeight + 10;
    //   console.log(this.jszcHeight)
    // })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const user_info = window.localStorage.getItem("yichi_userInfo");
    this.createdById = JSON.parse(user_info).id;


    // console.log('截取字符串',this.extractStringBetween('https://meet.yichimeeting.com/conferenceEntrance?conferenceCode=y0dslk'))
    // this.getFeatureList();
    // this.getLists();
    // this.getCaseOneList()
    // this.getPCH()
    // this.getHospitalOfficesList()
    // this.getJobTitleList(this.personnelListForm.type)
  },
  computed: {
    meetCode() {
      return this.$store.state.meetCode;
    },
    isShowIconTitle() {
      return this.$store.state.isShowIconTitle;
    },
    // isOpenCountdown(){
    //   return this.$store.state.isOpenCountdown;
    // }
    isShowIssueBtn() {
      return this.$store.state.isShowIssueBtn;
    },
    //小屏幕
    itemArrayX() {
      //状态为0的不显示
      let listObj = [];
      this.dataList.forEach((list,index)=>{
        if(list.isPublished===1){
          listObj.push(list);
        }
      })
      let lgh = listObj.length;

      if (lgh > 0) {
        if (lgh <= 4) {
          return listObj.map((n, i) => {
            // n.class = 'mid_image'
            n.width = '160px';
            n.height = '75px';
            n.marginTop = 15;
            n.marginLeft = 57.5;
            return n
          })
        }
        else if(lgh === 5){
          return listObj.map((n, i) => {
            if (i === 0) {
              // n.class = 'big_image'
              n.width = '160px';
              n.height = '160px';
              n.marginTop = 60;
              n.marginLeft = 57.5;
            }else {
              n.width = '75px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 15;
            }
            return n
          })
        }
        else if(lgh === 6){
          return listObj.map((n, i) => {
            if (i === 0 || i === 3) {
              // n.class = 'mid_image'
              n.width = '160px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 57.5;
            }else {
              n.width = '75px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 15;
            }
            return n
          })
        }
        else if(lgh === 7){
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '160px';
              n.height = '155px';
              n.marginTop = 60;
              n.marginLeft = 57.5;
            }
            else if(i === 1 || i === 2 || i === 3 || i === 4){
              n.width = '75px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 15;
            }
            else {
              n.width = '160px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 57.5;
            }
            return n
          })
        }
        else if(lgh === 8){
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '160px';
              n.height = '155px';
              n.marginTop = 60;
              n.marginLeft = 57.5;
            }
            else if(i === 7){
              n.width = '160px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 57.5;
            }
            else {
              n.width = '75px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 15;
            }
            return n
          })
        }
        else if(lgh === 9){
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '160px';
              n.height = '155px';
              n.marginTop = 60;
              n.marginLeft = 57.5;
            }
            else {
              n.width = '75px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 15;
            }
            return n
          })
        }
        else if(lgh === 10){
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '160px';
              n.height = '155px';
              n.marginTop = 60;
              n.marginLeft = 57.5;
            }else if(i === 7 || i === 8 || i === 9){
              n.width = '160px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 57.5;
            }
            else {
              n.width = '75px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 15;
            }
            return n
          })
        }
        else if(lgh === 11){
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '160px';
              n.height = '155px';
              n.marginTop = 60;
              n.marginLeft = 57.5;
            }else if(i === 7 || i === 8){
              n.width = '160px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 57.5;
            }
            else {
              n.width = '75px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 15;
            }
            return n
          })
        }
        else {
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '160px';
              n.height = '155px';
              n.marginTop = 60;
              n.marginLeft = 57.5;
            }else {
              n.width = '75px';
              n.height = '75px';
              n.marginTop = 15;
              n.marginLeft = 15;
            }
            return n
          })
        }
      }
    },
    //大屏幕
    itemArrayD() {
      //状态为0的不显示
      let listObj = [];
      this.dataList.forEach((list,index)=>{
        if(list.isPublished===1){
          listObj.push(list);
        }
      })
      let lgh = listObj.length;
      if (lgh > 0) {
        if (lgh <= 4) {
          return listObj.map((n, i) => {
            // n.class = 'mid_image'
            n.width = '175px';
            n.height = '85px';
            n.marginTop = 20;
            n.marginLeft = 65;
            return n
          })
        }
        else if(lgh === 5){
          return listObj.map((n, i) => {
            if (i === 0) {
              // n.class = 'big_image'
              n.width = '175px';
              n.height = '180px';
              n.marginTop = 65;
              n.marginLeft = 65;
            }else {
              n.width = '85px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 20;
            }
            return n
          })
        }
        else if(lgh === 6){
          return listObj.map((n, i) => {
            if (i === 0 || i === 3) {
              // n.class = 'mid_image'
              n.width = '175px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 65;
            }else {
              n.width = '85px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 20;
            }
            return n
          })
        }
        else if(lgh === 7){
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '175px';
              n.height = '175px';
              n.marginTop = 65;
              n.marginLeft = 65;
            }
            else if(i === 1 || i === 2 || i === 3 || i === 4){
              n.width = '85px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 20;
            }
            else if(i === 6){
              n.width = '180px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 67.5;
            }
            else {
              n.width = '175px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 65;
            }
            return n
          })
        }
        else if(lgh === 8){
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '180px';
              n.height = '175px';
              n.marginTop = 65;
              n.marginLeft = 67.5;
            }
            else if(i === 7){
              n.width = '180px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 67.5;
            }
            else {
              n.width = '85px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 20;
            }
            return n
          })
        }
        else if(lgh === 9){
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '180px';
              n.height = '175px';
              n.marginTop = 65;
              n.marginLeft = 67.5;
            }
            else {
              n.width = '85px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 20;
            }
            return n
          })
        }
        else if(lgh === 10){
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '180px';
              n.height = '175px';
              n.marginTop = 65;
              n.marginLeft = 67.5;
            }else if(i === 7 || i === 8 || i === 9){
              n.width = '180px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 67.5;
            }
            else {
              n.width = '85px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 20;
            }
            return n
          })
        }
        else if(lgh === 11){
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '180px';
              n.height = '175px';
              n.marginTop = 67.5;
              n.marginLeft = 65;
            }else if(i === 7 || i === 8){
              n.width = '180px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 67.5;
            }
            else {
              n.width = '85px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 20;
            }
            return n
          })
        }
        else {
          return listObj.map((n, i) => {
            if (i === 0) {
              n.width = '180px';
              n.height = '180px';
              n.marginTop = 67.5;
              n.marginLeft = 67.5;
            }else {
              n.width = '85px';
              n.height = '85px';
              n.marginTop = 20;
              n.marginLeft = 20;
            }
            return n
          })
        }
      }
    },
    rowSelection() {
       const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys,selectedRows) => {
          this.selectedRows=selectedRows
          this.selectedRowKeys=selectedRowKeys
        },

      }

  }
  },
  watch: {
    // newArr(newVal){
    //   this.content_addCard_form.intro = newVal
    // },
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    base64ToBlob(dataurl) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //获取功能模块
    async getFunListNew() {
      const response = await getFuncType();
      if (response.code === 200) {
        this.funModelList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    async checkListSystemIcons(val) {
      // 打印选择的模板id,并进行图标模板修改
      if (val.iconTemplateId) {
        let data = {
          "conventionCode": this.meetCode, //会议code
          "iconTempId": val.iconTemplateId, //图标模板id
          "isCleanUp":this.isCleanUp //在未关联模板时传值0，也就是在第一次关联图标时传值0，其他传1
        }
        const response = await postReplacementIcon(data);
        if (response.code === 200) {
          this.$message.success("修改成功！");
          this.$refs.ConventionCase.getList();
          //调用父级类方法，刷新
          await this.getLists();
          await this.getFeatureList();
        } else {
          this.$message.warning(response.message);
        }
      }
    },
    closeMainSystemIcons(val) {
      this.systemIconsVisible = val;
    },
    openIconTemplate(row){
      this.funcId = row.id;
      this.IconTemplateObj = this.liveInfo;
      if(this.liveInfo.iconTempId){ //有图标模板id的情况下可以打开模板且更换模板
        //选择图标模板中一张图标弹窗
        this.IconTemplateVisible = true;
      }else { //没id，说明没有关联模板还，弹出关联图标模板对话框
        this.isCleanUp = 0;
        this.systemIconsVisible = true;
      }
    },
    closeMainIconTemplate(val){
      this.IconTemplateVisible = val;
    },
    checkSelectTemplate(){
      this.isCleanUp = 1;
      this.systemIconsVisible = true;
    },
    async checkListIconTemplate(val) {
      let dataIcon = {
        icon: val.url
      }
      await this.ZCBMBtn(dataIcon);
      await this.getFeatureList();
    },
    //新建修改选择功能类型
    funCodeValChange(val){
      this.content_addCard_form.funcCode = val;
    },
    funListVisible_cancel(){
      this.funListVisible = false;
      this.content_addCard_form = {
        conventionId: "",
        contentType: " ",
        funcCode: '',
        title: "",
        icon: "",
        intro: "",
        iconTitleColor:'#333333',
        iconBgColor:'#ffffff',
        transparency:0.2,
        // 是否显示悬浮报名
        isShowApplyBar: 0,
        // 是否需要付费
        isNeedPay: 0,
        // 是否需要登录
        isNeedLogin: 0,
      }
    },
    async funListVisible_Ok() {
      let form = this.content_addCard_form;
      if (!form.title) {
        this.$message.warning("请输入功能名称！")
        return
      }
      if (!form.funcCode) {
        this.$message.warning("请选择功能类型！")
        return
      }
      if(form.funcCode === 'ZCJF'){
        form.isNeedLogin = 1
      }
      if (this.titleDrawer === '新增') {
        //新增
        const response = await post_content_card(form);
        if (response.code === 0) {
          this.$message.success("新增成功！");
          this.funListVisible_cancel();
          //  刷新列表
          await this.getFeatureList();
        } else {
          this.$message.warning("失败" + response.message);
        }
      } else {
        //  修改
        const response = await put_content_picture(this.case_cardId, form);
        if (response.code === 0) {
          this.$message.success("修改成功！");
          this.funListVisible_cancel();
          //  刷新列表
          await this.getFeatureList();
        } else {
          this.$message.warning("失败" + response.message);
        }
      }
    },
    closeMainDataReport(val) {
      this.visibleDataReport = val;
    },
    async getlconCityList() {
      const response = await getlconCity();
      if (response.code === 200) {
        this.cityList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    //十六进制颜色转换为rgba
    hexToRgb(hex,a) {
      // 移除十六进制颜色中的'#'
      let sanitizedHex = hex.replace('#', '');
      let TMD = a ? a : 0.2;
      // 解析红、绿、蓝值
      let r = parseInt(sanitizedHex.substring(0, 2), 16);
      let g = parseInt(sanitizedHex.substring(2, 4), 16);
      let b = parseInt(sanitizedHex.substring(4, 6), 16);

      let result = 'rgba('+r+','+g+','+b+', '+TMD+')'
      return result;
    },
    //摘取的是/和?中间的字符串
    //实例：https://meet.yichimeeting.com/conference?conferenceCode=y0dslk
    //取值conference
    extractStringBetween(url) {
      // 找到第三个 '/' 的位置
      const firstSlashIndex = url.indexOf('/');
      const secondSlashIndex = url.indexOf('/', firstSlashIndex + 1);
      const thirdSlashIndex = url.indexOf('/', secondSlashIndex + 1);

      // 找到第一个 '?' 的位置
      const questionMarkIndex = url.indexOf('?');

      // 如果找到了三个 '/' 和 '?'，则提取中间的部分
      if (firstSlashIndex !== -1 && secondSlashIndex !== -1 && thirdSlashIndex !== -1 && questionMarkIndex !== -1) {
        return url.substring(thirdSlashIndex + 1, questionMarkIndex);
      } else {
        return null; // 如果未找到任意一个 '/', '?' 或者 '/' 在 '?' 之后，则返回 null 或者适合的默认值
      }
    },
    //会议开关
    //会议结束或者开关关闭时不显示倒计时
    isOpenCountdown(startTime,endTime){
      if(this.$store.state.isOpenCountdown === 1){
        //开关开启
        // 会议有没有结束
        if(this.checkExpiration(endTime)){
          //已结束
          return 0;
        }else {
          if(this.checkIfNotStarted(startTime)){
            return 1;
          }else {
            return 0;
          }
        }
      }else {
        return 0; //关闭状态下不显示倒计时
      }
    },

    //判断时间是否过期
    checkExpiration(time){
      // 获取当前时间戳
      const currentTime = new Date().getTime();
      let inputTime = time + ' 23:59:59';

      // 获取输入时间的时间戳
      const inputTimestamp = new Date(inputTime).getTime();

      // 检查输入时间是否早于当前时间
      let isExpired = inputTimestamp <= currentTime;

      return isExpired;
    },

    checkIfNotStarted(startTime){
      // 获取当前时间戳
      const currentTime = new Date().getTime();
      // 获取输入的开始时间的时间戳
      const startTimestamp = new Date(startTime).getTime();
      // 开始时间是否晚于当前时间
      return  startTimestamp > currentTime;
    },

    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    // 倒计时
    countTime () {
      // 获取当前时间
      let date = new Date()
      let now = date.getTime()
      // 设置截止时间
      let endDate = new Date(this.bannerCountDown.curStartTime) // this.curStartTime需要倒计时的日期
      let end = endDate.getTime()
      // 时间差
      let leftTime = end - now
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        let day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        this.bannerCountDown.day = day < 10 ? '0' + day : day
        // 时
        let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
        this.bannerCountDown.hour = h < 10 ? '0' + h : h
        // 分
        let m = Math.floor(leftTime / 1000 / 60 % 60)
        this.bannerCountDown.min = m < 10 ? '0' + m : m
        // 秒
        let s = Math.floor(leftTime / 1000 % 60)
        this.bannerCountDown.second = s < 10 ? '0' + s : s
      } else {
        this.bannerCountDown.day = '00'
        this.bannerCountDown.hour = '00'
        this.bannerCountDown.min = '00'
        this.bannerCountDown.second = '00'
      }
      // 等于0的时候不调用
      if (Number(this.bannerCountDown.hour) === 0 && Number(this.bannerCountDown.day) === 0 && Number(this.bannerCountDown.min) === 0 && Number(this.bannerCountDown.second) === 0) {
        return
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000)
      }
    },
    async switchCountDownBtn(isOpen,beginDate) {

      if(this.checkIfNotStarted(beginDate)){
        let data = {
          conferenceCode: this.meetCode, //会议编码
          isOpenCountdown: isOpen
        };
        const response = await putSwitch(data);
        if (response.code === 0) {
          this.$store.dispatch("isOpenCountdown", isOpen? 1:0);
          this.$message.success("操作成功！");
          await this.getFeatureList();
        } else {
          this.$store.dispatch("isOpenCountdown", isOpen ?0:1);
          this.$message.warning(response.message);
        }
      }else {
        this.$message.warning("会议已开始，倒计时无法开启！");
      }


    },
    closeMainAlert(val) {
      this.visibleAlert = val;
    },
    //获取数据
    async getLists() {
      const response = await conventionCode(this.meetCode)
      if(response.code === 0){
        this.liveInfo = response.data;

        this.iconTitleColor = response.data.iconTitleColor ? response.data.iconTitleColor :'#333333';
        this.bannerCountDown.curStartTime = response.data.beginDate+ ' 00:00:00';
        this.liveCoverPics = response.data.coverPics[0];
        this.homeCoverImage = response.data.homeCoverImage;
        this.endDate = response.data.endDate;
        this.beginDate = response.data.beginDate;
        //会议是否发布
        this.isPublished = response.data.isPublished;
        this.tempUrl = response.data.tempUrl;
        this.organizerIsOpen = response.data.isOpenOrganizer;
        this.organizerList = response.data.organizer ? response.data.organizer:[];
        this.countTime();

        // 当this.isCleanUp = 0时，时在功能列表中的选择图片打开的更换图标模板，所以，更换图标模板后需要打开图标模板（选择单个图标弹窗）
        if(this.isCleanUp === 0){
          this.IconTemplateObj = response.data;
          this.IconTemplateVisible = true;
        }
        this.isCleanUp = 1;
      }
    },
    closeMainLink(val) {
      this.visibleLink = val;
    },
    checkListLink(val) {
      let list = val;
      this.content_GraphicLinks_form.targetUrl = list[0].link;
      this.content_GraphicLinks_form.displayUrl = list[0].imageUrl;
      this.content_GraphicLinks_form.title = list[0].name;
    },
    openLinkModel() {
      this.visibleLink = true;
    },
    async switchEditBtn(isOpen) {
      let data = {
        conferenceCode: this.meetCode, //会议编码
        isShowIconTitle: isOpen,
      };
      const response = await putSwitch(data);
      if (response.code === 0) {
        this.$store.dispatch("isShowIconTitle", isOpen? 1:0);
        this.$message.success("操作成功！");
        await this.getFeatureList();
      } else {
        this.$store.dispatch("isShowIconTitle", isOpen ?0:1);
        this.$message.warning(response.message);
      }
    },
    excelClose() {
      this.ExcelMember = false;
      this.listTel = "";
      this.listPageNo = 1;
      this.listpageSize = 10;
    },
    inquireBtn() {
      this.listPageNo = 1;
      this.listBtn();
    },
    async DownloadTemplate() {
      let data = {
        conferenceCode: this.meetCode,
        ExelName: "人员名单"+new Date().getTime(),
      };
      await exportMeetingRegistrationList(data);
    },
    templateChange(pageNo, pageSize) {
      this.listPageNo = pageNo;
      this.listBtn();
    },
    templateChangeSize(pageNo, pageSize) {
      this.listpageSize = pageSize;
      this.listBtn();
    },
    async checkList(val) {
      // this.picList = val[0].url;
      this.picList = val;
      let type = this.typePic;
      if (type === "功能图标") {
        this.content_addCard_form.icon = this.picList[0].url;
        this.getList();
      } else if (type === "云展厅") {
        this.content_GraphicLinks_form.displayUrl = this.picList[0].url;
      } else if (type === "图片展示") {
        for (let i = 0; i < this.picList.length; i++) {
          this.picture_form.picture.push(this.picList[i].url);
        }
      } else if (type === "顶部banner") {
        this.picture_form.topBanner = this.picList[0].url;
      } else if (type === "banner图（非必选）") {
        this.setTitleBar_form.topBanner = this.picList[0].url;
      } else if (type === "HTML顶部banner") {
        this.html_form.topBanner = this.picList[0].url;
      } else if (type === "ZCBM顶部banner") {
        this.ZCBMForm.topBanner = this.picList[0].url;
        let dataBanner = {
          topBanner: this.ZCBMForm.topBanner,
        };
        this.ZCBMBtn(dataBanner);
      } else if (type === "reminderPic") {
        this.reminderPic = this.picList[0].url;
        let reminderData = {
          "conferenceCode": this.meetCode,//会议
          coverPics: [this.reminderPic]
        };
        this.editApi(reminderData);
      } else if (type === "列表功能图标") {
        this.content_addCard_form.icon = this.picList[0].url;
        let dataIcon = {
          icon: this.content_addCard_form.icon
        }
        await this.ZCBMBtn(dataIcon);
        await this.getFeatureList();
        this.content_addCard_form.icon = '';
      }
    },
    delZCBMImg(){
      this.ZCBMForm.topBanner = '';
      let data = {
        topBanner: this.ZCBMForm.topBanner,
      };
      this.ZCBMBtn(data);
    },
    async ZCBMBtn(data) {
      const response = await put_content_picture(this.funcId, data);
      if (response.code === 0) {
        this.$message.success("操作成功");
      } else {
        this.$message.warning(response.message);
      }
    },
    closeMain(val) {
      this.visiblePic = val;
    },
    async colorRest(val) {
      this.iconTitleColor = val.hex;

      let data = {
        conferenceCode: this.meetCode, //会议编码
        iconTitleColor: this.iconTitleColor,
      };
      const response = await putSwitch(data);
      if (response.code === 0) {
        // this.$message.success("操作成功！");
      } else {
        this.$message.warning(response.message);
      }
    },
    // 修改接口
    async editApi(data,type) {
      const response = await putSwitch(data)
      if (response.code === 0) {
        //  刷新
        await this.getLists();
      } else {
        this.$message.warning(response.message)
      }
    },
    //新增
    addCard() {
      this.content_addCard_form.conventionId = this.$route.query.id;
      // this.content_addCard_rules = this.content_addCard_rules1;
      // this.ruleOneOrTwo = true;
      this.funListVisible = true;
      // this.isRestFun = true;
      this.titleDrawer = "新增";
      // this.get_caseLive_typeList();
    },
    restBtn(row) {
      // this.get_caseLive_typeList()
      // if (row.funcCode === "LBGG" || row.funcCode === "ZMTZ") {
      //   //选择轮播广告和文字字幕时，无需填写其他内容（其他内容隐藏）
      //   this.content_addCard_rules = this.content_addCard_rules2;
      //   this.ruleOneOrTwo = false;
      // } else {
      //   this.content_addCard_rules = this.content_addCard_rules1;
      //   this.ruleOneOrTwo = true;
      // }
      // this.content_addCard_visible = true;
      this.funListVisible = true;
      // if(row.funcTitle){
      // //  有值禁止修改
      //   this.isRestFun = true;
      // }else {
      //   //可以修改
      //   this.isRestFun = false;
      // }
      this.titleDrawer = "修改";
      this.case_cardId = row.id;
      let form = this.content_addCard_form;
      form.title = row.title;
      form.icon = row.icon;
      form.iconBgColor = row.iconBgColor ? row.iconBgColor : '#ffffff';
      form.iconTitleColor = row.iconTitleColor ? row.iconTitleColor : '#333333';
      form.transparency = row.transparency ? row.transparency : 0.2;
      form.funcCode = row.funcCode;

      // //判断将选择的code值在功能列表中比对，回显name
      // let list = this.code_selectData;
      //
      // for (let i = 0; i < list.length; i++) {
      //   if (list[i].code === row.funcCode) {
      //     form.funcCode = list[i].name;
      //     this.codeVal = list[i].code;
      //
      //     return;
      //   }
      // }
      // if (!form.funcCode) {
      //   form.funcCode = undefined;
      // }
    },
    //获取会议列表类型列表
    async get_caseLive_typeList() {
      const response = await geTypeList();
      if (response.code === 0) {
        this.code_selectData = response.data.types;
      } else {
        this.$message.warning("失败" + response.message);
      }
    },
    content_addCard_form_handleChange(funcCode, list) {
      //判断isTitle为true时需要截取字符串
      if (this.titleDrawer === "新增") {
        this.content_addCard_form.title = list.name;
      }
      //判断，当列表中的isTrue为true时则表明这是重复的功能类型,code需要将字符串的最后一位删除掉
      if (list.isTrue) {
        this.codeVal = funcCode.substring(0, funcCode.length - 1);
      } else {
        this.codeVal = funcCode;
      }
      // if(list.code === 'LBGG' || list.code === 'ZMTZ'){
      //   //选择轮播广告和文字字幕时，无需填写其他内容（其他内容隐藏）
      //   this.content_addCard_rules = this.content_addCard_rules2;
      //   this.ruleOneOrTwo = false;
      // }else {
      //   this.content_addCard_rules = this.content_addCard_rules1;
      //   this.ruleOneOrTwo = true;
      // }
    },
    async content_add_image() {
      let inputDOM = this.$refs.detailCoverUrl_addICON.files[0];
      //判断图片大小
      if (inputDOM.size / 1024 / 1024 < 1) {
        const image = await update(inputDOM);
        if (image.code === 0) {
          this.content_addCard_form.icon = image.data.url;
          this.$message.success("上传成功");
        } else {
          this.$message.warning("上传失败");
        }
      } else {
        this.$message.warning("请上传小于1M的图片！");
      }
      document.getElementById("uploadFile_add").value = null;
    },
    addSubmit() {
      this.$refs.content_add_ruleForm.validate(async (valid) => {
        if (valid) {
          let form = this.content_addCard_form;
          this.content_addCard_form.funcCode = this.codeVal;
          if (form.conventionId !== "") {
            //新增
            const response = await post_content_card(form);
            if (response.code === 0) {
              this.$message.success("新增成功！");
              //  关闭
              this.content_addCard_visible = false;
              this.content_addCard_form.iconBgColor = '#ffffff';
              this.content_addCard_form.transparency = 1;
              this.codeVal = "";
              //  刷新列表
              await this.getFeatureList();
            } else {
              this.$message.warning("失败" + response.message);
            }
          } else {
            //  修改
            const response = await put_content_picture(this.case_cardId, form);
            if (response.code === 0) {
              this.$message.success("修改成功！");
              //  关闭
              this.content_addCard_visible = false;
              this.content_addCard_form.iconBgColor = '#fffffff';
              this.content_addCard_form.transparency = 1;
              //  刷新列表
              await this.getFeatureList();
            } else {
              this.$message.warning("失败" + response.message);
            }
          }
          //  清空
          this.closeAddOrRest();
        } else {
          return false;
        }
      });
    },
    colorAdd(val){
      this.content_addCard_form.iconBgColor = val.hex;
      this.content_addCard_form.transparency = val.a;
    },
    colorIconBgColor(val){
      this.content_addCard_form.iconBgColor = val.hex;
      this.content_addCard_form.transparency = val.a;
    },
    colorIconTitleColor(val){
      this.content_addCard_form.iconTitleColor = val.hex;
    },
    closeAddOrRest() {
      this.content_addCard_visible = false;
      this.content_addCard_form.iconBgColor = '#f5f5f5'
      this.$refs.content_add_ruleForm.resetFields();
      this.content_addCard_form.title = "";
      this.content_addCard_form.conventionId = "";
      this.content_addCard_form.funcCode = undefined;
      this.content_addCard_form.icon = "";
      // this.content_addCard_form.isShowIconTitle = 0;
      this.selectImg = "";
      this.codeVal = "";
    },
    //获取列表
    async getFeatureList() {
      this.spinning = true;
      const response = await CaseLiveFeature(this.$route.query.id);
      if (response.code === 0) {
        this.dataList = response.data;
      }
      this.spinning = false;
    },
    //移动公用
    async movement(id, data) {
      let action = data;
      const response = await function_upDown(id, action);
      if (response.code === 0) {
        this.$message.success("操作成功！");
        //  刷新列表
        await this.getOpenFeatureList();
        await this.getFeatureList();
      } else {
        this.$message.warning(response.message);
      }
    },
    //上移
    moveUp(id) {
      let action = "down";
      this.movement(id, action);
    },
    //下移
    shiftDown(id) {
      let action = "up";
      this.movement(id, action);
    },
    //获取单个功能列表内容
    async getFeatureListOne(id) {
      this.spinning = true;
      const response = await CaseLiveFeatureOne(id);
      if (response.code === 0) {
        this.dataListOne = response.data;
      } else {
        this.$message.warning("失败!" + response.message);
      }
      this.spinning = false;
    },
    async functionModuleSwitch(row, isOpen) {
      let data = {
        isPublished: isOpen,
      };
      const response = await put_content_picture(row.id, data);
      if (response.code === 0) {
        //  刷新列表
        await this.getFeatureList();
      } else {
        this.$message.warning("失败!" + response.message);
      }
    },
    //删除会议功能
    async delCard(row) {
      const response = await del_card(row.id);
      if (response.code === 0) {
        this.$message.success("删除成功！");
        //  刷新列表
        await this.getFeatureList();
      } else {
        this.$message.warning("失败!" + response.message);
      }
    },
    //设置----------------------------------------------------------------------
    async set_sum_api(id,data) {
      const response = await put_content_picture(id, data);
      if (response.code === 0) {
        this.$message.success("操作成功");
        //  刷新列表
        await this.getFeatureList();
      } else {
        this.$message.warning(response.message);
      }
    },
    set_payFees_Btn(id,isNeedPay) { //是否需要缴费
      this.set_form.isNeedPay = isNeedPay;
      let data = {
        isNeedPay: isNeedPay,
      };
      this.set_sum_api(id,data);
    },
    set_Suspended_Btn(id,isShowApplyBar) { //是否显示悬浮报名
      this.set_form.isShowApplyBar = isShowApplyBar;
      let data = {
        isShowApplyBar: isShowApplyBar,
      };
      this.set_sum_api(id,data);
    },
    set_login_Btn(id,isNeedLogin) { //是否需要登录
      this.set_form.isNeedLogin = isNeedLogin;
      let data = {
        isNeedLogin: isNeedLogin,
      };
      this.set_sum_api(id,data);
    },
    //内容-字幕文字通知---------------------------------------------------------------
    async content_alphabetic_delBtn(id) {
      const response = await del_content_alphabetic_list(id);
      if (response.code === 0) {
        this.$message.success("删除成功！");
        //  刷新
        await this.getZMTZList();
      } else {
        this.$message.warning("失败" + response.message);
      }
    },
    async content_alphabetic_submit() {
      if (this.content_alphabetic_font !== "") {
        let data = {
          conventionId: this.$route.query.id,
          icon: "https://yunicu-1252497858.cos.ap-guangzhou.myqcloud.com/images/yunicu/1636956351919_1628996234437_%E5%AD%97%E5%B9%95%E9%80%9A%E7%9F%A5.png",
          content: this.content_alphabetic_font,
        };
        const response = await post_content_alphabetic_list(data);
        if (response.code === 0) {
          this.$message.success("添加成功");
          //  刷新
          await this.getZMTZList();
          this.content_alphabetic_font = "";
        } else {
          this.$message.warning(response.message);
        }
      } else {
        this.$message.warning("有空");
      }
    },
    closeAlphabetic() {
      this.content_alphabetic_visible = false;
    },
    //内容-图片展示-------------------------------------------------------------------
    async picture_image() {
      let inputDOM = this.$refs.detailCoverUrl_hyc.files[0];
      //判断图片大小
      if (inputDOM.size / 1024 / 1024 < 1) {
        const image = await update(inputDOM);
        if (image.code === 0) {
          this.picture_form.picture.push(image.data.url);
          this.$message.success("上传成功");
        } else {
          this.$message.warning("上传失败");
        }
      } else {
        this.$message.warning("请上传小于1M的图片！");
      }
      document.getElementById("upload_HYC").value = null;
    },
    isOpenTopTitleBarBtn(isOpen){
      this.setTitleBar_form.isOpenTopTitleBar = isOpen;
    },
    isOpenTopTitleBarHTMLBtn(isOpen){
      this.html_form.isOpenTopTitleBar = isOpen;
    },
    isOpenTopTitleBarZCBMBtn(isOpen){
      this.ZCBMForm.isOpenTopTitleBar = isOpen;
      let data = {
        isOpenTopTitleBar: this.ZCBMForm.isOpenTopTitleBar,
      };
      this.ZCBMBtn(data);
    },
    async pictureTrueBtn() {
      let data = {
        content: {
          value: this.picture_form.picture,
        },
        // isOpenTopTitleBar:this.picture_form.isOpenTopTitleBar,
        // topBanner:this.picture_form.topBanner
      };
      const response = await put_content_picture(this.picture_id, data);
      if (response.code === 0) {
        this.$message.success("操作成功");
        //清空
        this.picture_form.picture = [];
        // this.picture_form.topBanner = '';
        // this.picture_form.isOpenTopTitleBar = 1;
        this.picture_visible = false;
      } else {
        this.$message.warning("失败" + response.message);
      }
    },

    //内容-PDF展示--------------------------------------------------------------
    async content_pdf(name) {
      const fileList = document.getElementById(name).files;

      let formData = new FormData();
      for (const file of fileList) {
        let picture = {};

        let str = file.name;
        let index = str.indexOf("."); // 获取元素在字符串中的索引位置
        let result = str.substring(0, index); // 使用substring()方法截取从0到index的子字符串

        picture.name = result;
        formData.append("file", file);
        const image = await postImgList(formData);
        if (image.code === 0) {
          for (const images of image.data.url) {
            picture.url = images;
          }
          this.content_pdf_list.push({
            value: picture.url,
            name: picture.name,
          });
        }
      }
    },
    delectPdfList(index){
      this.content_pdf_list.splice(index,1);
      console.log(this.content_pdf_list.length)
    },
    async content_pdf_Ok() {
      let data = {
        pdfContent:this.content_pdf_list
      };
      const response = await put_content_picture(this.HYTZ_id, data);
      if (response.code === 0) {
        this.$message.success("操作成功");
        //清空
        this.content_pdf_visible = false;
        //  清空文件名和文件进度条
        this.content_pdf_list = [];
        this.percentAge = 0;
      } else {
        this.$message.warning("失败" + response.message);
      }
    },

    //内容-图文链接展示-----------------------------------------------------------------
    async content_GraphicLinks_image() {
      let inputDOM = this.$refs.content_GraphicLinksUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM);
        if (image.code === 0) {
          this.content_GraphicLinks_form.displayUrl = image.data.url;
          this.$message.success("上传成功");
        } else {
          this.$message.warning("上传失败");
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！");
      }
      document.getElementById("picture_image_linkImg").value = null;
    },
    content_GraphicLinks_submit() {
      this.$refs.content_GraphicLinks_ruleForm.validate(async (valid) => {
        if (valid) {
          let form = this.content_GraphicLinks_form;
          let data = {
            title: form.title,
            conventionId: this.$route.query.id,
            displayUrl: form.displayUrl,
            targetUrl: form.targetUrl,
            funcId: this.funcId,
          };
          const response = await post_content_GraphicLinks_list(data);
          if (response.code === 0) {
            this.$message.success("添加成功！");
            await this.getYZTList();
            //  清空
            this.$refs.content_GraphicLinks_ruleForm.resetFields();
            this.content_GraphicLinks_form.displayUrl = "";
          } else {
            this.$message.warning("失败", response.message);
          }
        } else {
          this.$message.warning("有空~~");
          return false;
        }
      });
    },
    async content_GraphicLinks_data_delBtn(id) {
      const response = await del_content_GraphicLinks_list(id);
      if (response.code === 0) {
        this.$message.success("删除成功！");
        await this.getYZTList();
      } else {
        this.$message.warning("失败", response.message);
      }
    },
    //上移下移公用
    async content_GraphicLinks_upDown(id, action) {
      const response = await put_content_GraphicLinks_move(id, action);
      if (response.code === 0) {
        await this.getYZTList();
      } else {
        this.$message.warning("失败", response.message);
      }
    },
    //上移下移
    content_GraphicLinks_up(id) {
      let action = "up";
      this.content_GraphicLinks_upDown(id, action);
    },
    content_GraphicLinks_down(id) {
      let action = "down";
      this.content_GraphicLinks_upDown(id, action);
    },

    //内容-链接展示--------------------------------------------------------------------
    async content_LinkShow_Ok() {
      if (this.content_LinkShow_dataVal) {
        this.content_LinkShow_dataValIsTrue = false;
        //判断url有没有？，没有就拼一下
        let data = {
          url: this.content_LinkShow_dataVal,
        };
        const response = await put_content_picture(this.HTML_id, data);
        if (response.code === 0) {
          this.$message.success("操作成功");
          //清空
          this.content_LinkShow_dataVal = "";
          this.content_LinkShow_visible = false;
        } else {
          this.$message.warning(response.message);
        }
      } else {
        this.content_LinkShow_dataValIsTrue = true;
      }
    },

    //获取列表内容-字幕文字通知---ZMTZ
    async getZMTZList() {
      const response = await get_content_alphabetic_list(this.$route.query.id);
      if (response.code === 0) {
        this.content_alphabetic_data = response.data;
      } else {
        this.$message.warning("失败" + response.message);
      }
    },
    //获取列表内容-图文链接展示---YZT
    async getYZTList() {
      this.spinning = true;
      const response = await get_content_GraphicLinks_list(
        this.$route.query.id,
        this.funcId
      );
      if (response.code === 0) {
        this.content_GraphicLinks_data = response.data;
      }
      this.spinning = false;
    },

    close_richText(){
      this.richText_visible = false;
    },
    async richTextSubmit() {
      let data = {
        fwbContent: this.html_form.html,
        // topBanner: this.html_form.topBanner,
        // isOpenTopTitleBar: this.html_form.isOpenTopTitleBar,
      };
      const response = await put_content_picture(this.funcId, data);
      if (response.code === 0) {
        this.$message.success("操作成功");
        //清空
        this.html_form.html = '';
        // this.html_form.topBanner = '';
        // this.html_form.isOpenTopTitleBar = 1;
        this.richText_visible = false;
      } else {
        this.$message.warning("失败" + response.message);
      }
    },
    //内容对话框---------------------------------------------------------------
    async contentBtn(list) {
      console.log(list);

      if (list.funcCode) {
        //获取功能id
        this.funcId = list.id;
        switch (list.funcCode) {
          // case 'DBHF':
          //   this.$message.info("请去点播视频管理中维护~")
          //   // this.get_content_video_list()
          //   // this.content_video_demand_visible = true
          //   break;
          // case 'LBGG':
          //   this.content_ByAdvertising_visible = true
          //   this.getLBGGList()
          //   break;
          case "HYBM":
            this.deployBtn(list);
            break;
            case "ZMTZ":
            this.content_alphabetic_visible = true;
            this.getZMTZList();
            break;
          case "YZT":
            this.content_GraphicLinks_visible = true;
            this.getYZTList();
            break;
          case "HYC":
            this.picture_id = list.id;
            this.picture_visible = true;
            //  点击获取内容
            await this.getFeatureListOne(list.id);
            if (this.dataListOne.content) {
              this.picture_form.picture = this.dataListOne.content.value;
              // this.picture_form.isOpenTopTitleBar = this.dataListOne.isOpenTopTitleBar;
              // this.picture_form.topBanner = this.dataListOne.topBanner;
            }
            break;
          case "HTML":
            this.HTML_id = list.id;
            this.content_LinkShow_visible = true;
            //  点击获取内容
            await this.getFeatureListOne(list.id);
            this.content_LinkShow_dataVal = this.dataListOne.url;
            break;
          case "HYTZ":
            this.HYTZ_id = list.id;
            this.content_pdf_visible = true;
            //  点击获取内容
            await this.getFeatureListOne(list.id);
            if (this.dataListOne.pdfContent) {
              this.content_pdf_list = this.dataListOne.pdfContent;
            }
            break;
          case "TEXT":
            this.richText_visible = true;
            await this.getFeatureListOne(list.id);

            setTimeout(() => {
              if (this.dataListOne.fwbContent) {
                this.html_form.html = this.dataListOne.fwbContent;
                // this.html_form.topBanner = this.dataListOne.topBanner;
                // this.html_form.isOpenTopTitleBar = this.dataListOne.isOpenTopTitleBar;
              }
            }, 100)
            break;
          case "ZCBM":
            await this.ZCBMList(this.meetCode,list.id);
            this.ZCBM_visible = true;
            // await this.getFeatureListOne(list.id);

            // setTimeout(() => {
            //   if (this.dataListOne) {
            //     // this.ZCBMForm.topBanner = this.dataListOne.topBanner;
            //     // this.ZCBMForm.isOpenTopTitleBar = this.dataListOne.isOpenTopTitleBar;
            //   }
            // }, 100)
            break;
            case "ZCJF":
            await this.ZCJFList(this.meetCode,list.id);
            // 收集结果
            this.ZCJF_visible = true;
            list.qrCodeLink === null?this.qrStatus = 'shengcheng':this.qrStatus = 'zhanshi'
            break;

          default:
            break;
        }
      } else {
        this.$message.warning("类型未设置，请点击修改进行进行设置");
      }
    },
    setRemarks(record){ // 设置备注按钮
      this.funcId = record.id;
      this.remarkVal = record.remark;
      this.remarkLocation = record.remarkLocation ?  record.remarkLocation : 'up';
      this.setRemarksVisible = true;
    },
    async setRemark_Ok() { // 设置备注确认按钮
        let data = {
          remark:this.remarkVal,
          remarkLocation:this.remarkLocation
        }
        console.log(data);


        const response = await put_content_picture(this.funcId, data);
        if (response.code === 0) {
          this.$message.success("修改成功！");
          //  关闭
          this.setRemarksVisible = false;
          //  刷新列表
          await this.getFeatureList();
        } else {
          this.$message.warning(response.message);
        }
    },
    setRemark_cancel(){ // 设置备注取消按钮
      this.setRemarksVisible = false;
      this.remarkVal = '';
      this.setRemark_dataValIsTrue = false;
    },
    //判断注册报名是否可以新增修改或删除操作
    async getAllowOperation(type,item) {
      const response = await allowOperation(this.meetCode);
      if (response.code === 200) {
        if(response.data){
          if(type === '新增'){
            this.ZCBMTitle = '新增';
            this.ZCBM_add_visible = true;
          }else if(type === '修改'){

            this.ZCBMTitle = '修改';
            this.ZCBMListId = item.id;
            // 赋值
            this.ZCBM_form.type = item.type;
            this.ZCBM_form.title = item.title;
            this.ZCBM_form.isMust = item.isMust ? true:false;
            if(item.option.length > 0){
              let obj = [];
              for (let i=0;i<item.option.length;i++){
                obj.push({
                  title:item.option[i]
                })
              }
              this.ZCBM_form.option = obj;
            }
            this.ZCBM_form.tips = item.tips;
            this.ZCBM_add_visible = true;
          }
        }else {
          this.clearVisible = true;
          // this.$message.warning("注册报名已开始，若想新增或编辑数据，请先清空名单！");
        }
      } else {
        this.$message.warning(response.message);
      }
    },

    //设置
    setBtn(list) {
      this.set_form.isNeedLogin = list.isNeedLogin;
      this.set_form.isShowApplyBar = list.isShowApplyBar;
      this.set_form.isNeedPay = list.isNeedPay;

      this.set_visible = true;
      this.set_id = list.id;
      this.card_list_content = list;
    },
    async listBtn() {
      let data = {
        conferenceCode: this.meetCode,
        tel: this.listTel,
        pageNo: this.listPageNo,
        pageSize: this.listpageSize,
      };
      const response = await meetingRegistrationList(data);
      if (response.code === 200) {
        this.dataLists = response.data;
        this.listCount = response.count;
      } else {
        this.$message.warning(response.message);
      }
      this.ExcelMember = true;
    },
    async ZCBMPanelBtn(record) {
      console.log("打开", record);

      this.visibleDataReport = true;
      this.dataId=record.id
      this.funcCode1=record.funcCode

      // await this.ZCBMPanel(this.meetCode);
    },
    deployBtn(list) {
      if (list.content) {
        this.configuration_form.remark = list.content.remark;
      }
      this.codeId = list.id;
      this.configuration_visible = true;
    },
    returnBtn() {
      // window.history.back()
      this.$router.push({ path: "/meetingManagement" });
      this.$store.dispatch("isShowIconTitle", 0);
    },
    openLinkUrl(row) {
      window.open(row.targetUrl);
    },
    delPicListBtn(index) {
      // this.picture_form.picture.splice(
      //   this.picture_form.picture.indexOf(index),
      //   1
      // );
      this.picture_form.picture.splice(index, 1);

    },
    switchBtn(isOpen) {
      // this.content_addCard_form.isShowIconTitle = isOpen;
    },
    openModel(type,picType,change,id) {
      if(id){
        this.funcId = id;
      }
      if(picType === '图标库'){
        this.getlconCityList();
        this.visible = true;
        this.getList()
      }else {
        this.typePic = type;
        if(change === 'radio'){ //单选
          this.pInfo =  true;
        }else {
          //多选
          this.pInfo =  false;
        }
        this.visiblePic = true;
      }
    },
    handleCancel() {
      this.visible = false;
      this.iconTemplate = 0;
    },
    handleOk() {
      if (this.selectImg) {
        this.content_addCard_form.icon = this.selectImg;
        this.visible = false;
        this.iconTemplate = 0;
        this.selectIndex = null;
        this.selectMyIndex = null;
        this.selectId = ["1"];
        this.pageNo = 1;
        this.pageSize = 5;
      } else {
        this.$message.info("请选择功能图标！");
      }
    },
    async selectStatus(val,item) {
      this.selectId[0] = item.id;
      this.funList = [];
      this.spin = true;
      if (val === "我的上传") {
        let data = {
          createdById: this.createdById,
          name: this.myFunName,
          curPage: this.curPage,
          pageSize: 99999,
        };
        const response = await iconList(data);
        if (response.code === 0) {
          this.myFunList = response.data;
        } else {
          this.$message.warning(response.message);
        }
      } else {
        await this.getList();
      }
      //清空选中的index
      this.selectIndex = null;
      this.selectMyIndex = null;
      this.selectImg = "";
      this.spin = false;
    },
    selectCity(val){
      this.selectId[0] = val;
      this.funList = [];
      this.iconTemplate = 0;
      this.pageNo = 1;
      this.getList();
    },
    leftBtn() {
      // 左边按钮
      if (this.iconTemplate > 0) {
        this.iconTemplate--;
        this.getTemplateIcon(this.iconDataList[this.iconTemplate].id);
      } else {
        //返回上一页九宫格模板的数据，并且初始index为4
        this.iconTemplate = 4;
        this.pageNo--;
        this.getList();
      }
      //   if(this.pageNo > 1){
      //     this.iconTemplate = 0;
      //     this.pageNo -- ;
      //     this.getList();
      //   }
    },

    rightBtn() {
      // 右边按钮
      if (this.iconTemplate < this.iconDataList.length - 1) {
        this.iconTemplate++;
        this.getTemplateIcon(this.iconDataList[this.iconTemplate].id);
        this.downLoadFile = this.iconDataList[this.iconTemplate].fileUrl;
      } else {
        //加载下一页九宫格模板的数据
        if (this.pageNo * this.pageSize < this.iconDataCount) {
          this.iconTemplate = 0;
          this.pageNo++;
          this.getList();
        }
      }
      // if(this.pageNo * this.pageSize < this.iconDataCount){
      //   this.iconTemplate = 0;
      //   this.pageNo ++ ;
      //   this.getList()
      // }
    },
    MySearch() {
      this.selectStatus("我的上传");
    },
    onSearch() {
      this.getTemplateIcon(this.iconTemplateId);
    },
    async getInformation() {
      const response = await getAuthenticationInformation();
      if (response.code === 200) {
        this.userInfoList = response.data;
        if (this.userInfoList.memberStatus !== 0) {
          window.open(this.downLoadFile);
        } else {
          this.visibleAlert = true;
        }
      } else {
        this.$message.warning("name", response.message);
      }
    },
    openDown() {
      this.getInformation();
      // window.open(this.downLoadFile);

    },
    selectCard(index, url) {
      this.selectIndex = index;
      this.selectImg = url;
    },
    getSelectMyIcon(index, url) {
      this.selectMyIndex = index;
      this.selectImg = url;
    },
    async saveBtn() {
      if (this.funUp.funName) {
        let data = {
          url: this.funUp.funImg,
          name: this.funUp.funName,
          createdById: this.createdById,
        };
        const response = await postIcon(data);
        if (response.code === 0) {
          this.$message.success("新增成功！");
          await this.selectStatus("我的上传");
          this.funUp = {
            funImg: "",
            funName: "",
          };
        } else {
          this.$message.warning(response.message);
        }
      } else {
        this.$message.info("请输入功能名称！");
      }
    },
    async surfacePlotBtn() {
      this.spin = true;
      let inputDOM = this.$refs.surfacePlot.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM);
        if (image.code === 0) {
          this.funUp.funImg = image.data.url;
          this.funUp.funName = inputDOM.name;
          this.$message.success("上传成功");
        } else {
          this.$message.warning("上传失败");
        }
      } else {
        this.$message.warning("请上传小于1M的图片！");
      }
      document.getElementById("surfacePlot").value = null;
      this.spin = false;
    },
    //批量上传图片
    async batchImgBtn(name) {
      let data = [];
      let fileList = this.$refs[name].files;
      let formData = new FormData();

      for (const file of fileList) {
        let list = {
          url: "",
          name: file.name,
          createdById: this.createdById,
        };
        data.push(list);
        formData.append("file", file);
      }
      // 判断图片大小
      const image = await postImgList(formData);
      if (image.code === 0) {
        for (let j = 0; j < image.data.url.length; j++) {
          data[j].url = image.data.url[j];
        }
        await this.upImgList(data);
      } else {
        this.$message.warning("上传失败");
      }
      // document.getElementById('batchImg').value = null
    },
    //批量上传图片接口
    async upImgList(data) {
      const response = await bach_icon(data);
      if (response.code === 0) {
        await this.selectStatus("我的上传");
        this.$message.success("操作成功！");
      } else {
        this.$message.warning(response.message);
      }
    },
    getIconList(id, index, fileUrl) {
      //获取图标
      this.downLoadFile = fileUrl;
      this.iconTemplate = index ? index : 0;
      this.iconTemplateId = id;
      this.getTemplateIcon(id);
    },
    //获取图标数据
    async getTemplateIcon(id) {
      this.spin = true;
      this.iconTemplateId = id;
      let data = {
        // createdById:'',
        name: this.iconName,
        curPage: this.curPage,
        pageSize: 99999,
        icontempId: id, //九宫格模板id
      };
      const response = await iconList(data);
      if (response.code === 0) {
        this.funList = response.data;
      } else {
        this.$message.warning(response.message);
      }
      this.spin = false;
    },
    async getList() {
      let data = {
        curPage: this.pageNo,
        pageSize: this.pageSize,
        type:this.selectId[0] === 'vector' ? 'vector':'city',
        city:this.selectId[0] === 'vector' ? null : this.selectId[0],
        isOpen: 1,
      };
      const response = await icontemp(data);
      if (response.code === 0) {
        this.iconDataList = response.data;
        this.iconDataCount = response.count;

        if (this.iconDataList.length) {
          //默认情况下为数组第一个源文件
          this.downLoadFile =  response.data[this.iconTemplate].fileUrl ;
          //默认情况下获取数组一下的图标
          await this.getIconList(
              response.data[this.iconTemplate].id,
              this.iconTemplate
          );
        }
      } else {
        this.$message.warning("上传失败");
      }
    },
    async delIconList(id) {
      const response = await delIcon(id);
      if (response.code === 0) {
        await this.selectStatus("我的上传");
        this.$message.success("操作成功！");
      } else {
        this.$message.warning(response.message);
      }
    },
    putIconList(img) {
      this.$set(img, "editKey", true);
      //自动聚焦
      this.$nextTick(() => {
        document.getElementById("Ainput").focus();
      });
    },
    async pressEnter(img) {
      let data = {
        id: img.id,
        name: img.name,
        createdById: this.createdById,
      };
      const response = await putIcon(data);
      if (response.code === 0) {
        await this.selectStatus("我的上传");
      } else {
        this.$message.warning(response.message);
      }
    },
    blurEnter(img) {
      this.$set(img, "editKey", false);
    },
    configurationClose() {
      this.configuration_visible = false;
      this.configuration_form.remark = "";
    },
    configurationSubmit() {
      this.$refs.configuration_ruleForm.validate(async (valid) => {
        if (valid) {
          const response = await putUpdXFZC(
            this.codeId,
            this.configuration_form.remark
          );
          if (response.code === 200) {
            this.$message.success("操作成功！");
            await this.getFeatureList();
            this.configurationClose();
          } else {
            this.$message.warning(response.message);
          }
        } else {
          return false;
        }
      });
    },
    closeMainService(val){
      this.ServiceVisible = val;
    },
    //埋点
    async postAddReportPb(location) {
      let data = {
        "serviceCode":this.serviceCode, //浏览商品code值
        // "browseTime":this.seconds, //浏览时间
        "location":location //浏览位置
      }
      await addReportPb(data);
    },
    openServiceModel(type){
      this.isPayModel(type);
    },
    //获取主办单位设置和技术支持去广告是否弹出确认使用框
    async isPayModel(type) {
      const response = await get_isShowPay(this.meetCode);
      if (response.code === 200) {
        let obj = response.data;
        this.objContent = obj;
        switch (type){
          case 'zbdw': //主办单位
              this.isWhos = 'zbdwsz';
              if(obj.zbdwszShow){
                this.serviceCode = 'zbdwsz';
                this.ServiceVisible = true;
                if(!this.isKefu){
                  this.postAddReportPb('CONVENTION_FUNC_SET_UP');
                }
              }else {
                this.OrganizerVisible = true;
              }
                break;
          case 'jszc': //技术支持
            this.isWhos = 'jszcqgg';
              if(obj.jszcqggShow){
                this.serviceCode = 'jszcqgg';
                this.ServiceVisible = true;
                if(!this.isKefu){
                  this.postAddReportPb('CONVENTION_FUNC_SET_UP');
                }
              }else {
                this.TechnicalVisible = true;
              }
            break;
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    closeMainOrganizer(val){
      this.OrganizerVisible = val;
    },

    closeMainTechnical(val){
      this.TechnicalVisible = val;
    },

    // closeMainProduct(val){
    //   this.ProductVisible = val;
    // },

    // closeMainPay(val){
    //   this.payVisible = val;
    // },

    openModelOrganizer(){
      this.OrganizerVisible = true;
    },
    openModelTechnical(){
      this.TechnicalVisible = true;
    },

    //获取注册报名数据列表
    async ZCBMList(code,id) {
      const response = await getZCBMList(code,id);
      if (response.code === 200) {
        this.dataZCBMList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取注册报名数据列表--名单
    async ZCBMPanel(code) {
      let data = {
        dateBegin:this.ZCBM_list_StimeVal,
        dateEnd:this.ZCBM_list_EtimeVal,
        page:this.pagination.current,
        pageSize:this.pagination.pageSize
      }
      const response = await getZCBMPanel(code,data);
      if (response.code === 200) {

        let header = response.headers;
        let headerKey = Object.keys(header);
        let headerArr = Object.keys(header).map((key,index)=>{
          let list = {
            title: header[key],
            dataIndex: headerKey[index],
            key: headerKey[index],
            slots: { title: headerKey[index] },
          }
          return list;
        });
        this.columnsZCBMList = headerArr;//table表头

        this.dataListZCBMList = response.data; //table数据

        this.pagination.total = response.count;
        this.ZCBM_visible_list = true;
      } else {
        this.$message.warning(response.message);
      }
    },
    selectZCBMTime(date, dateString) {
      this.ZCBM_list_StimeVal = dateString[0];
      this.ZCBM_list_EtimeVal = dateString[1];
      if(this.ZCBM_list_StimeVal === '' && this.ZCBM_list_EtimeVal === ''){
        this.ZCBMPanel(this.meetCode);
      }
    },
    selectZCBMTimeOk() {
      this.ZCBMPanel(this.meetCode);
    },
    //新增注册报名数据列表
    async exportBtn() {
      this.loadingZCBM = true;
      let data = {
        dateBegin:this.ZCBM_list_StimeVal,
        dateEnd:this.ZCBM_list_EtimeVal,
        ExelName:'注册报名'+new Date().getTime()
      }
      await getZCBMPanelExport(this.meetCode,data);
      this.loadingZCBM = false;
    },
    //新增注册报名数据列表
    async postZCBMLists(data) {
      const response = await postZCBMList(data);
      if (response.code === 200) {
        this.$message.success("新增成功");
        this.ZCBMList(this.meetCode,this.funcId);
      } else {
        this.$message.warning(response.message);
      }
    },
    //修改注册报名数据列表
    async putZCBMLists(data) {
      const response = await putZCBMList(data);
      if (response.code === 200) {
        this.$message.success("修改成功");
        this.ZCBMList(this.meetCode,this.funcId);
      } else {
        this.$message.warning(response.message);
      }
    },
    //修改注册报名数据列表上移下移
    async putZCBMListMoves(type,id) {
      const response = await putZCBMListMove(type,id);
      if (response.code === 200) {
        this.$message.success("修改成功");
        this.ZCBMList(this.meetCode,this.funcId);
      } else {
        this.$message.warning(response.message);
      }
    },
    //删除注册报名数据列表
    async delZCBMListMoves(id) {
      const response = await delZCBMListMove(id);
      if (response.code === 200) {
        this.$message.success("删除成功");
        this.ZCBMList(this.meetCode,this.funcId);
      } else {
        this.$message.warning(response.message);
      }
    },
    close_ZCBM(){
      this.ZCBM_visible = false;
      this.qrStatus = ''
    },
    openZCBM(){
      this.getAllowOperation('新增');
    },
    // 打开签到二维码弹窗
    openQrCode(){
      this.linkVisible = true
      this.getTheQrcode()
    },
    // 点击生成二维码
    showQRCode(){
      this.qrStatus = 'zhanshi'
      this.getTheQrcode()
    },
    downImg(){
      let container = document.getElementById('qrCode').childNodes[0];  //id
      let blob = this.base64ToBlob(container.getAttribute('src'));
      let aLink = document.createElement('a');

      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = '文件名';
      aLink.href = URL.createObjectURL(blob);
      aLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));//兼容火狐
    },
    // 获取签到二维码
    async getTheQrcode(){
      const response = await getQrCode(this.meetCode, this.funcId);
        if (response.code === 0) {
          this.appSrc = response.data.qrCodeLink
          this.app2Src = 'https://meet.yichimeeting.com/qrSign?'+'conventionCode='+this.meetCode+'&funcId='+this.funcId
          this.getFeatureList()
          // 重新赋值
          // this.qrStatus = 'zhanshi'
        } else {
          this.$message.warning(response.message);
        }
    },
    close_ZCBMAdd(){
      this.ZCBM_add_visible = false;
      this.ZCBM_form = {
        type:'text',
        title:'',
        isMust:false,
        option:[{
            title:'',
        }],
        tips:'',
      }
    },
    ZCBMAdd(){
      this.$refs.ZCBM_add_ruleForm.validate(valid => {
        if (valid) {
          // let isNext = true;
          //判断标题不能重复
          // this.dataZCBMList.forEach(item => {
          //   if (item.title === this.ZCBM_form.title) {
          //     isNext = false;
          //     this.$message.warning('请勿重复设置相同标题的问题！');
          //   }
          // })

          let ifTrue = true;
          //当类型为单选，多选，下拉选择
          if(this.ZCBM_form.type === 'radio' || this.ZCBM_form.type === 'dropdown_radio' || this.ZCBM_form.type === 'check'){
            this.ZCBM_form.option.forEach(item => {
              if (item.title === '') {
                ifTrue = false;
                this.$message.warning('请完善选项');
              }
            })
          }else {
            this.ZCBM_form.option = [];
          }
          if (ifTrue) {
            let options = this.ZCBM_form.option ? this.ZCBM_form.option.map((item) => item.title):[];
            let data = [];
            if(this.ZCBMTitle === '新增'){
              data = {
                "conventionCode": this.meetCode, //会议code
                "type": this.ZCBM_form.type, //类型填空：text多行填空：multiline单选：radio下拉单选：dropdown_radio多选：check
                "title": this.ZCBM_form.title, //标题
                "isMust": this.ZCBM_form.isMust ? 1:0, //是否必填 0否 1 是
                "option": options,//选项
                "tips": this.ZCBM_form.tips,//提示
                "funcId":this.funcId
              }
              this.postZCBMLists(data);
            }else if(this.ZCBMTitle === '修改'){
              data = {
                "id": this.ZCBMListId, //id
                "type": this.ZCBM_form.type, //类型填空：text多行填空：multiline单选：radio下拉单选：dropdown_radio多选：check
                "title": this.ZCBM_form.title, //标题
                "isMust": this.ZCBM_form.isMust ? 1:0, //是否必填 0否 1 是
                "option": options,//选项
                "tips": this.ZCBM_form.tips,//提示
                "funcId":this.funcId   //222222
              }
              this.putZCBMLists(data);
            }
            this.close_ZCBMAdd();
          }
        } else {
          return false;
        }
      });
    },
    UpZCBMList(item){
      console.log("hahhaah",item);
      this.getAllowOperation('修改',item)
    },
    delCardZCBMList(item){
      this.delZCBMListMoves(item.id);
    },
    moveUpZCBM(type,id){
      this.putZCBMListMoves(type,id);
    },
    //新增注册报名配置
    selectBtn(item){
      this.ZCBM_form.type = item.type;
    },
    //新增选项
    addOptions(){
      let ifTrue = true
      this.ZCBM_form.option.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.ZCBM_form.option.push({
          title:''
        })}
    },
    delOptions(item,index){
      this.ZCBM_form.option.splice(index, 1)
    },
    close_ZCBMList(){
      this.ZCBM_visible_list = false;
      this.ZCBM_list_StimeVal = null;
      this.ZCBM_list_EtimeVal = null;
    },
    pageChange(current,pageSize){
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.ZCBMPanel(this.meetCode);
    },
    //删除注册报名数据列表
    async delZCBMMList() {
      const response = await delAllNameList(this.meetCode);
      if (response.code === 200) {
        this.ZCBM_list_StimeVal = null;
        this.ZCBM_list_EtimeVal = null;
        this.clearVisible = false;
        await this.ZCBMPanel(this.meetCode);
      } else {
        this.$message.warning(response.message);
      }
    },
    closeZCBMMList(){
      this.clearVisible = false;
    },
    //删除注册报名数据列表
    async delZCBMMLists() {
      const response = await delAllNameList(this.meetCode);
      if (response.code === 200) {
        this.clearVisible = false;
        this.$message.success("已清除名单！");
      } else {
        this.$message.warning(response.message);
      }
    },
    mouseoverBtn(val){
      if(val === 'liveCoverPics'){
        this.liveCoverPicsShow = true;
      }

    },
    mouseleaveBtn(val){
      if(val === 'liveCoverPics') {
        this.liveCoverPicsShow = false;
      }
    },
    tableSortBtn(){
      this.getOpenFeatureList();
      this.setSortVisible = true;
    },
    //获取功能开启的列表
    async getOpenFeatureList() {
      let data = {
        isPublished:1,
      }
      const response = await CaseLiveFeature(this.$route.query.id,data);
      if (response.code === 0) {
        this.dataListOpen = response.data;
      }
    },
    oneKeyBtn(){
      this.setClickVisible = true;
      this.iconTitleColorNoBtn = this.liveInfo.iconTitleColor ? this.liveInfo.iconTitleColor : '#333333';;

    },

    //  功能名称字体颜色
    FunctionColorRest(val){
      this.iconTitleColorNoBtn = val.hex;
      // this.iconTitleColor = val.hex;
    },
    async putFunColorApi() {
      this.iconTitleColor = this.iconTitleColorNoBtn;

      let data = {
        conferenceCode: this.meetCode, //会议编码
        iconTitleColor: this.iconTitleColor,
      };
      const response = await putSwitch(data);
      if (response.code === 0) {
        this.$message.success("操作成功！");
        await this.getFeatureList();
      } else {
        this.$message.warning(response.message);
      }
    },
    //  功能图标背景颜色
    funIconColorAdd(val){
      this.funIconColor = val.hex;
      this.funTransparency = val.a;
    },
    async funcIconBgColorBtn() {
      let data = {
        conventionId: this.liveInfo.id,
        iconBgColor: this.funIconColor,
        transparency:this.funTransparency
      };
      const response = await oneClickFuncIconBgColor(data);
      if (response.code === 200) {
        this.$message.success("操作成功！");
        //  刷新列表
        await this.getFeatureList();
      } else {
        this.$message.warning(response.message);
      }
    },
    openSetTitleBar(row){
      //赋值
      this.funcId = row.id;
      this.setTitleBar_form.title = row.title;
      this.setTitleBar_form.isOpenTopTitleBar = row.isOpenTopTitleBar;
      this.setTitleBar_form.topBanner = row.topBanner;

      this.setTitleBar = true;
    },
    setTitleBar_cancel(){
      this.setTitleBar = false;
      this.setTitleBar_form.title = '';
      this.setTitleBar_form.isOpenTopTitleBar = 0;
      this.setTitleBar_form.topBanner = '';
      this.funcId = null;
    },
    async setTitleBar_Ok() {
      let data = {
        isOpenTopTitleBar: this.setTitleBar_form.isOpenTopTitleBar,
        topBanner: this.setTitleBar_form.topBanner,
      };
      await this.ZCBMBtn(data);
      await this.getFeatureList();
      await this.setTitleBar_cancel();
    },
     close_ZCJF(){
      this.ZCJF_visible=false
  },
   async ZCJFList(conventionCode,funcId){
     const res=await getFuncZCJF(conventionCode,funcId)
     if(res.code==0){
       this.dataZCJFList=res.data
      //  this.dataZCJFList.forEach(item=>{
      //     // 是否存在关闭的状态
      //    if(item.isOpen==0){
      //       this.moveVisible=true
      //    }
      //  })
      // }
      // 找出关闭的第一个的序号
      // if(this.moveVisible){
      //   const num= this.dataZCJFList.findIndex(item=>item.isOpen==0)
      //   this.moveNum=num

      // }
     }

  },
  // -----------新增字段
 async openZCJF(){
      let data={
          funcId:this.funcId,
          conventionCode:this.meetCode,
          phone:"",
          payStatus:"",
          checkStatus:"",
          pageNo:"",
          pageSize:'',
        }
       const res= await getZCJFResult(data)
       if(res.code==0){
            if(res.data.data.length==0){
                 this.ZCJF_add_visible=true
                 this.ZCJFADDType=0
               // 获取系统字段表格
               const response=await getSystemType()
                if(response.code===0){
                  this.ZCJFSystemList=response.data
                 }
            }

            else{

              this.$message.warning('表单已填写不能新增字段！')
              return
            }
       }
  },
  close_ZCJFAdd(){
    this.ZCJF_add_visible=false
    this.ZCJFTitle="新增"
    this.ZCJFList(this.meetCode,this.funcId)
    this.ZCJF_form={
          type:'text',
          title:'',
          isMust:false,
          option:[
          {
            name:'',
          }
         ],
         tips:''
         }
             this.codeListZCJF=[
        {
          id:1,
          name:'填空',
          type:'text',
        },
        {
          id:2,
          name:'多选',
          type:'check',
        },
        {
          id:3,
          name:'单选',
          type:'radio',
        },
        {
          id:4,
          name:'下拉单选',
          type:'dropdown_radio',
        },
        {
          id:5,
          name:'多行填空',
          type:'multiline',
        }

        ]
  },
  selectBtnZCJF(form,item){
   const flag=this.codeListZCJF.find(item=>item.name=="单选付费")
   if(!flag){
    this.ZCJF_form.type = item.type;
   }
  else{
     this.$message.warning('系统字段无法修改类型！')
     return
  }
  },
      //新增选项
  addOptionsZCJF(type){
    if(type!=='radio_pay'){
      let ifTrue = true
      this.ZCJF_form.option.forEach(item => {
        if (item.name === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.ZCJF_form.option.push({
          name:''
        })}
    }
    if(type=="radio_pay"){
      let ifTrue = true
      this.ZCJF_form.option.forEach(item => {
        if (item.name === '' || item.money=='') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.ZCJF_form.option.push({
          name:'',
          money:''
        })}
    }

  },
    delOptionsZCJF(item,index){
      this.ZCJF_form.option.splice(index, 1)
    },
   ZCJFAdd(){
    this.$refs.ZCJF_add_ruleForm.validate(async valid => {
     if (valid) {
      let ifTrue=true
      //当类型为单选，多选，下拉选择
         if(this.ZCJF_form.type === 'radio' || this.ZCJF_form.type === 'dropdown_radio' || this.ZCJF_form.type === 'check' ||this.ZCJF_form.type==='radio_pay' ){

            this.ZCJF_form.option.forEach(item => {

              if (item.name === '' || item.money==="") {
                 ifTrue=false
                this.$message.warning('请完善选项');
              }
            })
         }
      if(ifTrue){
      if(this.ZCJFTitle=="新增"){
      let data={
      conventionCode: this.meetCode,//会议code
	    funcId: this.funcId,//功能id
	    fieldLabel: this.ZCJF_form.title, //标题
	    fieldType: this.ZCJF_form.type, //类型
	    options:this.ZCJF_form.option,
	    isRequired: this.ZCJF_form.isMust==true?1:0, //是否必填
	    tips: this.ZCJF_form.tips, //提示

       }
      const res= await addFuncZCJF(data)
      if(res.code==0){
         this.$message.success('添加成功')
         this.ZCJF_add_visible=false
         this.ZCJFList(this.meetCode,this.funcId)
         this.ZCJFTitle=="新增"
         this.ZCJF_form={
          type:'text',
          title:'',
         isMust:false,
          option:[
          {
            name:'',
          }
         ],
         tips:''
         }
      }
      }
      if(this.ZCJFTitle=="编辑"){
      let data={
      id:this.ZCJFId,
      conventionCode: this.meetCode,//会议code
	    funcId: this.funcId,//功能id
	    fieldLabel: this.ZCJF_form.title, //标题
	    fieldType: this.ZCJF_form.type, //类型
	    options:this.ZCJF_form.option,
	    isRequired: this.ZCJF_form.isMust==true?1:0, //是否必填
	    tips: this.ZCJF_form.tips, //提示
      isOpen:this.ZCJF_form.isOpen
       }
       const res=await editFuncZCJF(data)
       if(res.code==0){
          this.$message.success('修改成功！')
          this.ZCJF_add_visible=false
          this.ZCJFList(this.meetCode,this.funcId)
          this.ZCJFTitle=="新增"
          this.ZCJF_form={
          type:'text',
          title:'',
          isMust:false,
          option:[
          {
            name:'',
          }
         ],
         tips:''
           }
         this.codeListZCJF=[
        {
          id:1,
          name:'填空',
          type:'text',
        },
        {
          id:2,
          name:'多选',
          type:'check',
        },
        {
          id:3,
          name:'单选',
          type:'radio',
        },
        {
          id:4,
          name:'下拉单选',
          type:'dropdown_radio',
        },
        {
          id:5,
          name:'多行填空',
          type:'multiline',
        }

        ]
    }
      }
     }
     }
    })
    },
    ZCJFClose(){
          this.ZCJF_add_visible=false
          this.ZCJFTitle="新增"
          this.selectedRowKeys=[]
          this.selectedRows=[]
          this.ZCJF_form={
          type:'text',
          title:'',
          isMust:false,
          option:[
          {
            name:'',
          }
         ],
         tips:''
         },
         this.codeListZCJF=[
        {
          id:1,
          name:'填空',
          type:'text',
        },
        {
          id:2,
          name:'多选',
          type:'check',
        },
        {
          id:3,
          name:'单选',
          type:'radio',
        },
        {
          id:4,
          name:'下拉单选',
          type:'dropdown_radio',
        },
        {
          id:5,
          name:'多行填空',
          type:'multiline',
        }

        ]
    },
    //  编辑注册缴费
    editZCJF(record){
    console.log("上面",record);

    if(record.type=="系统字段"){
        this.codeListZCJF=[
        {
          id:1,
          name:'填空',
          type:'text',
        },
        {
          id:2,
          name:'多选',
          type:'check',
        },
        {
          id:3,
          name:'单选',
          type:'radio',
        },
        {
          id:4,
          name:'下拉单选',
          type:'dropdown_radio',
        },
        {
          id:5,
          name:'多行填空',
          type:'multiline',
        },
         {
          id:6,
          name:'单选付费',
          type:'radio_pay',
        },
        {
          id:7,
          name:'日期',
          type:'date',
        },
        ]
    }
    this.ZCJF_add_visible=true
    this.ZCJFTitle="编辑"
    this.ZCJFId=record.id
    this.ZCJFADDType=1
    // if(record.options.length > 0){
    //           let obj = [];
    //           for (let i=0;i<record.options.length;i++){
    //             obj.push({
    //               tit:record.options[i]
    //             })
    //           }
    //           this.ZCJF_form.option = obj;
    //         }
    // this.ZCJF_form.tyoe=record.fieldType
    // this.ZCJF_form.title=record.fieldLabel
    // this.ZCJF_form.isMust=record.isRequired==1?true:false,
    // this.ZCJF_form.tips=record.tips,
    // this.ZCJF_form.zhiduanType=record.type,
    // this.ZCJF_form.isOpen=record.isOpen,
    //   this.ZCJF_form.isOpenfieldName=record.fieldName
    this.ZCJF_form={
         type:record.fieldType,
         title:record.fieldLabel,
         isMust:record.isRequired==1?true:false,
         tips:record.tips,
         zhiduanType:record.type,
         isOpen:record.isOpen,
         fieldName:record.fieldName,
         option:JSON.parse(JSON.stringify(record.options))//深拷贝
         }
    console.log(this.ZCJF_form);

    },
    // 删除注册缴费
    async delZCJF(id){
      const res=await delFuncZCJF(id)
       if(res.code==0){
            this.$message.success("删除成功！")
            this.ZCJFList(this.meetCode,this.funcId)
       }
    },
    async moveZCJF(type,id){
       const res=await moveFuncZCJF(type,id)
       if(res.code==0){
         this.$message.success('移动成功！')
         this.ZCJFList(this.meetCode,this.funcId)
       }

    },
    changeZCJF(a,index1){
    if(a==0){
      let conut =0
      this.ZCJF_form.option.forEach((item,index)=>{
      if(item.isOpen==0){
          conut++
      }
    })
    if(conut>=2){
         this.$message.warning('至少开启一个报名类型！')
         return
    }
    else{
       this.ZCJF_form.option.forEach((item,index)=>{
      if(index==index1)  {
        item.isOpen=a
      }
    })
    }
    }
    else{
    this.ZCJF_form.option.forEach((item,index)=>{
      if(index==index1)  {
        item.isOpen=a
      }
    })
    }





    },
   async changeOpenZCJF(isopen,record){
         if(record.fieldName=='baomingType'){
             this.$message.warning('报名类型不能关闭！')
             return
         }
        const res=await changeisOpen(record.id,isopen)
        if(res.code==0){
           this.$message.success('修改成功！')
           this.ZCJFList(this.meetCode,this.funcId)
        }
    },
    // 新增系统字段
   async ZCJFADDSys(){
     if( this.selectedRows.length!==0){
     let idList=[]
     this.selectedRows.forEach(item=>{
          idList.push(item.id)
       })
     const res= await postSystemType(this.meetCode,this.funcId,idList)
     if(res.code==0){
        this.$message.success('添加成功！')
        this.selectedRows=[]
        this.selectedRowKeys=[]
        this.ZCJFList(this.meetCode,this.funcId)
        this.ZCJF_add_visible=false
        this.ZCJFTitle=="新增"
        this.ZCJF_form={
          type:'text',
          title:'',
         isMust:false,
          option:[
          {
            name:'',
          }
         ],
         tips:''
         }
     }
     else{
         this.$message.warning(res.message)
     }
     }
     else{
          this.$message.warning('请选择系统字段！')
          return
     }
   }

}
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.content-div{
  display: flex;
  background-color: #edeff2;
  min-height: 900px;
  border-top: 10px solid #edeff2;
  .left-phone{
    background-color: #fff;
    width: 400px!important;
    height: 812px;
    border-left: 10px solid #edeff2;
    border-bottom: 10px solid #edeff2;
    position: relative;
    .left-banner{
      position: relative;
      .reminder{
        width: 100%;
        display: flex;
        justify-content: right;
        height: 25px;
        line-height: 25px;
        padding-right: 5px;
        position: absolute;
        top: 5px;
        //background-color: #C4E6FA;
        //color: #1890FF;
        .reminder-edit{
          width: 35px;
          height: 35px;
          text-align: center;
          line-height: 30px;
          border-radius: 40px;
          background-color: rgba(0,0,0,.5);
          font-size: 20px;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .isOpenCountdown{
        width: 100%;
        height: 30px;
        padding-top: 4px;
        background-color: rgba(0,0,0,.5);
        color: #eceaea;
        position: absolute;
        bottom: 0;
      }
      .NoShow{
        display: flex;
        justify-content: right;
      }
      .isShow{
        display: flex;
        justify-content: space-between;
      }
    }
    .left-content-img{
      width: 100%;
      height: 600px;
      position: absolute;
      top: 0;
    }
    //九宫格
    .left-content-list{
      width: 100%;
      height: 625px;
      overflow-y: scroll;
    }
    .rectangle{
      width: 95%;
      display: flex;
      margin: 5px;
      border-radius: 5px;
      //box-shadow: #dbdbdb 1px 1px 1px;
      box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
    }
    .fontSty{
      width: 100px;
      text-align: center;
      font-weight: bold;
      margin-top:10px;
      font-size: 12px;
    }
    .technicalSupport{
      text-align: center;
      width: 100%;
      .technicalSupport-top{
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: #ffffff;
        text-shadow: #000 1px 1px 3px;
      }
      .technicalSupport-bottom{
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: #fff;
        .delectIcon{
          margin-left: 10px;color: #45A5E6;font-size: 12px;margin-top: 2px;cursor: pointer;
        }
      }
    }
  }
  .right-table{
    flex: 1;
    width: 69%;
    background-color: #ffffff;
    margin-right: 10px;
    border-left: 10px solid #edeff2;
    .topCard{
      width: 100%;
      border-top: 1px solid #F5F5F5;
      border-bottom: 10px solid #edeff2;
    }
    .btn{
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
    }
    .table{
      .rpa-table-header{
        overflow: hidden !important;;
        margin-bottom:auto!important;
      }
      padding-left: 10px;
      margin-top: 10px;

      ::-webkit-scrollbar {
        width:10px;
        height: 10px;
      }
      ::-webkit-scrollbar-thumb{
        background-color: #C1C1C1;
      }
      ::-webkit-scrollbar-track {
        background-color: #F1F1F1;
      }
    }
  }
}
.modalDiv {
  width: 100%;
  display: flex;
  margin-top: -24px;
  margin-left: -24px;
  background-color: #fff;
  .menu {
    width: 150px;
    border: none;
  }
  .content {
    width: 100%;
    background-color: #fff;
    border-left: 1px solid #e7e7e7;
    .content-top {
      height: 200px;
      border-bottom: 1px solid #e7e7e7;
      display: flex;
      .top-icon {
        width: 10%;
        text-align: center;
        .icon {
          line-height: 200px;
          font-size: 40px;
          color: #929292;
          cursor: pointer;
        }
      }
      .imgList {
        width: 80%;
        display: flex;
        margin-top: 20px;
        .imgD {
          height: 160px;
          width: 150px;
          margin-right: 30px;
        }
      }
    }
    .content-list {
      height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
      .content-list-top {
        width: 98%;
        display: flex;
        justify-content: space-between;
        margin: 20px 0 0 0;
      }
      .content-list-card {
        width: 100%;
        margin-top: 20px;
        margin-left: 10px;
        .card {
          width: 15%;
          height: 180px;
          float: left;
          margin-left: 1%;
          margin-bottom: 1%;
          border: 1px solid #dcdbdb;
          .imgD {
            width: 130px;
            height: 120px;
            margin-top: 10%;
          }
          .font {
            width: 98%;
            margin-top: 10px;
            margin-left: 2px;
            text-align: center;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .content-two-list {
    .content-two-list-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 20px 0 0 10px;
    }
    .content-two-list-card {
      width: 100%;
      height: 425px;
      overflow-y: scroll;
      margin-top: 20px;
      margin-left: 10px;
      .addCard {
        width: 15%;
        border: 1px solid #dcdbdb;
        height: 180px;
        float: left;
        margin-left: 1%;
        margin-bottom: 1%;
        position: relative;
        .up {
          width: 100%;
          text-align: center;
          cursor: pointer;
        }
        .inputImg {
          width: 100%;
          height: 80%;
          opacity: 0;
          z-index: 999999;
          position: absolute;
          top: 0;
        }
        .upSave {
          position: absolute;
          top: 0;
          right: 10px;
          color: #45a5e6;
          cursor: pointer;
          z-index: 9999999999;
        }
        .imgSty {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          .img {
            width: 100%;
            height: 80%;
            background-color: #fff;
          }
        }
        .upInput {
          width: 93%;
          margin: auto;
        }
      }
      .card {
        width: 15%;
        height: 180px;
        float: left;
        margin-left: 1%;
        margin-bottom: 1%;
        border: 1px solid #dcdbdb;
        .imgTitle {
          width: 100%;
          height: 100%;
          position: relative;
        }
        .maskLayer {
          z-index: 9999999;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          color: #fff;
          background: rgba(0, 0, 0, 0.4);
          text-align: center;
          opacity: 0; //透明度为0 不显示遮罩层
        }
        .maskLayer:hover {
          opacity: 1;
        }
        .imgD {
          width: 6vw;
          height: 6vw;
          margin-top: 10%;
        }
        .font {
          width: 98%;
          margin-left: 2px;
          text-align: center;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
.surfacePlot {
  border: #dddddd 2px dashed;
  width: 172px;
  min-height: 172px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
  .surface {
    position: absolute;
    width: 100%;
    text-align: center;
    .add {
      font-size: 70px;
      color: #bdbcbc;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .font {
      font-size: 20px;
      color: #bdbcbc;
    }
  }
}
.uploadImg_img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  //background-color: #f4742f;
}
.upImgBtn{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  font-size: 12px;
  padding-left: 6%;
  background-color:rgba(0,0,0,.4);
  opacity: 0;
}
.upImgBtn:hover {
  opacity: 1;
}
.feature-list {
  width: 100%;
  //min-height: 800px;
  margin: auto;
  background: #fff;
  .titleTop {
    width: 98%;
    margin: auto;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box-add {
  border: 2px #c5c5c5 dashed;
  margin: 10px;
  height: 185px;
  line-height: 185px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.gutter-box {
  background: #fff;
  border: 1px solid #ececec;
  margin: 10px;
  .gutter-box-top {
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 15px;
    .image {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      overflow: hidden;
      background-color: #217dd4;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .gutter-box-top-title {
      margin-left: 15px;
      height: 100px;
      .gutter-box-top-title-title {
        font-size: 16px;
        height: 30px;
        font-weight: bold;
        color: black;
      }
      .gutter-box-top-title-type {
        height: 40px;
        color: #8c8a8a;
        margin-top: 5px;
      }
      .gutter-box-top-title-open {
        margin-top: 5px;
        .upDown {
          width: auto;
          margin-left: 15px;
          .upDown_up {
            color: black;
            font-size: 14px;
          }
          .upDown_up:hover {
            color: #1890ff;
          }
        }
      }
    }
  }
  .gutter-box-btn {
    height: 50px;
    margin-top: 20px;
    background-color: #f7f9fa;
    border-top: 1px solid #ececec;
    .gutter-box-btn-font {
      display: flex;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      cursor: pointer;
      color: black;
      justify-content: space-around;
      .font:hover {
        color: #1890ff;
      }
    }
  }
}
.uploadImg {
  border: #dddddd 2px dashed;
  width: 120px;
  height: 120px;

  //  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;

  .add {
    font-size: 50px;
    color: #bdbcbc;
  }
}

.upload_deyails {
  border: #dddddd 2px dashed;
  width: 220px;
  height: 120px;
  margin-left: 20px;
  text-align: center;
}
.upload_LiveBroadcast {
  border: #dddddd 2px dashed;
  width: 120px;
  height: 120px;
  margin-left: 20px;
  text-align: center;
}
.upload_gutter {
  border: #dddddd 2px dashed;
  width: 100%;
  height: auto;
}
.uploadpdf {
  width: 450px;
  height: 200px;
  background-image: url("../../../assets/img_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.upload_pdf {
  margin-top: 15px;
  text-align: center;
}
.content_LiveBroadcastCalendar {
  width: 100%;
  height: auto;
  .content_LiveBroadcastCalendar-top {
    width: 40%;
    height: 50px;
    display: flex;
    .add-file {
      width: 80px;
      //text-align: center;
      //border: 1px solid #dedcdc;
      //border-radius: 5px;
      height: 32px;
      //background-color: #b0b0b0;
      float: left;
      .fileAdd {
        width: 80px;
        height: 32px;
        opacity: 0;
        margin-top: -25px;
        margin-left: -15px;
      }
    }
  }
  .content_LiveBroadcastCalendar-mid {
    width: 100%;
    display: flex;
    .content_LiveBroadcastCalendar-mid-left {
      width: 40%;
    }
    .content_LiveBroadcastCalendar-mid-right {
      width: 60%;
      //background-color: #00FFFF;
    }
  }
  //.changeBjys{
  //  background-color: red !important;
  //}
  .changeBjys {
    background-color: #cd1313;
    color: #fff;
  }
}
.addressSpan {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bottomSty {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  z-index: 9;
  background-color: #f0f2f5;
  border-bottom: 1px solid #fff;
  .btn {
    width: 80%;
    display: flex;
    justify-content: right;
    margin-top: 10px;
  }
}
.add-file {
  width: 80px;
  height: 32px;
  float: left;
  position: relative;
}
.entrance-list{
  width: 100%;
  position: absolute;
  top: 0;
  margin-bottom: 10px;
  //height: 585px;
  overflow-y: scroll;
  color: #333333;
}
.b_item {
  float: left;
  padding: 5px;
}

.upImgModelBanner{
  width: 380px;
  height: 110px;
  text-align: center;
  background-color: #fcfcfc;
  border: #DDDDDD 2px dashed;
  border-radius: 10px;
  .contentBanner{
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin-top: 10px;
    color: #929292;
    .addModel{
      font-size: 25px;
    }
  }
  .topBanner{
    width: 370px; height: 100px;border-radius: 10px;position: absolute;top: 5px;left: 5px;
  }
}
.hoverBgcIconBanner{
  width: 380px;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,.4);
  top:0;
  border-radius: 10px;
  opacity: 0; //透明度为0 不显示遮罩层
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 25px;
  .iconDel{
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
.hoverBgcIconBanner:hover{
  opacity: 1;
}
.sampleGraph{
  width: 300px;
  display: flex;
  background-color: #45A5E6;
  color: #ffffff;
}
.ant-radio-button-wrapper:before{
  background-color: #ffffff;
}
.model-fun{
  border: 1px solid #d7d7d7;
  margin-top: 20px;
  color: #333333;
  min-height: 400px;
  .fun-top{
    line-height:50px;
    height: 50px;
    background-color: #F5F5F5;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
  }
  .fun-alert{
    height: 40px;
    width:600px;
    margin-top: 5px;
    margin-left: 5px;
  }
  .funRadio{
    min-width: 100px;
    float: left;
    //width: 100%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border: 1px solid #9c9b9b;
    color: #333333;
    margin-right: 10px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    margin-bottom: 15px;
  }
  .funRadio:hover{
    color: #1890FF;
  }
}
.iconLayer{
  background-color: rgba(0,0,0,.8);
  color: white;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  font-size: 10px;
  padding-top: 15px;
  text-align: center;
  opacity: 0;
}
.iconLayer:hover{
  opacity: 1;
}

.codeLink{
  display: flex;
  margin-top: 20px;
  .title{
    width: 120px;
    text-align: right;
    margin-right: 10px;
  }
  .iconStys{
    color: #45a5e6;
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
  }
  .codeBox{
    width:181px;
    height: 181px;
    border: 1px dashed rgb(0, 0, 0);
    display:flex;
    justify-content: center;
    align-items: center;
  }
}
.erweima {
  ::v-deep .ant-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
    min-height: 300px;

}
}
</style>
