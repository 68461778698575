<template>
  <div>
    <!--  技术支持设置-->
    <a-modal width="500px" v-model="visible" :footer="null" @cancel="handleClose">
      <template slot="title">
        <span style="font-weight: 550;color: #333333;font-size: 20px">技术支持设置</span>
      </template>
      <div style="margin-bottom: 10px">
        <div style="display: flex;margin-bottom: 10px">
          <span style="color: #333333">技术支持设置：</span>
          <a-switch
              :checked="isOpenCountdown === 1 ? true : false"
              checked-children="开启"
              un-checked-children="关闭"
              @change="switchCountDownBtn(isOpenCountdown === 0 ? 1 : 0)"
              default-checked
          />
        </div>

        <a-alert style="margin-top: 70px" type="info" show-icon >
          <span slot="message">
            该服务已生成订单，如需关闭此服务订单请点击<a-popconfirm
              title="关闭服务后将不会计入订单中，无法使用该服务，是否确认关闭?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="confirmClose"
          ><a>关闭服务</a></a-popconfirm>
          </span>
        </a-alert>
      </div>
    </a-modal>
  </div>
</template>

<script>

import {cancelConventionFuncOrder, conventionCode, putSwitch} from "@/service/MedicalConference";

export default {
  name: "TechnicalSupport",
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    meetCode:{
      type:String,
      default:'',
    },
  },
  data(){
    return{
      isOpenCountdown:0,
    }
  },
  created() {
    this.getConvention();
  },
  methods:{
    handleClose(){
      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）
    },
    switchCountDownBtn(isOpen){
      this.isOpenCountdown = isOpen;
      let data = {
        conferenceCode: this.meetCode, //会议编码
        isOpenSupport:isOpen,
      }
      this.putConvention(data);
    },
    //获取单个会议内容
    async getConvention() {
      const response = await conventionCode(this.meetCode);
      if (response.code === 0) {
        this.isOpenCountdown = response.data.isOpenSupport;
      } else {
        this.$message.warning(response.message)
      }
    },
    //修改会议接口
    async putConvention(data) {
      const response = await putSwitch(data);
      if (response.code === 0) {
        await this.getConvention();
      } else {
        this.$message.warning(response.message)
      }
    },
    async confirmClose(data) {
      // let date = {
      //   conventionCode:this.meetCode,
      //   type:'jszcqgg',
      // }
      const response = await cancelConventionFuncOrder(this.meetCode,'jszcqgg');
      if (response.code === 200) {
        this.handleClose();
      } else {
        this.$message.warning(response.message)
      }
    }
  }
}
</script>

<style scoped>
</style>
