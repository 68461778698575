<template>
<div>
<!--  服务介绍 -->
  <a-modal width="700px" v-model="visible" :footer="null" @cancel="handleClose">
    <template slot="title">
      <span style="font-weight: 550;color: #333333;font-size: 20px">服务介绍</span>
    </template>
    <div style="margin-bottom: 50px;height: 500px;overflow-y: scroll;" v-html="content"></div>
  </a-modal>
</div>
</template>

<script>

export default {
  name: "ServiceIntroductionDetail",
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    content:{
      type:String,
      default:'',
    },
  },
  data(){
    return{

    }
  },
  methods:{
    handleClose(){
      let visible = false;
      this.$emit("closeMainDetail", visible); //子组件向父组件传值（开关）
    },
  }
}
</script>

<style scoped>
.bottomSty {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background-color: #fff;
  display: flex;
  justify-content: right;
  z-index: 1;
}
.cost{
  font-size: 12px;
  margin-top: 5px;
  color: #333333;
}
.pay{
  color: #00C024;
  font-size: 18px;
}
.original{
  font-size: 12px;
  margin-left: 15px;
  color: #9c9b9b;
  text-decoration: line-through;
}
</style>
