<template>
<!--背景图-->
  <div>
    <a-modal
        :dialogStyle="{ 'top': '5%',}"
        width="1300px"
        title="背景图"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
    >
      <div class="modalDiv">
        <div class="left">
          <a-menu
              class="menu"
              v-model="selectId"
              mode="inline">
            <a-menu-item
                v-for="(item, index) in templateList"
                :key="item.id"
            >{{ item.title }}</a-menu-item>
          </a-menu>
        </div>

        <div class="content">
          <a-spin :spinning="spin">
            <div class="content-top">
              <div>颜色搜索：</div>
              <div class="top-right">
<!--                <div v-for="(list,index) in colorList" :key="index" class="colorSty" :style="{backgroundColor:list,border:selectColors === list ? '2px solid #585858' : ''}" @click="selectColor(list)">-->
                <div v-for="(list,index) in colorList" :key="index" class="colorSty" :style="{backgroundColor:list,width:selectColors === list ? '45px' : '',height:selectColors === list ? '25px' : ''}" @click="selectColor(list)">
                </div>
<!--                {{selectColors}}-->
              </div>
            </div>
            <div class="content-list">
              <a-row :gutter="[16,16]">
                <a-col :span="5" v-for="(item,index) in ImgList" :key="index">
                  <div class="listSty">
                    <img style="width: 100%;height: 100%" :src="item.bgImg" alt="">
                    <div class="box-y">
                      <div>
                        <!--                      <admin_con style="font-size: 40px" type="icon-xuanze" />-->
                        <p
                            style="
                      color: white;
                      margin: 0 auto;
                      font-size: 17px;
                      font-weight: 550;
                    "
                        >
                          <a-button type="primary" @click="selectBGI(item.bgImg)">
                            确认选择
                          </a-button>
                        </p>
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-spin>
        </div>

      </div>
    </a-modal>
  </div>
</template>

<script>
import {selectAll, selectAllColor} from "@/service/photoCommander";

export default {
  name: "BackgroundImage",
  props:{
    visible:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return{
      spin:false,
      selectId:['1'],
      selectColors:'',
      templateList:[
        {
          id:'1',
          title:'系统背景图'
        }
      ],
      colorList:[],
      ImgList:[]
    }
  },
  created() {
    this.getSelectAllColors();
    this.getSelectImgs();
  },
  methods:{
    //获取颜色列表
    async getSelectAllColors() {
      const response = await selectAllColor();
      if (response.code === 200) {
        this.colorList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取图片列表
    async getSelectImgs() {
      let data = {
        type:this.selectColors
      }
      const response = await selectAll(data);
      if (response.code === 200) {
        this.ImgList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    handleCancel(){
      let flag = false;
      this.$emit("closeMainBackgroundImage", flag); //子组件向父组件传值（开关）
    },
    selectColor(color){
      if(this.selectColors === color){
        this.selectColors = '';
      }else {
        this.selectColors = color;
      }
      this.getSelectImgs();
    },
    selectBGI(bgImg){
      this.$emit("checkListBackgroundImage", bgImg); //子组件向父组件传值（开关）
      this.handleCancel();
    },
  }
}
</script>

<style scoped lang="scss">
.ant-col-5 {
  width: 20%;
}
.modalDiv {
  width: 103.8%;
  display: flex;
  //padding: 0!important;
  //margin: 0!important;
  margin-top: -24px!important;
  margin-left: -24px!important;
  background-color: #fff;

  .menu {
    width: 150px;
    border: none;
  }
  .content {
    width: 100%;
    background-color: #fff;
    border-left: 1px solid #e7e7e7;
    .content-top {
      width: 100%;
      min-height: 50px;
      border-bottom: 1px solid #e7e7e7;
      box-shadow: 0 3px 10px rgba(175, 175, 175, 0.5);
      display: flex;
      .top-right{
        display: flex;
        align-items: center;
        height: 35px;
        line-height: 35px;
        //padding-top: 8px;
        margin-top: 7px;
        padding-left: 10px;
        background-color: #F5F5FF;
      }
      .colorSty{
        width: 40px;
        height: 20px;
        border-radius: 3px;
        margin-right: 10px;
        cursor: pointer;

      }
      .selectColor{
        border: 2px solid #585858;
      }
    }
    .content-list {
      height: 550px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 20px;
      .listSty{
        width: 100%;
        height: 300px;
        border: 1px solid #F5F5F5;
      }
      .box-y {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 91%;
        height: 94%;
        background: rgba(0, 0, 0, 0.4) no-repeat center;
        display: none;
      }
      .listSty:hover .box-y {
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

</style>
