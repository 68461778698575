<template>
  <div style="height: 100%">
    <!--  确认产品信息-->
    <a-modal width="1250px"
             :dialogStyle="{ 'top': '5%',}"
             v-model="visible"
             :footer="null"
             @cancel="handleClose">
      <template slot="title">
        <span style="font-weight: 550;color: #333333;font-size: 20px">确认产品信息</span>
      </template>
      <div class="productInfor">
        <!--      <div :style="{height:screenHeight+'px'}" class="ModelSty">-->
        <div class="ModelSty">
          <div>
            <a-tag color="blue">
              下单说明
            </a-tag>
            <span style="color: #333333">请确认产品信息后提交订单，如有优惠券可在支付时选择使用，<span style="color: red">最终实付金额以支付订单时为准。</span></span>
          </div>

          <div class="marginTopSty">
            <div class="product">产品清单</div>
            <div class="marginTopSty">
              <a-collapse default-active-key="1">
                <a-collapse-panel key="1">
                  <template slot="header">
                    <div style="width: 100%;display: flex;justify-content: space-between">
                      <div style="font-weight: 900;color: #333333;font-size: 16px">预付费产品（{{data.count}}）</div>
                      <div>
                        <span style="font-size: 16px;color: #333333">应付合计金额</span>
                        <span style="margin-left: 10px;"><a-tag v-if="data.gsTotal" color="#F9EBE5"><span style="color: #FE6B22">共省{{data.gsTotal}}元</span></a-tag></span>
                        <span style="color: #FE6B22">{{data.sfTotal}}元</span>
                      </div>
                    </div>
                  </template>

                  <a-table :columns="columns" :data-source="data.orderData" :pagination="false" style="margin: -16px -15px">
                  <span slot="serviceIntroduction" slot-scope="text, record">
                    <a @click="openDetail(record)">详情</a>
                  </span>
                    <span slot="purchaseQuantity" slot-scope="text, record">
                    x {{text}}
                  </span>
                    <span slot="orderAmount" slot-scope="text, record">
                    <span v-if="record.orderMoneyYj !== record.orderMoney" style="text-decoration: line-through;color: #c6c6c6">原价{{record.orderMoneyYj}}元</span>
                    <span style="color: #FE6B22"> {{record.orderMoney}}元</span>
                  </span>
                  </a-table>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>

          <!--          优惠卷-->

          <div class="marginTopSty">
            <div class="product">选择优惠券</div>
            <a-tabs default-active-key="1">
              <a-tab-pane key="1">
                <span slot="tab">
                  代金卷（{{CouponsList.length}}）
                </span>
              </a-tab-pane>
            </a-tabs>
            <div style="color: #333333" >
              <div v-if="isSelect">
                <a-checkbox v-model="isSelect" @change="isSelectBtn">
                <span style="color: #333333">使用代金券抵扣 {{selectCouponObj.favorablePrice}}元
                  <!--                  <a>兑换优惠卷</a>-->
                </span>
                </a-checkbox>
              </div>

              <p style="margin-top: 5px">
                您有 {{CouponsListCount}} 张代金券，本次有 {{userCoupon}} 张可用。

                <span v-if="NoUserCoupon">
                  <a @click="HideUnavailableCoupons(isHideUnavailableCoupons)">
                    <span v-if="isHideUnavailableCoupons">隐藏不可用券({{NoUserCoupon}})</span>
                    <span v-else>显示不可用券({{NoUserCoupon}})</span>
                  </a>
                </span>
              </p>
            </div>

            <div>
              <a-row>
                <!--              <a-col :span="6">-->
                <!--                <div :class="1?'voucher':'voucherNo'">-->
                <!--                  <div class="voucher_left"><div>代</div><div>金</div><div>卷</div></div>-->
                <!--                  <div class="voucher_right">-->
                <!--                    <div><span class="money">￥408.00</span> 满1166元可使用</div>-->
                <!--                    <div>适用产品  <span class="SX">|</span>  付费场景  <span class="SX">|</span>  使用限制</div>-->
                <!--                    <div>2024/06/07 - 2024/08-07</div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </a-col>-->
                <a-col :span="6" v-for="(item,index) in CouponsList" :key="index">
                  <div class="titleImg"
                       @click="selectCouponBtn(item)"
                       :style="{border: selectCoupon === item.id ? '2px solid #45A5E6':''}">
<!--                    可用优惠卷-->
                    <img v-if="item.orderAvailable" class="img" src="../../assets/paidImg/couponY.png" alt="">
<!--                    不可用优惠卷-->
                    <img v-else class="img" src="../../assets/paidImg/noCoupon.png" alt="">

                    <div class="title-coupon-div">
                      <div style="width: 120px;text-align: center">
                        <div><span class="couponMoneyIcon">￥</span><span class="money">{{item.favorablePrice}}</span></div>

                        <div class="mark" :style="{color: item.orderAvailable ? '#e28700':'#666666'}">满{{item.thresholdPrice}}使用</div>
                        <div class="markSY" v-if="remainingDays(item.endTime) > 0">剩余{{remainingDays(item.endTime)}}天过期</div>
                      </div>
                      <div style="width: 155px;font-size: 12px;text-align: center">
                        <div class="coupon-right-title">{{item.couponName}}</div>
                        <div class="coupon-right-time" :style="{color: item.orderAvailable ? '#e28700':'#666666'}">{{ item.beginTime }}-{{ item.endTime }}</div>
                        <div class="coupon-right-tag">
                          <a-tag color="#ffffff"
                                 v-for="(tag,index) in item.label" :key="index"
                                 style="margin-bottom: 5px">
                            <span class="tag-font" :style="{color: item.orderAvailable ? '#e28700':'#666666'}">{{tag}}</span>
                          </a-tag>
                        </div>
                      </div>
                    </div>

                  </div>
                </a-col>


              </a-row>
            </div>

            <div style="margin-top: 15px;margin-bottom: 30px">
              <a-checkbox v-model="checkVal">
                <span style="color: #333333">我已阅读并同意<a>《医驰云服务购买说明》</a>和<a>《退款规则》</a></span>
              </a-checkbox>
            </div>
          </div>





        </div>
        <div class="bottomSty">
          <div style="display: flex">
            <div class="cost">实付金额  <span class="payMoney">{{data.sfTotal}}元</span></div>
          </div>
          <a-button class="payMoneyBtn" type="primary" @click="handleOk">
            去支付
          </a-button>
        </div>
      </div>
    </a-modal>

    <!--    订单支付  -->
    <pay-indent
        v-if="payVisible"
        :orderNumber="data.orderData[0].orderNumber"
        :visible="payVisible"
        :publishedConvention="publishedConvention"
        :isNextGetOrderList="isNextGetOrderList"
        :conferenceCode="conferenceCode"
        v-on:closeMain="closeMainPay"
    />
    <ServiceIntroductionDetail
        :visible="detailVisible"
        :content="detailContent"
        v-on:closeMainDetail="closeMainDetail"
    />
  </div>
</template>

<script>
import payIndent from "@/components/PaidView/payIndent";
import ServiceIntroductionDetail from "@/components/PaidView/ServiceIntroductionDetail";
import {getServiceInfo} from "@/service/authentication";
import {
  conventionNoPayOrder,
  selectCouponByOrderAvailable,
  selectCouponByOrderNumber, useForOrderNumber
} from "@/service/MedicalConference";
const columns = [
  {
    title: '产品名称',
    dataIndex: 'serviceName',
    key: 'serviceName',
  },
  {
    title: '服务介绍',
    align:'serviceIntroduction',
    scopedSlots: { customRender: 'serviceIntroduction' },
  },
  {
    title: '类型',
    dataIndex: 'purchaseType',
    key: 'purchaseType',
    align:'center',
  },
  {
    title: '单价',
    key: 'orderMoney',
    align:'center',
    dataIndex: 'orderMoney',
  },
  {
    title: '数量',
    key: 'purchaseQuantity',
    dataIndex: 'purchaseQuantity',
    align:'center',
    scopedSlots: { customRender: 'purchaseQuantity' },
  },
  {
    title: '时长',
    key: 'duration',
    align:'center',
    dataIndex: 'duration',
  },
  {
    title: '订单金额',
    align:'right',
    key: 'serialTotalMoney',
    dataIndex: 'serialTotalMoney',
    scopedSlots: { customRender: 'orderAmount' },
  },
];

export default {
  name: "ProductInfor",
  components:{ServiceIntroductionDetail, payIndent},
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    // data:{
    //   type:Object,
    //   default:{},
    // },
    orderNumber:{
      type:String
    },
    //支付成功后是否调用发布会议接口
    publishedConvention:{
      type:Boolean,
      default:false,
    },
    //支付成功后是否调用获取订单管理接口，刷新页面展示
    isNextGetOrderList:{
      type:Boolean,
      default:false,
    },
    conferenceCode:{
      type:String,
      default:'',
    },
  },
  created() {
    // if(this.visible){
    //   alert(1)
    //   this.getIsShowIssueBtn();
      this.getCouponsUsedNotPaid();
    // }
  },
  data(){
    return{
      data:{},
      columns,
      payVisible:false,
      checkVal:false,
      detailVisible:false,
      detailContent:'',
      selectCoupon:null,
      selectCouponObj:{},

      userCoupon:null,
      NoUserCoupon:null,

      CouponsList:[], //优惠卷
      CouponsListCount:0,
      isSelect:false,

      isHideUnavailableCoupons:true,
    }
  },

  methods:{
    //隐藏不可用券
    async HideUnavailableCoupons(isOpen) {
      this.isHideUnavailableCoupons = !isOpen;
      if(isOpen){ //隐藏
        let list = {
          orderAvailable:isOpen
        }
        const res = await selectCouponByOrderAvailable(this.orderNumber, list)
        if (res.code === 200) {
          this.CouponsList = res.data;
          this.CouponsListCount = res.count;
        }
      }else { //全部
        const res = await selectCouponByOrderAvailable(this.orderNumber)
        if (res.code === 200) {
          this.CouponsList = res.data;
          this.CouponsListCount = res.count;
        }
      }
    },
    isSelectBtn(e) {
      if(!this.isSelect){
        this.selectCoupon = null;
        this.selectCouponObj = {};
        this.getIsShowIssueBtn();
      }
    },
    //获取已使用但未付款的优惠卷
    async getCouponsUsedNotPaid(list) {
      const response = await selectCouponByOrderNumber(this.orderNumber)
      if (response.code === 200) {
        if(response.data){ //有已使用但未付款的优惠卷
          this.CouponsList = [response.data];
          this.selectCoupon = response.data.id;
          this.selectCouponObj = response.data;
          this.isSelect = true;
          let data = {
            cruId:response.data.id
          }
          await this.getIsShowIssueBtn(data);
        }else { // 没有已使用但未付款的优惠卷   获取全部优惠卷
          //加载信息
          await this.getIsShowIssueBtn();
          const res= await selectCouponByOrderAvailable(this.orderNumber,list)
          if (res.code === 200) {
            this.CouponsList = res.data;
            this.CouponsListCount = res.count;
            this.selectCoupon = null;
            this.isSelect = false;
          }
        }
        const result = this.countIsOpen(this.CouponsList);
        this.userCoupon = result.trueCount;
        this.NoUserCoupon = result.falseCount;

        // console.log(this.userCoupon,this.NoUserCoupon)
      } else {
        this.$message.warning(response.message);
      }
    },
    countIsOpen(array){
      const trueCount = array.filter(item => item.orderAvailable).length;  // 计算 isOpen 为 true 的数量
      const falseCount = array.filter(item => !item.orderAvailable).length; // 计算 isOpen 为 false 的数量

      return {
        trueCount,
        falseCount,
      };
    },
    // 获取未支付的订单列表
    async getIsShowIssueBtn(data) {
      console.log('执行了几次')
      const response = await conventionNoPayOrder(this.orderNumber,data)
      if(response.code === 200){
        this.data = response.data;
      }else {
        this.$message.warning(response.message);
      }
    },
    closeMainPay(val){
      this.payVisible = val;
      this.handleClose();
    },
    openDetail(row){
      this.getInformation(row.serviceCode);
    },
    selectCouponBtn(item){
      if(item.orderAvailable){
        if(item.id === this.selectCoupon){
          this.selectCoupon = null;
          this.isSelect = false;
          this.getIsShowIssueBtn();
        }else {
          this.selectCoupon = item.id;
          this.selectCouponObj = item;
          this.isSelect = true;
          let data = {
            cruId:item.id
          }
          this.getIsShowIssueBtn(data);
        }
      }
    },
    //获取服务介绍
    async getInformation(code) {
      const response = await getServiceInfo(code);
      if (response.code === 200) {
        this.detailContent = response.data ? response.data.serviceIntroduction : '暂无详情';
        this.detailVisible = true;
      } else {
        this.$message.warning(response.message);
      }
    },
    closeMainDetail(val){
      this.detailVisible = val;
    },
    async handleOk() {
      // if(!this.checkVal){
      //   this.$message.warning("请先同意医驰云服务购买说明和退款规则！");
      //   return
      // }
      if (this.data.orderData.length) {
        //调用优惠卷后调用该接口
        if (this.selectCoupon) {
          let data = {
            "id": this.selectCoupon,
            "orderNumber": this.orderNumber
          }
          await useForOrderNumber(data)
        }
        // this.payVisible = true;
        this.$emit("OpenPayModel");
        setTimeout(() => {
          let visible = false;
          this.$emit("closeMainProduct", visible); //子组件向父组件传值（开关）
        }, 1000)
      } else {
        this.$message.warning("当前无订单！");
      }
    },
    handleClose(){
      let visible = false;
      this.$emit("closeMainProduct", visible); //子组件向父组件传值（开关）
    },
    remainingDays(startDate) {
      if (!startDate) return null; // 如果没有选择日期，返回 null
      const today = new Date(); // 获取当前日期
      const start = new Date(startDate); // 将输入的日期转换为 Date 对象
      const timeDiff = start - today; // 计算时间差（毫秒）
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // 转换为天数
      return daysDiff+1; // 返回剩余天数
    },
  }
}
</script>

<style scoped lang="scss">
.ant-col-5 {
  width: 20%;
}
.productInfor{
  height: 600px;
  //background-color: red;
  overflow-y: scroll;
}
.marginTopSty{
  margin-top: 30px;
}
.bottomSty {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background-color: #fff;
  display: flex;
  justify-content: right;
  z-index: 99;
}
.cost{
  margin-top: 5px;
  color: #333333;
}
.pay{
  color: #00C024;
  font-size: 18px;
}
.original{
  font-size: 12px;
  margin-left: 15px;
  color: #9c9b9b;
  text-decoration: line-through;
}
.ModelSty{
  margin-bottom: 50px;
  overflow-y: scroll;
}
.product{
  font-weight: bold;
  font-size: 18px;
  border-left: 5px solid #108DE9;
  padding-left: 10px;
  color: #333333;
}
.voucher{
  width: 98%;
  margin: auto;
  //background-color: rosybrown;
  height: 100px;
  margin-bottom: 10px;
  background-image: url("../../assets/paidImg/pitchOn.png");
  background-repeat: no-repeat;
  background-size: 100% 100px;
  display: flex;
  .voucher_left{
    width: 10%;
    text-align: center;
    margin-top: 5%;
    color: white;
  }
  .voucher_right{
    margin: 10px;
    color: #108DE9;
  }

}
.voucherNo{
  width: 98%;
  margin: auto;
  height: 100px;
  margin-bottom: 10px;
  background-image: url("../../assets/paidImg/pitchNo.png");
  background-repeat: no-repeat;
  background-size: 100% 100px;
  display: flex;
  .voucher_left{
    width: 10%;
    text-align: center;
    margin-top: 5%;
    color: #838383;
  }
  .voucher_right{
    margin: 10px;
    color: #A1A1A1;
  }
}

.money{
  font-size: 25px;
  font-weight: bold;
  margin-right: 10px;
}
.SX{
  margin-left: 7px;
  margin-right: 7px;
}
.payMoney{
  color: red;
  font-size: 24px;
  font-weight: bold;
}
.payMoneyBtn{
  margin-left: 20px;
  margin-top: 8px;
  width: 100px;
}

.titleImg{
  width: 290px;
  cursor: pointer;
  height: 113px;
  //text-align: center;
  border-radius: 3px;
  padding: 2px;
  margin-bottom: 10px;
  position: relative;

  .img{
    width: 280px;
  }
  .title-coupon-div{
    position: absolute;
    left: 0;
    top: 10px;
    display: flex;
    color: white;
    z-index: 9;
    .couponMoneyIcon{
      font-size: 20px;font-weight: bold;
    }
    .money{
      font-size: 30px;font-weight: bold;
    }
    .mark{
      margin-bottom: 5px;font-weight: bold;letter-spacing: 1px;
    }
    .coupon-right-title{
      font-size: 16px;margin-bottom: 5px;
    }
    .coupon-right-time{
      margin-bottom: 5px;font-weight: bold;
    }
    .coupon-right-tag{
      width: 100%;padding-left: 5px;
      .tag-font{
        font-size: 10px;font-weight: 400;
      }
    }
  }
}
</style>
