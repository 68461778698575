<template>
<!--图标模版-->
  <div>
    <a-drawer
        :get-container="false"
        @close="PCSetup_cancel"
        :body-style="{ paddingBottom: '80px'}"
        width="800px"
        :visible="visible"
        title="图标模版">
      <a-spin :spinning="spin">
      <div style="display: flex;">
        <div>模版示例：</div>
        <div>
          <img style="width: 200px;height:200px;" :src="selectIcon.previewImg" alt="">
        </div>
        <div style="margin-top:180px;margin-left: 5px">
          <a @click="openSelectIcon">修改模版</a>
        </div>
      </div>
      <div style="margin-top: 30px;display: flex;">
        <div style="width: 70px;text-align: right">图标：</div>
        <div style="width: 700px;">
          <a-row :gutter="[16,16]">
            <a-col :span="5" v-for="item in funList" @click="selectIconBtn(item)">
              <div class="listSty" :style="{border:slectSty.id === item.id?'3px solid #43a6ff':''}">
                <img style="width: 90px;height:90px;margin-left: 15px;margin-top: 10px" :src="item.url" alt="">
                <div class="font">{{ item.name }}</div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      </a-spin>
      <div
          :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
              }"
      >
        <a-button  @click="PCSetup_cancel">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="PCSetup_ok">
          确认
        </a-button>
      </div>
    </a-drawer>

<!--    <SystemIcons-->
<!--        v-if="systemIconsVisible"-->
<!--        :visible="systemIconsVisible"-->
<!--        v-on:closeMainSystemIcons="closeMainSystemIcons"-->
<!--        v-on:checkListSystemIcons="checkListSystemIcons"-->
<!--    ></SystemIcons>-->
  </div>
</template>

<script>
import {iconList, icontemp} from "@/service/photoCommander";
import SystemIcons from "@/components/meet/SystemIcons";

export default {
  name: "IconTemplate",
  components:{SystemIcons},
  props:{
    visible:{
      type:false,
      default:false,
    },
    listObj:{
      type: Object
    },
  },
  data(){
    return{
      curPage:1,
      funList:[],
      selectIcon:{},
      spin:false,
      // systemIconsVisible:false,
      previewObj:{},
      slectSty:{},
    }
  },
  created() {
    console.log(this.listObj)
    this.getTemplateList(this.listObj.iconTempId);
  },
  methods:{
    //获取单个图标模板内容
    async getTemplateList(id) {
      if(id){
        this.spin = true;
        let data = {
          curPage: 1,
          id:id
        };
        const response = await icontemp(data);
        if (response.code === 0) {
          this.selectIcon = response.data[0];
          await this.getTemplateIcon();
        } else {
          this.$message.warning(response.message);
        }
        this.spin = false;
      }
    },
    //获取图标数据
    async getTemplateIcon() {
      let data = {
        curPage: this.curPage,
        pageSize: 99999,
        icontempId: this.selectIcon.id, //九宫格模板id
      };
      const response = await iconList(data);
      if (response.code === 0) {
        this.funList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },

    PCSetup_cancel(){
      let flag = false;
      this.$emit("closeMainSystemIcons", flag); //子组件向父组件传值（开关）
    },
    PCSetup_ok(){
      if(!this.slectSty.id){
        this.$message.warning("请选择图标！")
      }else {
        this.$emit("checkListIconTemplate", this.slectSty); //子组件向父组件传值（开关）
        this.PCSetup_cancel();
      }
    },
    openSelectIcon(){
      // this.systemIconsVisible = true;
      this.PCSetup_cancel();
      this.$emit("checkselectTemplate");
    },

    closeMainSystemIcons(val) {
      this.systemIconsVisible = val;
    },
    checkListSystemIcons(val){
      this.previewObj = val;
      //选择的模板id
      this.getTemplateList(val.iconTemplateId);
    },
    selectIconBtn(item){
      this.slectSty = item;
    },
  }
}
</script>

<style scoped lang="scss">
.ant-col-5 {
  width: 20%;
}
.listSty{
  background-color: black;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  width: 120px;
  height: 120px;
  margin: auto;
  .font{
    color: #F7F9FA;
    text-align: center;
    margin-top: -2px;
    font-size: 12px;
  }
}
</style>
