<template>
  <div>
    <!--  主页模版-->
    <a-modal
        @ok="OkTemplate"
        @cancel="cancelTemplate"
        width="1200px"
        :visible="visible"
        title="主页模版">
      <div class="templated-img">
        <div style="display: flex;justify-content: center">
          <!--              <a-icon style="color: #9c9b9b;font-size: 20px" type="check-circle" />-->
          <admin_con style="font-size: 25px" type="icon-icon_duihao-mian"/>
          <div style="margin-left: 5px;font-size: 16px"><span style="color: red">*</span> 系统模板</div>
        </div>
      </div>
      <a-row>
        <!-- 空数据 -->
        <div v-if="datasource.length == 0">
          <NullDataPrompt message="暂无数据"></NullDataPrompt>
        </div>
        <a-col
            v-else
            :span="4"
            v-for="item in datasource"
            :key="item.id"
        >
          <div
              :style="{
                  width: innerWidths > 0 && innerWidths < 1920 ? '92%' : '90%',
                }"
              class="iconSty"
          >
            <img
                :class="{ imgSelect: selectId === item.id }"
                @click="selectTemplate(item)"
                :src="item.egImg"
                class="img"
            />
            <!--                <div>-->
            <!--                  <a-tag :color="item.name === '模版一（九宫格）'?'green':item.name === '模版二（方块格）'?'blue':''" class="tag">{{ item.name }}</a-tag>-->
            <!--                </div>-->
            <img v-if="item.mode === 'free'" class="pitchOn" src="../../assets/paidImg/free.png">
            <img v-if="item.mode === 'paid'" class="pitchOn" src="../../assets/paidImg/paid.png">
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";
import NullDataPrompt from "@/components/NullDataPrompt";
import {getPage} from "@/service/columnmanageapi";

export default {
name: "TemplateList",
  components:{admin_con: admin_icon,NullDataPrompt},
  props:{
    visible:{
      type:Boolean,
      default:true,
    }
  },
  data(){
    return{
      innerWidths:null,
      datasource: [],
      total:'',
      selectId:null,
      tempUrl:'',
    }
  },
  created() {
    this.innerWidths = window.innerWidth; //屏幕宽度
    this.load();
  },
  methods:{
    selectTemplate(item) {
      this.selectId = item.id;
      this.tempUrl = item.tempUrl;
    },
    load() {
      getPage({
        isOpen: 1,
        // mode:this.liveStatus,
        curPage: 1,
        pageSize: 99999,
      }).then((res) => {
        this.datasource = res.data;
        this.total = res.count;
      });
    },
    OkTemplate(){
      if(this.tempUrl){
        this.$emit("selectTemplate", this.tempUrl); //子组件向父组件传值
        this.cancelTemplate();
      }else {
        this.$message.warning("请先选择主页模板！")
      }
    },
    cancelTemplate(){
      let visible = false;
      this.$emit("closeMainTemplate", visible); //子组件向父组件传值（开关）
    },
  }
}
</script>

<style scoped lang="scss">
.templated-img{
  display: flex;justify-content: space-between;width: 98%;margin: auto;margin-bottom: 10px;
}
.iconSty {
  margin-bottom: 30px;
  position: relative;
  .tag {
    position: absolute;
    left: 5px;
    top: 1%;
  }
  .img {
    width: 100%;
    margin: auto;
    //height: 100%;
    display: flex;
    border: 1px solid #F5F5F5;
    justify-content: center;
  }
  .pitchOn{
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: auto;
  }
  .img:hover {
    border: 1px solid #1890ff;
  }
  .imgSelect {
    border: 3px solid #1890ff;
    box-shadow: 5px 5px 5px #63caff;
  }
}
</style>
