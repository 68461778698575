import{get,put,post}from"./base";
import qs from "qs";
export function  getTitles(identify){
    return get('/v3/jobtitles',{identify,'pageNum':1,'pageSize':'9999'})
}
export function  getLoginDepartment(){
    // return get('/v3/offices',{'pageNum':1,'pageSize':'9999'})
    return get('/api/admin2/offices',{'pageNum':1,'pageSize':'9999'}) //冯修改
}
/**会员医院待清洗 **/
export function  getHospitalCleans(){
    return get('/v3/hospitalcleans')
}
//修改医院名称
export function putHospitalCleans(hospital_name,data){
    return put('/v3/hospitalclean/' + hospital_name,qs.stringify(data))
}
//置空医院名称
export function putCleanHospital(hospital_name){
    return put('/v3/hospital/clean/' + hospital_name)
}


/**会员科室待清洗 **/

export function  getOfficeCleans(pageNum){
    return get('/v3/officescleans',{pageNum})
}

export function putOfficeCleans(hospital_offices,data){
    return put('/v3/officesclean/' + hospital_offices,qs.stringify(data))
}
//置空医院名称
export function putCleanOffice(hospital_offices){
    return put('/v3/offices/clean/' + hospital_offices)
}

/**会员职称待清洗 **/

export function  getTitleCleans(pageNum){
    return get('/v3/jobtitlecleans',{pageNum})
}

export function putTitleCleans(job_title,data){
    return put('/v3/jobtitleclean/' + job_title,qs.stringify(data))
}
//置空职称
export function putTitleClean(job_title){
    return put('/v3/jobtitle/clean/' + job_title)
}
