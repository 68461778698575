//全局配置文件

/**
 * 功能类型列表
 * @param time
 * @returns {[{}]}
 */
export function codeList() {
    const list = [
        {
            code: "HYC1",
            isTrue:true, //true为重复
            isTitle:true,
            name: "欢迎辞",
            type:'【图片类型】',
        },{
            code: "HYTZ",
            isTrue:false,
            isTitle:true,
            name: "会议通知",
            type:'【pdf】',
        },
        // {
        //     code: "HYBM",
        //     isTrue:false,
        //     isTitle:true,
        //     name: "会议报名",
        //     type:'【表单】',
        // },
        {
            code: "YZT1",
            isTrue:true, //true为重复
            isTitle:true,
            name: "云展厅",
            type:'【图文链接】',
        },{
            code: "ZCBM",
            isTrue:false,
            isTitle:false,
            name: "自定义表单",
            type:'',
        },{
            code: "TEXT",
            isTrue:false,
            isTitle:false,
            name: "富文本类型",
            type:'',
        },{
            code: "HTML",
            isTrue:false,
            isTitle:false,
            name: "链接类型",
            type:'',
        },{
            code: "HYC",
            isTrue:false,
            isTitle:false,
            name: "图片类型",
            type:'',
        },{
            code: "YZT",
            isTrue:false,
            isTitle:false,
            name: "图文类型",
            type:'',
        },{
            code: "GRZL",
            isTrue:false,
            isTitle:false,
            name: "个人资料",
            type:'',
        },
    ]
    return list;
}


//功能图标列表
export function iconModelList() {
    const list = [
        {
            id:1, //注册
            img:'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/6d4d24ac-3178-47a9-9f83-23301e736f25.png',
        },{
            id:2, //直播
            img:'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/4722bba3-8ae2-4050-837d-aed197a2d1ce.png',
        },{
            id:3, //酒店住宿
            img:'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/b211b226-1018-4a1a-8062-e943075ff63b.png',
        },{
            id:4, //功能定义
            img:'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/6cd75504-f0e2-409a-b0d7-560480493760.png',
        },{
            id:5, //飞机
            img:'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/80677867-d002-4b22-9e8b-1d54f562bde7.png',
        },{
            id:6, //发文章
            img:'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/d5bb7208-da76-42e9-9f52-494f8981eafe.png',
        },{
            id:7, //点餐
            img:'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/9d0ba494-99fd-42f8-9186-b8cacf2be5fe.png',
        },{
            id:8, //参与人次
            img:'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/1373e9ad-81d2-4aea-82cf-09a308819e84.png',
        },{
            id:9, //编辑文章
            img:'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/223789ff-fea4-42da-85bf-b94795539cf4.png',
        },{
            id:10, //报名管理
            img:'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/27ba7aa4-252f-426b-8643-7c4257a0c068.png',
        },
    ];
    return list;
}
