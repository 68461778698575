<template>
<div>
<!--  数据报告-->
  <a-modal
      width="75%"
      :dialogStyle="{ 'top': '5%',}"
      title="数据报告"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div class="DataReport" style="margin: -23px -23.6px">
      <div class="DataReport-menu">
        <a-menu
            style="border-right: none"
            v-model="selectId"
            mode="inline">
          <a-menu-item key="1" @click="selectMenu('1')">
            <span>微网站主页</span>
          </a-menu-item>
          <a-menu-item v-for="(item) in menuList" :key="item.funcId" @click="selectMenu(item.funcId,item.funcCode)">{{item.title}}</a-menu-item>
          <!-- <a-menu-item key="2" @click="selectMenu('2')">
            <span>表单收集</span>
          </a-menu-item> -->
        </a-menu>
      </div>
      <div class="DataReport-cont">
        <a-spin :spinning="spinning">
       <!-- 数据 -->
        <div v-if="selectId[0] === '1'" style="width: 95%;margin: auto;">
          <!--        顶部数据展示-->
          <a-row>
            <a-col :span="8" style="padding-right:30px;margin-bottom: 10px;">
              <div class="DataGrid">
                <div class="boxColor1">
                </div>
                <div class="dataBox">
                  <div class="ViewingData">主页浏览总人数(ip)
                    <span>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span> 即访客的独立ip数： <br>多个用户在同一网络ip地址下访问时,只计算1个ip； <br> 统计的是所有访问用户的独立ip数；</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                    </span>
                  </div>

                  <div class="number"> <h2>{{HomepageAccess.homepageTotal}}</h2> </div>
                </div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="DataGrid">
                <div class="boxColor2">
                </div>
                <div class="dataBox">
                  <div class="ViewingData">首页访问总人次
                    <span>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                            <span> 即页面的浏览次数： <br>用户每次访问网页均被记录； <br> 对同一页面的多次访问,访问量累计；</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                    </span>
                  </div>
                  <div class="number"> <h2>{{HomepageAccess.homepageNumber}}</h2> </div>
                </div>
              </div>
            </a-col>
            <a-col :span="8" style="padding-left:30px">
              <div class="DataGrid">
                <div class="boxColor3">

                </div>
                <div class="dataBox">
                  <div class="ViewingData">功能访问总人次
                    <span>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                            <span> 即功能的浏览次数： <br>用户每次点击功能图标均被记录；<br> 对同一功能的多次访问,访问量累计；</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                    </span>
                  </div>
                  <div class="number"> <h2>{{HomepageAccess.funcNumber}}</h2> </div>
                </div>
              </div>
            </a-col>
          </a-row>
          <!--        数据展示-->
          <!-- 首页访问折线图（按周查询） -->
          <div style="width:100%;margin-bottom: 19px;">
            <div class="NumberOfHomepageVisitors">
              <p>微网站主页浏览人次/人数</p>
              <div style="display: flex;">
                <div class="tabAnddateChange">
                  <a-space direction="vertical" :size="10" class="dataPicker">
                    <a-range-picker
                        :allowClear="false"
                        style="width: 300px;"
                        :default-value="[startTime,endTime]"
                        :format="dateFormat"
                        :disabled-date="disabledDate"
                        @change="onChangeDay"
                    />
                  </a-space>
                </div>
              </div>
            </div>
            <div style="background-color: #ffffff;padding: 10px 18px;">
              <a-radio-group  v-model="type" style="margin-right: 20px" @change="searchOk">
                <a-radio-button value="personTime" >
                  人次
                </a-radio-button>
                <a-radio-button value="NumberOfPeople">
                  人数
                </a-radio-button>
              </a-radio-group>
            </div>
            <!-- 折线图 -->
            <div>
              <div id="lineChart" style="width:100%;height:400px; background-color:#ffffff ;display: flex;justify-content: center;" ></div>
            </div>
          </div>

          <!-- 地图 -->
          <div class="mapBox">
            <div class="mapBox-top">
              <p>微网站地区浏览人数/人次</p>

              <div style="display: flex;">
                <div class="tabAnddateChange">
                  <a-space direction="vertical" :size="10" class="dataPicker">
                    <a-range-picker
                        :allowClear="false"
                        style="width: 300px;"
                        :default-value="[startTimeMap,endTimeMap]"
                        :format="dateFormat"
                        :disabled-date="disabledDate"
                        @change="onChangeDayMap"
                    />
                  </a-space>
                </div>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between">
              <div style="padding: 10px 18px;width: 50%;">
                <a-radio-group  v-model="mapRadioType" @change="mapchangeSearchOk">
                  <a-radio-button value="numberCount" >
                    人次
                  </a-radio-button>
                  <a-radio-button value="number">
                    人数
                  </a-radio-button>
                </a-radio-group>
                <!-- 地图 -->
                <div id="mapContainer" style="width:100%;height:400px; background-color:#ffffff ;display: flex;justify-content: center;"></div>
              </div>
              <div style="width: 50%;margin-top: 10px;margin-right: 10px">
                <!-- table -->
                <div class="mapTable">
                  <a-table
                      size="small"
                      :pagination="false"
                      :columns="mapColumns"
                      style="margin: auto;width: 100%;"
                      :data-source="mapFunctionData"
                  >
                    <!-- :data-source="mapFunctionData" -->
                    <template slot="serialNum" slot-scope="text, record, index">
                      {{ index + ((mapPageNum - 1) * mapPageSize) + 1 }}
                    </template>

                    <span slot="number">
                      <div class="customSty">
                        <div class="title">人数</div>
                        <div>
                          <div >
                            <a-icon
                                :class="{ liBackground:sortMap === 'asc' && sortNameMap === 'number'}"
                                @click="createdUp('number','asc')"
                                class="icon" type="caret-up" /></div>
                          <div style="margin-top: -10px">
                            <a-icon
                                :class="{ liBackground:sortMap === 'desc' && sortNameMap === 'number'}"
                                @click="createdDown('number','desc')"
                                class="icon" type="caret-down" />
                          </div>
                        </div>
                      </div>
                    </span>

                    <span slot="numberCount">
                      <div class="customSty">
                        <div class="title">人次</div>
                        <div>
                          <div >
                            <a-icon
                                :class="{ liBackground:sortMap === 'asc' && sortNameMap === 'numberCount'}"
                                @click="createdUp('numberCount','asc')"
                                class="icon" type="caret-up" /></div>
                          <div style="margin-top: -10px">
                            <a-icon
                                :class="{ liBackground:sortMap === 'desc' && sortNameMap === 'numberCount'}"
                                @click="createdDown('numberCount','desc')"
                                class="icon" type="caret-down" />
                          </div>
                        </div>
                      </div>
                    </span>

                  </a-table>
                  <a-pagination
                      size="small"
                      :total="mapTotal"
                      show-size-changer
                      show-quick-jumper
                      :default-current="mapPageNum"
                      v-model="mapCurrent"
                      :pageSizeOptions="mapPageSizeOptions"
                      :pageSize = "mapPageSize"
                      @showSizeChange="onMapChange"
                      @change="onMapChange"
                      style="margin-top: 20px;"
                  />
                </div>
              </div>
            </div>

          </div>


          <!-- 饼状图-->
          <div class="PieChart">
            <div class="PieChart-top">
              <p>微网站主页功能浏览人数/人次</p>

              <div style="display: flex;">
                <div class="tabAnddateChange">
                  <a-space direction="vertical" :size="10" class="dataPicker">
                    <a-range-picker
                        :allowClear="false"
                        style="width: 300px;"
                        :default-value="[startTimePie,endTimePie]"
                        :format="dateFormat"
                        :disabled-date="disabledDate"
                        @change="onChangeDayPie"
                    />
                  </a-space>
                </div>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between">
              <div style="padding: 10px 18px;width: 50%;">
                <a-radio-group  v-model="pieDatatype" @change="functionSearchOk">
                  <a-radio-button value="personTime" >
                    人次
                  </a-radio-button>
                  <a-radio-button value="NumberOfPeople">
                    人数
                  </a-radio-button>
                </a-radio-group>
                <div>
                  <!-- 饼图 -->
                  <div id="piepic"  ref="b" style="width:100%;height:400px; background-color:#ffffff ;display: flex;justify-content: center;"></div>
                </div>
              </div>
              <div style="width: 50%;margin-top: 10px;margin-right: 10px">
                <div class="pieLayout">
                  <!-- 表格 -->
                  <div class="piepic2">
                    <a-table
                        size="small"
                        :pagination="false"
                        :columns="columns"
                        style="margin: auto;width: 100%;"
                        :data-source="functionData"
                    >
                      <!-- :data-source="functionData" -->
                      <template slot="serialNum" slot-scope="text, record, index">
                        {{ index + ((piePageNum - 1) * piePageSize) + 1 }}
                      </template>

                      <span slot="number">
                      <div class="customSty">
                        <div class="title">人数</div>
                        <div>
                          <div >
                            <a-icon
                                :class="{ liBackground:sortPie === 'asc' && sortNamePie === 'number'}"
                                @click="pieUp('number','asc')"
                                class="icon" type="caret-up" /></div>
                          <div style="margin-top: -10px">
                            <a-icon
                                :class="{ liBackground:sortPie === 'desc' && sortNamePie === 'number'}"
                                @click="pieDown('number','desc')"
                                class="icon" type="caret-down" />
                          </div>
                        </div>
                      </div>
                    </span>

                      <span slot="numberCount">
                      <div class="customSty">
                        <div class="title">人次</div>
                        <div>
                          <div >
                            <a-icon
                                :class="{ liBackground:sortPie === 'asc' && sortNamePie === 'numberCount'}"
                                @click="pieUp('numberCount','asc')"
                                class="icon" type="caret-up" /></div>
                          <div style="margin-top: -10px">
                            <a-icon
                                :class="{ liBackground:sortPie === 'desc' && sortNamePie === 'numberCount'}"
                                @click="pieDown('numberCount','desc')"
                                class="icon" type="caret-down" />
                          </div>
                        </div>
                      </div>
                    </span>


                    </a-table>
                    <!-- :total="mapTotal"  -->
                    <a-pagination
                        size="small"
                        :total="pieTotal"
                        show-size-changer
                        show-quick-jumper
                        :default-current="piePageNum"
                        v-model="pieCurrent"
                        :pageSizeOptions="pageSizeOptions"
                        :pageSize = "piePageSize"
                        @showSizeChange="onChange"
                        @change="onChange"
                        style="margin-top: 20px;"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       <!-- 表格 -->
        <div v-else style="width: 95%;margin: auto;background-color: #ffffff;padding: 20px">
          <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 20px;">
            <div style="display: flex;;align-items: center"  v-if="funcCode!=='ZCJF'">
                <span style="color: #333333">提交时间：</span>
                <a-range-picker :allowClear="true" style="width: 320px" show-time
                                @change="selectZCBMTime"
                                @ok="selectZCBMTimeOk"></a-range-picker>
            </div>
            <div style="display: flex;;align-items: center;" v-if="funcCode=='ZCJF'" >
             手机号：<a-input-search style="width:180px;margin-right:20px" placeholder="请输入" allowClear enter-button v-model="tel" @search="onSearchTEL" ></a-input-search>
            签到状态：
            <a-radio-group  button-style="solid" v-model="checkStatus" style="margin-right:20px">
              <a-radio-button value="1" @click="changecheckStatus('1')">
                 已签到
              </a-radio-button>
              <a-radio-button value="0"  @click="changecheckStatus('0')">
                待签到
              </a-radio-button>
             </a-radio-group>
            支付状态：
              <a-radio-group  button-style="solid" v-model="payStatus">
              <a-radio-button value="1" @click="changpayStatus('1')">
                已支付
              </a-radio-button>
              <a-radio-button value="0" @click="changpayStatus('0')">
                待支付
              </a-radio-button>
             </a-radio-group>
            </div>
            <div>
              <a-button :disabled="loadingZCBM" @click="exportBtn"><a-icon type="file-excel" style="color: #00A854" /> 批量导出</a-button>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <a-alert type="info" show-icon >
                <span slot="message">
                  共收集 <span style="color: #45A5E6">{{funcCode!=='ZCJF'?pagination.total:total}}</span> 条记录
                </span>
            </a-alert>
          </div>
          <a-table
              :pagination="false"
              :columns="columnsZCBMList"
              :data-source="dataListZCBMList"
              :scroll="{ x: 2500, y: 350 }" >
             <span :slot='item.key' slot-scope="text,record" v-for="(item,index) in columnsZCBMList" :key="index">
              {{text ==undefined?'-':text}}
             </span>
          </a-table>
          <div
          v-if="funcCode!=='ZCJF'"
          style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
          >
        <span
            style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ pagination.total }}条记录 第 {{ pagination.current }} /
          {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
        </span>
            <a-pagination
                style="float: right"
                show-quick-jumper
                show-size-changer
                :page-size.sync="pagination.pageSize"
                :total="pagination.total"
                v-model="pagination.current"
                @change="pageChange"
                @showSizeChange="pageChange"
            />
          </div>
          <div
          v-if="funcCode=='ZCJF'"
          style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
          >
        <span
            style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ total }}条记录 第 {{ pageNo }} /
          {{ Math.ceil(total / pageSize) }} 页
        </span>
            <a-pagination
                style="float: right"
                show-quick-jumper
                show-size-changer
                :page-size.sync="pageSize"
                :total="total"
                v-model="pageNo"
                @change="pageChangeZCJF"
                @showSizeChange="pageChangeZCJF"
            />
          </div>
        </div>
        </a-spin>
      </div>
    </div>
  </a-modal>
</div>
</template>
<script src="https://geo.datav.aliyun.com/areas_v3/bound/100000_full.json"></script>
<script>
import {
  getDataGrid,
  getMapData, getNumberOfHomepageVisitors,
  getPersonTimeOfMap,
  getPieAndTable,
  getPieAndTableNumberOfPeople, getuserSessions
} from "@/service/dataAnalysis/homePage";
import moment from "moment";
import * as echarts from "echarts";
import china from "@/assets/china.json";
import {getZCBMPanel, getZCBMPanelExport,getFormMeList,getZCJFResult,exportResult} from "@/service/MedicalConference";
import LoginYCVue from '../../views/LoginYC.vue';
echarts.registerMap('china', china) // 在echarts中注册使用这个文件

export default {
name: "DataReport",
  props:{
    visible:{
      type: Boolean,
      default: false,
    },
    //会议详情
    liveInfo:{
      type:Object
    },
    selectNumber:{
      type:Number,
    },
    dataId:{
      type:Number
    },
    funcCode1:{
      type:String
    }
  },
  computed:{
    // 九宫格页面传来的id
    dataAnalysisId() {
      return this.$store.state.dataAnalysisId;
    },
  },
  data(){
    return{
      spinning:false,
      HomepageAccess:{
        homepageTotal:0,
        homepageNumber:0,
        funcNumber:0,
      },
      selectId:['1'],
      dateFormat: 'YYYY-MM-DD',
      startTime:'',
      endTime:(moment().format('YYYY-MM-DD')),

      startTimeMap:'',
      endTimeMap:(moment().format('YYYY-MM-DD')),
      sortNameMap:'',
      sortMap:'',

      startTimePie:'',
      endTimePie:(moment().format('YYYY-MM-DD')),

      type: 'personTime',
      pieDatatype:'personTime',
      mapRadioType:'numberCount',
      mapColumns: [
        {
          title: '排名',
          dataIndex: 'serialNum',
          // width: '13%',
          key: 'serialNum',
          align: 'center',
          scopedSlots: {customRender: 'serialNum'},
        },
        {
          title: '城市',
          dataIndex: 'city',
          key: 'city',
          align: 'center',
          // width: '16%'
        },
        {
          // title: '人数',
          dataIndex: 'number',
          key: 'number',
          align: 'center',
          slots: { title: 'number' },
        },
        {
          // title: '人次',
          dataIndex: 'numberCount',
          key: 'numberCount',
          align: 'center',
          slots: { title: 'numberCount' },
        }
      ],
      mapFunctionData:[],
      mapTotal:0,
      mapPageNum:1,
      mapCurrent:1,
      mapPageSize:5,
      mapPageSizeOptions:["5", "10", "15", "20"],

      columns: [
        {
          title: '排名',
          dataIndex: 'serialNum',
          // width: '13%',
          key: 'serialNum',
          align: 'center',
          scopedSlots: {customRender: 'serialNum'},
        },
        {
          title: '功能名称',
          dataIndex: 'city',
          key: 'city',
          align: 'center',
          ellipsis: true,
          // width: '16%'
        },
        {
          // title:'人数',
          dataIndex: 'number',
          key: 'number',
          align: 'center',
          slots: { title: 'number' },
        },
        {
          // title: '人次',
          dataIndex: 'numberCount',
          key: 'numberCount',
          align: 'center',
          slots: { title: 'numberCount' },
        }
      ],
      functionData:[],
      pieTotal:0,
      piePageNum:1,
      pieCurrent:1,
      piePageSize:5,
      pageSizeOptions:["5", "10", "15", "20"],//分页

      pagination:{
        total:0,
        current:1,
        pageSize:10
      },
      ZCBM_list_StimeVal:null,
      ZCBM_list_EtimeVal:null,
      loadingZCBM:false,

      columnsZCBMList :[],
      dataListZCBMList:[],

      provincesStyle:'',

      sortNamePie:'',
      sortPie:'',
      menuList:[],
      funcCode:'',
      tel:'',
      checkStatus:'',
      payStatus:"",
      pageNo:1,
      pageSize:10,
      total:0,

    }
  },
  created() {
    // 打开时判断
    this.getThirtyDaysAgo(); //获取当前日期和30天前的日期
    //selectNumber 1：左侧菜单选择-微网站主页  2：注册报名
    if(this.selectNumber === 1){
      this.selectId = ['1'];
      this.getDataGrid(); //获取顶部 数据
      this.chinaMapTable(); //地图表格 数据
      this.PieTable();  //饼图表格 数据
    }
    else{
    if(this.funcCode1!=='ZCJF')  {
    this.selectId[0] = this.dataId;
    this.funcCode=this.funcCode1
    this.ZCBMPanel(this.dataId);
    }
    if(this.funcCode1=='ZCJF'){
    this.selectId[0] = this.dataId;
    this.funcCode=this.funcCode1
    this.ZCJFPanel(this.dataId)
    }
    }
  },
  mounted(){
     this.getMenuList()
    // 不可以在created里面挂载饼状图
    // selectNumber 1：左侧菜单选择-微网站主页  2：注册报名
    if(this.selectNumber === 1){
      this.selectId = ['1'];
      this.getuserSessions(); //折线图
      this.Pie(); //饼图
      this.chinaMapData();//地图
    }
  },
  methods:{
    // 点击左侧菜单触发
    selectMenu(val,funcCode){

      this.ZCBM_list_StimeVal = '';
      this.ZCBM_list_EtimeVal = '';
      this.pagination.current = 1;

      this.selectId[0] = val;
      this.funcCode=funcCode;
      console.log(this.funcCode);

      if(val === '1'){
        this.getDataGrid(); //获取顶部 数据
        this.chinaMapTable(); //地图表格 数据
        this.PieTable();  //饼图表格 数据

        this.getuserSessions(); //折线图
        this.Pie(); //饼图
        this.chinaMapData();//地图

      }
      else {
        if(funcCode!=='ZCJF'){
        this.dataId=val
        this.ZCBMPanel(this.dataId);
        }
        else{
           this.dataId=val
           this.ZCJFPanel(this.dataId)
        }
       }

    },
    //获取当前时间以及30天前的时间
    getThirtyDaysAgo() {
      const thirtyDaysAgoDate = new Date();
      thirtyDaysAgoDate.setDate(thirtyDaysAgoDate.getDate() - 30); // 获取30天前的日期
      this.startTime = moment(thirtyDaysAgoDate).format('YYYY-MM-DD');
      this.startTimeMap = moment(thirtyDaysAgoDate).format('YYYY-MM-DD');
      this.startTimePie = moment(thirtyDaysAgoDate).format('YYYY-MM-DD');
    },
    // 饼状图分页
    onChange(pageNumber, pageSize) {
      this.piePageSize = pageSize
      this.piePageNum = pageNumber
      this.PieTable();
    },
    async PieTable() { //饼图表格数据
      //参数sortName 排序字段 传值 ：人数number 人 numberCount
      // 参数sort  传值降序 desc  升序 asc
      let data = {
        page_no:this.piePageNum,
        page_size:this.piePageSize,
        beginDate:this.startTimePie,
        endDate:this.endTimePie,
        sortName:this.sortNamePie,
        sort:this.sortPie,
      }
      const response = await getPieAndTable(this.liveInfo.conferenceCode, data)
      this.functionData = response.data;
      this.pieTotal = response.count;
    },
    // 3.饼图
    async Pie() {
      let pieData;
      let peiRes;

      let data = {
        page_no:1,
        page_size:9999999,
        beginDate:this.startTimePie,
        endDate:this.endTimePie,
      }
      if (this.pieDatatype == "personTime"){ // 人次
        peiRes = await getPieAndTable(this.liveInfo.conferenceCode,data)
        pieData = peiRes.data;
      }else if(this.pieDatatype == "NumberOfPeople"){  //人数
        peiRes = await getPieAndTableNumberOfPeople(this.liveInfo.conferenceCode,data)
        pieData = peiRes.data;
      }
      let resultArr = [];
      if(pieData.length){
        //循环获取数组
        pieData.forEach((list)=>{
          let obj = {
            city:list.city,
            number: this.pieDatatype === "personTime" ? list.numberCount : list.number,
          }
          resultArr.push(obj)
        })
      }
      var myChart = echarts.init(document.getElementById('piepic'));
      // 绘制图表
      myChart.setOption({
        //设置
        tooltip:{
          trigger:'item',
          // formatter: "{a} <br/>{b} : {c} ({d}%)"
        },

        legend: {
          // orient: "vertical",
          left:'5px',
          bottom: "10px",
          right: "5px",
          textStyle: {
            // align: "right",
            fontSize: '10px'
          },
          formatter: function(name){
            return name.length>5?name.substr(0,5)+"...":name;
          }
        },
        dataset:{
          source:resultArr
        },
        series:[
          {
            radius:'60%',
            center:['50%','40%'],
            name: this.pieDatatype == "personTime"?"人次":"人数",
            type:'pie',
            //放置要展示的数据,我没数据啊我靠，省份不会也没有把
            data: resultArr.number,
            label:{
              textStyle: {
                color: 'black',  // 改变标示文字的颜色
                fontSize:10,//文字大小
              },
              padding: [30, 0],

            },
          },
        ]
      })
    },

    async onChangeDayPie(date, dateString) {
      this.startTimePie = dateString[0];
      this.endTimePie = dateString[1];
      this.Pie(); //饼图
      this.PieTable();  //饼图表格 数据
    },
    // 3.1饼图的人次人数切换
    functionSearchOk(e){
      this.Pie()
    },
    // 地图分页
    onMapChange(pageNumber, pageSize) {
      this.mapPageSize = pageSize
      this.mapPageNum = pageNumber
      this.chinaMapTable();
    },
    // 地图表格
    async chinaMapTable() {
      //参数sortName 排序字段 传值 ：人数number 人 numberCount        参数sort  传值降序 desc  升序 asc
      let data = {
        beginDate:this.startTimeMap,
        endDate:this.endTimeMap,
        page_no:this.mapPageNum,
        page_size:this.mapPageSize,
        sortName:this.sortNameMap,
        sort:this.sortMap,
      }
      const response = await getPersonTimeOfMap(this.liveInfo.conferenceCode, data)
      this.mapFunctionData = response.data;  //表格的
      this.mapTotal = response.count;  //分页的
    },
    // 左侧地图
    async chinaMapData(){
      let data = {
        beginDate:this.startTimeMap,
        endDate:this.endTimeMap,
        sortName:this.mapRadioType, //（人数传number，人次传numberCount）
      }
      const mapRes = await getMapData(this.liveInfo.conferenceCode,data)
      let information = mapRes.data  //画地图的
      var mapContainer = echarts.init(document.getElementById("mapContainer"));  //挂载echarts

      echarts.registerMap('china', china) // 在echarts中注册使用这个文件
      let option = {
        tooltip: {
          formatter:function(params){
            // console.log("悬浮框",params)
            return "地区："+ params.name+'<br />'+params.seriesName+' :  ' + (params.value ? params.value:0)
          }//数据格式化
        },
        visualMap:{
          min: 0,
          max: 400,
          left: 'left',
          top:'bottom',
          text:['H','L'],

          inRange:{
            color: ['#f1fbfb','#0c73dc'],
          },
          show:true
        },

        geo:{
          map:'china',
          roam: true,
          zoom:1.23,
          label: {
            // 显示的省份
            normal: {
              show: false,
              fontSize:'10px',
              color: 'rgba(0,0,0,0.7)'
            }
          },
          itemStyle: {
            normal:{
              borderColor: 'rgba(0,0,0,0.2)',
              areaCoLor:'#fff',
            },
            emphasis:{
              areaColor: '#F3B329',
              shadowoffsetX:0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor:'rgba(0,0,0, 0.5)'
            }
          }
        },

        dataset:{
          // source:information
        },

        series :[
          {
            name: this.mapRadioType == "numberCount"?"人次":"人数",
            type:'map',
            geoIndex:0,
            data:JSON.parse(JSON.stringify(information).replace(/province/g,'name').replace(/number/g, 'value'))
            // data:information
          }
        ]
      }
      mapContainer.setOption(option)
    },
    // 地图
    async onChangeDayMap(date, dateString) {
      this.startTimeMap = dateString[0];
      this.endTimeMap = dateString[1];
      this.chinaMapData(); //地图
      this.chinaMapTable(); //地图表格 数据
    },

    // 地图的人次人数切换---切换只针对左侧地图数据
    mapchangeSearchOk(e){
      this.chinaMapData();
    },
    // 1.1折线图的人次人数切换
    searchOk(e) {
      this.getuserSessions()
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    // 1.2折线图日历按钮(首页访问)
    async onChangeDay(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.getuserSessions()
    },




    // 1折线图（首页访问总人次）
    async getuserSessions() {
      this.spinning = true;
      if (this.type == "personTime"){ //人次
        const response = await getuserSessions(this.liveInfo.conferenceCode,this.startTime,this.endTime)
        this.chartdata = response.data;
        this.spinning = false;
      }else if(this.type == "NumberOfPeople"){ //人数
        const response = await getNumberOfHomepageVisitors(this.liveInfo.conferenceCode,this.startTime,this.endTime)
        this.chartdata = response.data;
        this.spinning = false;
      }
      var myChart = echarts.init(document.getElementById('lineChart'));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',    //悬浮提示框
        },
        legend: {               //设置区分（哪条线属于什么）
          data:this.type == "personTime"? ['访问总人次']:['访问总人数']
        },
        grid: {
          x: 40,
          y: 86,
          x2: 60,
          y2: 60,
          // borderWidth: 10
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            boundaryGap: false,
            data: this.chartdata.map(item => (item.currentDate)),
          }
        ],
        yAxis: [
          {
            type: 'value',
            minInterval: 1,  //最小刻度
            splitNumber: 5,
            splitLine: {    //网格线
              lineStyle: {
                type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
              },
              show: true,//隐藏或显示
              width: 20,
              interval: '0.5',    // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
            }
          }
        ],
        dataset: [
          {
            // source: this.hhh
          },
        ],
        series: [
          {
            // 点的数据
            data: this.chartdata.map(item =>  item.number),
            name:this.type == "personTime"? '访问总人次':"访问总人数",
            type: 'line',
            lineStyle: {
              color: '#5090FF',
            },
            // 点
            itemStyle: {
              color: '#5090FF'
            },
            // 平滑 属性
            smooth: true,
            symbol: 'emptyCircle',
            // 区域渐变效果
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [{
                      offset: 0,
                      color: '#4DC9EC'
                    },
                      {
                        offset: 1,
                        color: '#B3E7F7'
                      }
                    ],
                    false
                ),
                shadowColor: '#ffffff',
                shadowBlur: 10
              }
            },
          }
        ]
      })

    },
    //获取顶部数据
    async getDataGrid() {
      const response = await getDataGrid(this.liveInfo.conferenceCode);
      if (response.code === 0) {
        this.HomepageAccess = response.data;
      }
    },
    handleOk(){
      this.handleCancel();
    },
    handleCancel(){
      let flag = false;
      this.$emit("closeMainDataReport", flag); //子组件向父组件传值（开关）
    },
    selectZCBMTime(date, dateString) {
      this.ZCBM_list_StimeVal = dateString[0];
      this.ZCBM_list_EtimeVal = dateString[1];
      if(this.ZCBM_list_StimeVal === '' && this.ZCBM_list_EtimeVal === ''){
        this.ZCBMPanel(this.dataId);
      }
    },
    selectZCBMTimeOk() {
      this.ZCBMPanel(this.dataId);
    },
    //新增注册报名数据列表
    async exportBtn() {
      this.loadingZCBM = true;
      if(this.funcCode!=='ZCJF'){
      let data = {
        dateBegin:this.ZCBM_list_StimeVal,
        dateEnd:this.ZCBM_list_EtimeVal,
        ExelName:'注册报名'+new Date().getTime(),
      }
      await getZCBMPanelExport(this.liveInfo.conferenceCode,data);
      this.loadingZCBM = false;
    }
     if(this.funcCode=='ZCJF'){
      let data = {
         funcId:this.dataId,
         conventionCode:this.liveInfo.conferenceCode,
         tel:this.tel,
         payStatus:this.payStatus,
         checkStatus:this.checkStatus,
        ExelName:'注册缴费'+new Date().getTime(),
      }
      await exportResult(data)
      this.loadingZCBM = false;

     }

    },
    //获取自定义表单数据
    async ZCBMPanel(id) {
      let data = {

        dateBegin:this.ZCBM_list_StimeVal,
        dateEnd:this.ZCBM_list_EtimeVal,
        page:this.pagination.current,
        pageSize:this.pagination.pageSize
      }
      const response = await getZCBMPanel(this.liveInfo.conferenceCode,data,id);
      if (response.code === 200) {

        let header = response.headers;
        let headerKey = Object.keys(header);
        let headerArr = Object.keys(header).map((key,index)=>{
          let list = {
            title: header[key],
            dataIndex: headerKey[index],
            key: headerKey[index],
            scopedSlots: { customRender: headerKey[index] },
          }
          return list;
        });
        this.columnsZCBMList = headerArr;//table表头

        this.dataListZCBMList = response.data; //table数据

        this.pagination.total = response.count;



      } else {
        this.$message.warning(response.message);
      }
    },
    pageChange(current,pageSize){
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.ZCBMPanel(this.dataId);
    },
      pageChangeZCJF(current,pageSize){
      this.pageNo= current;
      this.pageSize = pageSize;
      this.ZCJFPanel(this.dataId);
    },

    //排序
    createdUp(sortName,sort){
      this.sortNameMap = sortName;
      this.sortMap = sort;
      this.chinaMapTable();
    },
    createdDown(sortName,sort){
      this.sortNameMap = sortName;
      this.sortMap = sort;
      this.chinaMapTable();
    },
    //排序
    pieUp(sortName,sort){
      this.sortNamePie = sortName;
      this.sortPie = sort;
      this.PieTable();
    },
    pieDown(sortName,sort){
      this.sortNamePie = sortName;
      this.sortPie = sort;
      this.PieTable();
    },

    // -------------------------------------------
    // 获取左侧菜单
    async getMenuList(){
         const res=await getFormMeList(this.liveInfo.conferenceCode)
         if(res.code==0){
            this.menuList=res.data
         }

    },
    // 注册缴费表格
    async ZCJFPanel(funcId){
        let data={
          funcId:funcId,
          conventionCode:this.liveInfo.conferenceCode,
          phone:this.tel,
          payStatus:this.payStatus,
          checkStatus:this.checkStatus,
          pageNo:this.pageNo,
          pageSize:this.pageSize,
        }
        const res=await getZCJFResult(data)
        if(res.code==0){
        let header = res.data.header;
        let headerKey = Object.keys(header);
        let headerArr = Object.keys(header).map((key,index)=>{
          let list = {
            title: header[key],
            dataIndex: headerKey[index],
            key: headerKey[index],
            // slots: { title: headerKey[index] },
            scopedSlots: { customRender: headerKey[index] },

          }
          return list;
        });
        this.columnsZCBMList = headerArr;//table表头

        this.dataListZCBMList = res.data.data; //table数据

         this.total = res.count;

        }

    },
    changecheckStatus(val){
      if(this.checkStatus==val){
          this.checkStatus=''
           this.ZCJFPanel(this.dataId)
      }
      else{
        this.checkStatus=val
       this.ZCJFPanel(this.dataId)
      }

    },
    changpayStatus(val){
    if(this.payStatus==val){
        this.payStatus=''
        this.ZCJFPanel(this.dataId)
    }
    else{
     this.payStatus=val
     this.ZCJFPanel(this.dataId)
    }

    },
    onSearchTEL(value){

        this.ZCJFPanel(this.dataId)
      },

  }
}
</script>

<style scoped lang="scss">
.DataReport{
  height: 630px;
  display: flex;
  background-color: #F0F2F5;
  .DataReport-menu{
    width: 15%;
    overflow-y: scroll;
    background-color: #ffffff;
  }
  .DataReport-cont{
    width: 85%;
    height: 100%;
    overflow-y: scroll;
    padding-top: 10px;
    .DataGrid{
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #fff;
      height: 100px;
      border-radius: 5px;

      .boxColor1{
        width: 10%;
        height: 100%;
        background-color: #d3a3ff;
        border-radius: 5px 0 0 5px;
      }

      .boxColor2{
        width: 10%;
        height: 100%;
        background-color: #f4742f;
        border-radius: 5px 0 0 5px;
      }

      .boxColor3{
        width: 10%;
        height: 100%;
        background-color: #5adebc;
        border-radius: 5px 0 0 5px;
      }
      .dataBox{
        width: 80%;
        border-radius: 0 5px  5px /0;


        .ViewingData{
          width: 100%;
          text-align: center;
          margin-bottom: 6px;
          color: #a1a1a1;
        }
        .number{
          width: 100%;
          text-align: center;

          h2{
            margin-bottom: 0;
            font-size:28px
          }
        }

      }
    }
    .NumberOfHomepageVisitors {
      margin-top: 10px;
      width: 100%;
      background-color: #ffffff;
      height: 54px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      position: relative;

      p {
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
      }

      .tabAnddateChange{
        .dataPicker {
          margin-right:20px;

        }
      }
    }
    .mapBox{
      height: 500px;
      width: 100%;
      background-color: #fff;
      margin-bottom: 19px;
      .mapBox-top{
        height: 54px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
      }
      .tabAnddateChange{
        .dataPicker {
          margin-right:20px;
        }
      }
      p{
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
      }

      .map{
        width:100%;
        height: 450px ;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        padding-top:0;

        //#mapContainer{
          //width:48% ;
          //height: 100%;
        //}

        .mapTable{
          width:100% ;
          height: 100%;
        }

      }
    }
    .PieChart{
      height: 500px;
      width: 100%;
      background-color: #fff;
      margin-bottom: 19px;
      .PieChart-top{
        height: 54px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
      }
      .tabAnddateChange{
        .dataPicker {
          margin-right:20px;
        }
      }
      p{
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
      }

      .pieLayout{
        display: flex;
        justify-content: space-between;
        height: 88%;
        #piepic{
          width:62% ;
          height: 100%;
          //background-color: pink;
        }
        .piepic2{
          width:100%;
          height: 100%;
          // background-color: pink;
        }
      }
    }
  }
}
.timeIndex{
  display: flex;
  cursor: pointer;
  align-items: center;
  .title{
    margin-right: 5px;
  }
  ::v-deep .anticon{
    color: #aba9a9;
    font-size: small;
  }
}
.liBackground {
  color: #4a9bfa !important;
}
.customSty{
  display: flex;
  height: 30px;
  .title{
    margin-top:5px;margin-right: 8px;
  }
  .icon{
    cursor: pointer;color: #8c8a8a;
  }
}
</style>
