<template>
  <div>
    <!--  主办方单位设置-->
    <a-modal width="1000px" v-model="visible" :footer="null" @cancel="handleClose">
      <template slot="title">
        <span style="font-weight: 550;color: #333333;font-size: 20px">主办单位设置</span>
      </template>
      <div style="margin-bottom: 10px">
        <div style="display: flex;margin-bottom: 10px">
          <span style="color: #333333">主办单位设置：</span>
          <a-switch
              :checked="isOpenCountdown === 1 ? true : false"
              checked-children="开启"
              un-checked-children="关闭"
              @change="switchCountDownBtn(isOpenCountdown === 0 ? 1 : 0)"
              default-checked
          />
        </div>
        <div>
          <a-table :columns="columns" :data-source="data" :pagination="false">
            <template
                v-for="col in ['icon', 'name', 'link']"
                :slot="col"
                slot-scope="text, record, index"
            >
              <div :key="col">
                <div v-if="col === 'icon'">
                  <div v-if="record.editable" style="position: relative;width: 60px;height: 60px;">
                    <div v-if="text">
                      <img :src="text" style="width: 50px;height: auto">
                    </div>
                    <div class="imgDiv" v-else>
                      logo
                    </div>
                    <input
                        class="upImg"
                        type="file"
                        @change="e => handleChangeImg(e, record.key, col)">
                  </div>
                  <template v-else>
                    <img :src="text" style="width: 50px;height: auto">
                  </template>
                </div>
                <div v-if="col === 'name'">
                  <div v-if="record.editable" style="display: flex">
                    <span style="color: red;margin-right: 5px">*</span>
                    <a-input
                        v-if="record.editable"
                        placeholder="如  主办单位：广州市优屏科技有限公司"
                        style="margin: -5px 0;width: 300px"
                        :value="text"
                        @change="e => handleChange(e.target.value, record.key, col)"
                    />
                  </div>
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
                <div v-if="col === 'link'">
                  <a-input
                      v-if="record.editable"
                      placeholder="请输入"
                      style="margin: -5px 0"
                      :value="text"
                      @change="e => handleChange(e.target.value, record.key, col)"
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </div>
            </template>
            <template slot="operation" slot-scope="text, record, index">
              <div class="editable-row-operations">
                <span v-if="record.editable">
                  <a style="margin-right: 10px" @click="() => save(record.key)">保存</a>
                  <a-popconfirm title="是否确认取消?" @confirm="() => cancel(record.key)">
                    <a>取消</a>
                  </a-popconfirm>
                </span>
                <span v-else>
                  <a :disabled="editingKey !== ''" @click="() => edit(record.key)">修改</a>
                  <a-popconfirm
                      style="margin-left: 5px"
                      :disabled="editingKey !== ''"
                      title="是否确认删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="delBtn(index)"
                  >
                    <a>删除</a></a-popconfirm>
                </span>
              </div>
            </template>
          </a-table>

          <a-button @click="addTable" type="dashed" style="width: 100%;margin-top: 10px">+ 新增</a-button>
        </div>

        <a-alert style="margin-top: 70px" type="info" show-icon >
          <span slot="message">
            该服务已生成订单，如需关闭此服务订单请点击<a-popconfirm
              title="关闭服务后将不会计入订单中，无法使用该服务，是否确认关闭?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="confirmClose"
          ><a>关闭服务</a></a-popconfirm>
          </span>
        </a-alert>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {postDocumentImg} from "@/service/document_api";
import {cancelConventionFuncOrder, conventionCode, putSwitch} from "@/service/MedicalConference";

const columns = [
  {
    title: '图标',
    dataIndex: 'icon',
    width: '10%',
    scopedSlots: { customRender: 'icon' },
  },
  {
    title: '名称',
    dataIndex: 'name',
    width: '35%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '跳转链接',
    dataIndex: 'link',
    width: '40%',
    scopedSlots: { customRender: 'link' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '15%',
    scopedSlots: { customRender: 'operation' },
  },
];
const data = [];
export default {
  name: "OrganizerSetup",
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    meetCode:{
      type:String,
      default:'',
    },
  },
  data(){
    this.cacheData = data.map(item => ({ ...item }));
    return{
      isOpenCountdown:0,
      data,
      columns,
      editingKey: '',
    }
  },
  created() {
    this.getConvention();
  },
  methods:{
    handleClose(){
      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）
      this.$parent.getLists();
    },
    switchCountDownBtn(isOpen){
      this.isOpenCountdown = isOpen;
      let data = {
        conferenceCode: this.meetCode, //会议编码
        isOpenOrganizer:isOpen,
      }
      this.putConvention(data);
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    async handleChangeImg(e, key, column) {
      let file = e.target.files[0];
      let formData = new FormData()
      formData.append('file', file)
      const image = await postDocumentImg(formData);
      if (image.code === 0) {
        let value = image.data.url;
        const newData = [...this.data];
        const target = newData.find(item => key === item.key);
        if (target) {
          target[column] = value;
          this.data = newData;
        }
      }
    },
    edit(key) {
      const newData = [...this.data];
      this.cacheData = this.data.map(item => ({ ...item }));
      const target = newData.find(item => key === item.key);
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    addTable(){
      if(this.data.length < 3){
        if(this.data.length === 0 || !this.data[this.data.length-1].editable){
          let num = Math.floor(Math.random() * 100);
          this.data.push({
            key:num,
            icon: '',
            name:'',
            link: '',
          })
          let key = num;

          const newData = [...this.data];
          this.cacheData = this.data.map(item => ({ ...item }));
          const target = newData.find(item => key === item.key);
          this.editingKey = key;
          if (target) {
            target.editable = true;
            this.data = newData;
          }
        }else {
          this.$message.warning("请先保存当前主办单位设置！")
        }
      }else {
        this.$message.warning("最多可以设置三个主办方展示位！")
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => key === item.key);
      const targetCache = newCacheData.find(item => key === item.key);
      if (target && targetCache) {
        if(target.name){
          delete target.editable;
          delete target.key;
          this.data = newData;
          Object.assign(targetCache, target);
          this.cacheData = newCacheData;
          let data = {
            conferenceCode: this.meetCode, //会议编码
            organizer:this.data,
          }
          this.putConvention(data);
        }else {
          this.$message.warning("主办单位名称不能为空！");
        }
      }
      this.editingKey = '';
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      this.editingKey = '';
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.key));
        delete target.editable;
        //将未设置名称且取消的删除掉
        newData.forEach((item,index)=>{
          if(item.name === ''){
            newData.splice(index,1)
          }
        })
        this.data = newData;
      }
    },
    //获取单个会议内容
    async getConvention() {
      const response = await conventionCode(this.meetCode);
      if (response.code === 0) {
        this.isOpenCountdown = response.data.isOpenOrganizer;
        this.data = response.data.organizer ? response.data.organizer:[];
        this.data.forEach((item,index)=>{
          item.key = index
        })
        this.cacheData = this.data.map(item => ({ ...item }));
      } else {
        this.$message.warning(response.message)
      }
    },
    delBtn(index){
      this.data.splice(index,1);
      let data = {
        conferenceCode: this.meetCode, //会议编码
        organizer:this.data,
      }
      this.putConvention(data);
    },
    //修改会议接口
    async putConvention(data) {
      const response = await putSwitch(data);
      if (response.code === 0) {
        await this.getConvention();
        this.$message.success('操作成功！')
      } else {
        this.$message.warning(response.message)
      }
    },
    //主办单位设置/技术支持去广告预订单  确认取消服务
    async confirmClose(data) {
      // let date = {
      //   conventionCode:this.meetCode,
      //   type:'zbdwsz',
      // }
      const response = await cancelConventionFuncOrder(this.meetCode,'zbdwsz');
      if (response.code === 200) {
        this.handleClose();
      } else {
        this.$message.warning(response.message)
      }
    }
  }
}
</script>

<style scoped>
.bottomSty {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background-color: #fff;
  display: flex;
  justify-content: right;
  z-index: 1;
}
.cost{
  font-size: 12px;
  margin-top: 5px;
  color: #333333;
}
.pay{
  color: #00C024;
  font-size: 18px;
}
.original{
  font-size: 12px;
  margin-left: 15px;
  color: #9c9b9b;
  text-decoration: line-through;
}
.imgDiv{
  width: 60px;
  height: 60px;
  background-color: #98A4A9;
  border:2px dashed #000000;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  color: white;
  font-size: 16px;
}
.upImg{
  margin: -5px 0;position: absolute;top: 0;width: 60px;height: 60px;opacity: 0;cursor: pointer;
}
</style>
