<template>
<div>
<!--  消息提示  -->
  <a-modal
      :visible="visible"
      :footer="null"
      :closable="false"
      width="360px"
  >
    <div class="model">
      <div><admin_con style="font-size: 25px" type="icon-gantanhao-yuankuang"/></div>
      <div class="message">
        <div class="title">消息提醒</div>
<!--        <div style="margin-top: 15px;margin-bottom: 15px">您好，此功能需要完成实名认证才可操作！</div>-->
        <div style="margin-top: 15px;margin-bottom: 15px">您好，此功能需要提交实名认证才可操作！</div>
        <div class="btn">
          <a-button @click="handleCancel">取消</a-button>
          <a-button @click="handleOk(userInfoList)" type="primary" style="margin-left: 15px">去认证</a-button>
        </div>
      </div>
    </div>
  </a-modal>
  <!--认证-->
  <RealNameAuthentication
      :visible="visibleAuthentication"
      :isShow="isShow"
      v-on:closeMainRealNameAuth="closeMain"
  ></RealNameAuthentication>
  <!-- 审核中-->
  <UnderReview :visible="visibleUnderReview" v-on:closeMain="closeMains"/>
</div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";
import RealNameAuthentication from "@/components/Authentication/RealNameAuthentication";
import UnderReview from "@/components/Authentication/UnderReview";

export default {
  name: "MessageAlert",
  props:{
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components:{
    UnderReview,
    admin_con: admin_icon,RealNameAuthentication
  },
  computed:{
    userInfoList(){
      return this.$store.state.userInfoList;
    }
  },
  data(){
    return{
      visibleAuthentication:false,
      visibleUnderReview:false,
      isShow:{}
    }
  },
  methods:{
    closeMain(val){
      this.visibleAuthentication = val;
    },
    closeMains(val){
      this.visibleUnderReview = val;
    },
    handleOk(row){
      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）
      //判断当前是否认证   0,//认证状态 0-未提交认证 1已提交待审核 2已审核未通过 3已通过审核
      if(this.userInfoList.companyStatus === 1){
        this.visibleUnderReview = true;
      }else {
        //打开认证弹窗
        this.isShow = {
          companyStatusDW:0, //未认证
          id:row.companyId,
        }
        this.visibleAuthentication = true;
      }
    },
    handleCancel(){
      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）
    }
  }
}
</script>

<style scoped lang="scss">
  .model{
    width: 100%;
    display: flex;
    .message{
      margin-top: 2px;
      margin-left: 15px;
      .title{
        color: rgba(0,0,0,.65);
        font-weight: 550;
      }
      .btn{
        text-align: right;
      }
    }
  }
</style>
