
import {get, getXToken, del, post, put, postById} from "@/service/base";
import qs from "qs";

/**
 * 获取图标-城市列表
 * @returns {Promise<unknown>}
 */
export function getlconCity(){
    return getXToken("/getIconCity")
}
/**
 * 获取图标模板
 * @param data
 * @returns {Promise<unknown>}
 */
export function icontemp(data){
    return getXToken("/convention/icontemp",data)
}

/**
 * 获取所有城市地标的省份
 */
export function getIconProvince(data){
    return getXToken("/getIconProvince",data)
}
/**
 * 获取所有城市地标的城市
 */
export function getIconCity(province){
    return getXToken("/getIconCity?province="+province)
}
/**
 * 获取所有矢量图标颜色
 */
export function getIconColor(data){
    return getXToken("/getIconColor",data)
}

/**
 * 查询主页背景图-所有色值
 */
export function selectAllColor(){
    return getXToken("/home/page/bgImg/selectAllColor")
}
/**
 * 查询主页背景图
 */
export function selectAll(data){
    return getXToken("/home/page/bgImg/selectAll",data)
}

/**
 * 添加图标模板
 * @param data
 * @returns {Promise<unknown>}
 */
export function postIcontemp(data){
    return post("/convention/icontemp",data)
}

/**
 * 修改图标模板
 * @param data
 * @returns {Promise<unknown>}
 */
export function putIcontemp(data){
    return put("/convention/icontemp",data)
}

/**
 * 删除图标模板
 * @param id
 * @returns {Promise<unknown>}
 */
export function delIcontemp(id){
    return del("/convention/icontemp/"+id)
}

/**
 * 获取图标
 * @param data
 * @returns {Promise<unknown>}
 */
export function iconList(data){
    return getXToken("/convention/icon",data)
}

/**
 * 一键更换图标
 */
export function postReplacementIcon(data){
    return postById("/convention/replacement/icon",data)
}
/**
 * 添加图标
 * @param data
 * @returns {Promise<unknown>}
 */
export function postIcon(data){
    return post("/convention/icon",data)
}

/**
 * 修改图标
 * @param data
 * @returns {Promise<unknown>}
 */
export function putIcon(data){
    return put("/convention/icon",data)
}

/**
 * 删除图标
 * @param id
 * @returns {Promise<unknown>}
 */
export function delIcon(id){
    return del("/convention/icon/"+id)
}

/**
 * 批量插入图标
 * @param data
 * @returns {Promise<unknown>}
 */
export function bach_icon(data){
    return post("/convention/bach_icon",data)
}

/**
 * 收藏图标（收藏全部）
 * @param itemId
 * @returns {Promise<* | void>}
 */
export function addMyCollectionByItemId(itemId){
    return post("/api/admin/addMyCollectionByItemId/"+itemId)
}

/**
 * 收藏图标单个
 * @param id
 * @returns {Promise<unknown>}
 */
export function addMyCollection(id){
    return post("/api/admin/addMyCollection/"+id)
}


