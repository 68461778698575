<template>
  <div>
    <!--系统图标-->
    <a-modal
        :dialogStyle="{ 'top': '5%',}"
        width="1100px"
        title="图片库"
        :visible="visible"
        :footer="false"
        @cancel="handleCancel"
    >
      <div class="modalDiv">
        <div class="left">
          <a-menu
              :default-open-keys="['sub1']"
              class="menu"
              v-model="selectId"
              mode="inline">
            <a-menu-item
                v-for="(item, index) in templateList"
                @click="selectStatus(item.title,item)"
                :key="item.id"
            >
              {{ item.title }}
            </a-menu-item>

            <a-sub-menu key="sub1" title="城市图标">
              <a-menu-item
                  v-for="(list, index) in cityList"
                  @click="selectCity(list)"
                  :key="list"
              >
                {{ list }}
              </a-menu-item>
            </a-sub-menu>

          </a-menu>
        </div>

        <div class="content">
          <a-spin :spinning="spin">
            <div class="content-top" v-if="currentMenu === 1">
<!--              <div class="top-icon">-->
<!--                &lt;!&ndash;   v-show="!(pageNo === 1 && iconTemplate === 0)"    &ndash;&gt;-->
<!--                <a-icon-->
<!--                    v-show="!(pageNo === 1 && iconTemplate === 0)"-->
<!--                    class="icon"-->
<!--                    @click="leftBtn"-->
<!--                    type="left"-->
<!--                />-->
<!--              </div>-->
              <div style="display: flex;width: 100%" v-if="colorList.length">
                <div>颜色搜索：</div>
                <div class="top-right">
<!--                  <div v-for="(list,index) in colorList" :key="index" class="colorSty" :style="{backgroundColor:list,border:selectColors === list ? '2px solid #585858' : ''}" @click="selectColor(list)">-->
                  <div v-for="(list,index) in colorList" :key="index" class="colorSty" :style="{backgroundColor:list,width:selectColors === list ? '45px' : '',height:selectColors === list ? '25px' : ''}" @click="selectColor(list)">
                  </div>
                </div>
              </div>

              <div class="imgList">
                <a-row :gutter="[16,16]">
                  <a-col :span="5" v-for="(img, index) in iconDataList" :key="img.id">
                    <div class="imgListDiv">
<!--                      @click="getIconList(img.id,img.previewImg, index, img.fileUrl)" -->
                      <img
                          class="imgD"
                          :src="img.previewImg"/>
                      <div class="imgName">{{img.name}}</div>

                      <div class="box-y">
                        <div>
                          <p class="box-btn">
                            <a-button type="primary" @click="selectIcon(img.id,img.previewImg)">
                              确认选择
                            </a-button>
                            <a-button @click="getIconList(img.id,img.previewImg,img.name)">
                              查看图标
                            </a-button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>









<!--              <div class="top-icon">-->
<!--                &lt;!&ndash;   v-show="!(((pageNo-1) * pageSize + iconTemplate +1) === iconDataCount)"             &ndash;&gt;-->
<!--                <a-icon-->
<!--                    v-show="-->
<!--                      !(-->
<!--                        (pageNo - 1) * pageSize + iconTemplate + 1 ===-->
<!--                        iconDataCount-->
<!--                      )-->
<!--                    "-->
<!--                    class="icon"-->
<!--                    @click="rightBtn"-->
<!--                    type="right"-->
<!--                />-->
<!--              </div>-->
            </div>

            <div class="content-list" v-if="currentMenu === 2">
<!--              <div class="content-list-card">-->
<!--                <div-->
<!--                    @click="selectCard(index, img.url)"-->
<!--                    class="card"-->
<!--                    v-for="(img, index) in funList"-->
<!--                    :key="img.id"-->
<!--                >-->
<!--                  <div-->
<!--                      style="-->
<!--                        display: flex;-->
<!--                        justify-content: center;-->
<!--                        width: 100%;-->
<!--                      "-->
<!--                  >-->
<!--                    <img class="imgD" :src="img.url" />-->
<!--                  </div>-->
<!--                  <div class="font" style="color: #F7F9FA">{{ img.name }}</div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="content-list-left">
                <div style="font-weight: bold;display: flex;justify-items: center">
                  <a-icon style="font-size: 23px;margin-right: 10px;" @click="returnBtn" type="left-circle" />
                  <div style="margin-top: -2px;">{{iconTemplateNme}}</div>
                </div>
                <div>
                  <img style="width: 230px;height: auto;margin-top: 20px;border: 1px solid #F5F5F5" :src="iconTemplateImg" alt="">
                </div>
              </div>
              <div class="content-list-card">
                <a-row :gutter="[16,16]">
                  <a-col :span="5" v-for="(img, index) in funList" :key="img.id">
                    <div class="content-list-card-imgList">
                      <!--                      @click="getIconList(img.id,img.previewImg, index, img.fileUrl)" -->
                      <img
                          class="content-list-card-imgD"
                          :src="img.url"/>
                      <div class="font" style="">{{ img.name }}</div>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-spin>
        </div>

      </div>

      <div v-if="currentMenu === 2" :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
        <a-button  @click="returnBtn">
          返回
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="submitBtn">
          确认选择
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {getIconColor, getlconCity, iconList, icontemp} from "@/service/photoCommander";

export default {
name: "SystemIcons",
  data(){
    return{
      selectId:['vector'],
      templateList: [
        {
          id: "vector",
          title: "矢量图标",
        },
      ],
      cityList:[],
      funList: [],
      spin:false,
      pageNo:1,
      iconTemplate:0,
      iconDataCount: 0,
      iconName:'',
      //最终选择的图片
      selectImg: "",
      selectIndex: null,
      iconDataList: [],
      pageSize: 999999,
      curPage: 1,
      iconTemplateId:null,
      iconTemplateImg:'',
      iconTemplateNme:'',
      colorList:[],
      selectColors:"",
      currentMenu:1,
    }
  },
  props: {
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getlconCityList();
    this.getIconColors();
    this.getList();
  },
  methods:{
    //获取颜色列表
    async getIconColors() {
      let data = {
        type:this.selectId[0] === 'vector' ? 'vector' : 'city',
        // province:'',
        city:this.selectId[0] !== 'vector' ? this.selectId[0]:'',
      }
      const response = await getIconColor(data);
      if (response.code === 200) {
        this.colorList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    selectColor(color){
      if(this.selectColors === color){
        this.selectColors = '';
      }else {
        this.selectColors = color;
      }

      this.getList();
    },
    async getList() {
      this.spin = true;
      let data = {
        curPage: this.pageNo,
        pageSize: this.pageSize,
        color:this.selectColors,
        type:this.selectId[0] === 'vector' ? 'vector':'city',
        city:this.selectId[0] === 'vector' ? null : this.selectId[0],
        isOpen: 1,
      };
      const response = await icontemp(data);
      if (response.code === 0) {
        this.iconDataList = response.data;
        this.iconDataCount = response.count;
        // if (this.iconDataList.length) {
        //   //默认情况下为数组第一个源文件
        //   this.downLoadFile =  response.data[this.iconTemplate].fileUrl;
        //   //默认情况下获取数组一下的图标
        //   await this.getIconList(
        //       response.data[this.iconTemplate].id,
        //       response.data[this.iconTemplate].previewImg,
        //       this.iconTemplate,
        //       this.downLoadFile,
        //   );
        // }
      } else {
        this.$message.warning("上传失败");
      }
      this.spin = false;
    },
    async getlconCityList() {
      const response = await getlconCity();
      if (response.code === 200) {
        this.cityList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    selectCard(index, url) {
      // this.selectIndex = index;
      // this.selectImg = url;
    },
    onSearch() {
      this.getTemplateIcon(this.iconTemplateId);
    },
    openDown() {
      // this.getInformation();
      window.open(this.downLoadFile);
    },
    leftBtn() {
      // 左边按钮
      if (this.iconTemplate > 0) {
        this.iconTemplate--;
        this.getTemplateIcon(this.iconDataList[this.iconTemplate].id);
      } else {
        //返回上一页九宫格模板的数据，并且初始index为4
        this.iconTemplate = 4;
        this.pageNo--;
        this.getList();
      }
    },

    rightBtn() {
      // 右边按钮
      if (this.iconTemplate < this.iconDataList.length - 1) {
        this.iconTemplate++;
        this.getTemplateIcon(this.iconDataList[this.iconTemplate].id);
        this.downLoadFile = this.iconDataList[this.iconTemplate].fileUrl;
      } else {
        //加载下一页九宫格模板的数据
        if (this.pageNo * this.pageSize < this.iconDataCount) {
          this.iconTemplate = 0;
          this.pageNo++;
          this.getList();
        }
      }
    },

    getIconList(id,previewImg,name) {
    // getIconList(id, previewImg,index, fileUrl) {
      //获取图标
      // this.downLoadFile = fileUrl;
      // this.iconTemplate = index ? index : 0;

      //显示图标详情
      this.currentMenu = 2;
      this.iconTemplateNme = name;

      this.iconTemplateId = id;
      this.iconTemplateImg = previewImg;
      this.getTemplateIcon(id);
    },
    //获取图标数据
    async getTemplateIcon(id) {
      this.spin = true;
      this.iconTemplateId = id;
      let data = {
        name: this.iconName,
        curPage: this.curPage,
        pageSize: 99999,
        icontempId: id, //九宫格模板id
      };
      const response = await iconList(data);
      if (response.code === 0) {
        this.funList = response.data;
      } else {
        this.$message.warning(response.message);
      }
      this.spin = false;
    },
    handleOk(){
      let data = {
        iconTemplateId:this.iconTemplateId,
        previewImg:this.iconTemplateImg,
      }
      this.$emit("checkListSystemIcons", data);
      this.handleCancel();
    },
    handleCancel(){
      let flag = false;
      this.$emit("closeMainSystemIcons", flag); //子组件向父组件传值（开关）
    },
    selectStatus(val,item) {
      this.currentMenu = 1;
      this.selectId[0] = item.id;
      this.funList = [];
      //清空选中的index
      this.selectIndex = null;
      this.selectMyIndex = null;
      this.selectImg = "";

      this.selectColors = '';
      this.getIconColors();
      this.getList();
    },
    selectCity(val){
      this.currentMenu = 1;
      this.selectId[0] = val;
      this.funList = [];
      this.iconTemplate = 0;
      this.pageNo = 1;

      this.selectColors = '';
      this.getIconColors();
      this.getList();
    },
    selectIcon(id,previewImg){
      this.iconTemplateId = id;
      this.iconTemplateImg = previewImg;
      this.handleOk();
    },
    returnBtn(){
      this.currentMenu = 1;
      this.funList = [];
      this.iconTemplateId = null;
      this.iconTemplateImg = '';
    },
    submitBtn(){
      // console.log(this.iconTemplateId,this.iconTemplateImg);
      this.handleOk();
    },
  }
}
</script>

<style scoped lang="scss">
.ant-col-5 {
  width: 20%;
}
.modalDiv {
  width: 103.8%;
  height: 600px;
  display: flex;
  margin-top: -24px;
  margin-left: -24px;
  .menu {
    width: 150px;
    border: none;
  }
  .content {
    width: 100%;
    //background-color: #fff;
    border-left: 1px solid #e7e7e7;
    .content-top {
      //height: 200px;
      //border-bottom: 1px solid #e7e7e7;
      //display: flex;
      .top-right{
        display: flex;
        height: 35px;
        align-items: center;
        //padding-top: 8px;
        margin-top: 7px;
        padding-left: 10px;
        background-color: #F5F5FF;
      }
      .colorSty{
        width: 40px;
        height: 20px;
        border-radius: 3px;
        margin-right: 10px;
        cursor: pointer;

      }
      //.top-icon {
      //  width: 10%;
      //  text-align: center;
      //  .icon {
      //    line-height: 200px;
      //    font-size: 40px;
      //    color: #929292;
      //    cursor: pointer;
      //  }
      //}
      .imgList {
        width: 100%;
        height: 560px;
        overflow-y: scroll;
        overflow-x: hidden;
        //display: flex;
        margin-top: 10px;


        .imgListDiv{
          width: 100%;
          border: 1px solid #d7d7d7;
          border-radius: 3px;
          cursor: pointer;
          margin: auto;
          height: 190px;
          padding-top: 15px;
          //padding-left: 10px;
          .imgName{
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: #7f7f7f;
            margin-top: -5px;
          }
        }
        .imgD {
          height: 140px;
          width: 140px;
          margin-right: 30px;
          margin-left: 15px;
        }
        .box-y {
          border-radius: 3px;
          position: absolute;
          top: 9px;
          left: 9px;
          width: 91%;
          height: 91%;
          background: rgba(0, 0, 0, 0.4) no-repeat center;
          display: none;
          .box-btn {
            color: white;
            margin: 35px 20px 10px 40px;
            font-size: 17px;
            font-weight: 550;
          }
        }
        .imgListDiv:hover .box-y {
          display: block;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .content-list {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .content-list-left{
        width: 35%;
        font-size: 18px;
        color: black;

      }
      .content-list-card{
        width: 65%;
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-top: 50px;
        .content-list-card-imgList{
          background-color: black;
          //display: flex;
          //justify-content: center;
          //align-items: center;
          height: 110px;
          img{
            width: 80px;
            //height: 80px;
            margin-left: 12%;
            padding-top: 10px;
          }
          .font{
            color: #F7F9FA;
            text-align: center;
            //margin-top: -5px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
